import downloadFile from '../../../helpers/downloadFile';
import apiClient from '../../../services/api';
import { EXPORT_RIDES_TO_EXCEL } from '../../../services/routes';
import { DownloadExport } from './types';

const exportRidesToExcel = async (variables: DownloadExport): Promise<unknown> => {
    const result = await apiClient
        .post(
            EXPORT_RIDES_TO_EXCEL,
            variables,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                responseType: 'blob',
            },
        )
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            downloadFile(url, 'GET-E Rides.xlsx');
        })
        .catch(error => {
            console.log(error);
        });

    return result;
};

export default exportRidesToExcel;
