import { useEffect, useRef, DependencyList } from 'react';

export function useDebounceEffect(
    fn: () => void,
    waitTime: number,
    deps?: DependencyList,
) {
    const savedCallback = useRef(fn);

    useEffect(() => {
        savedCallback.current = fn;
    }, [fn]);

    useEffect(() => {
        const t = setTimeout(() => {
            savedCallback.current();
        }, waitTime);

        return () => {
            clearTimeout(t);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
