import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import { RideResponse } from '../../api/types';
import withExpandable from '../../components/withExpandable';

const useStyles = makeStyles({
    meetingInstructionsContainer: {
        width: '100%',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        marginTop: '1rem',
        padding: '1rem',
        position: 'relative',
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        marginBottom: '0',
        color: COLORS.BLUE,
        fontSize: '1rem',
        fontWeight: 700,
    },
});

interface MeetingInstructionsCardProps {
    data?: RideResponse;
    renderChevrons: JSX.Element;
    isExpanded: boolean;
}

const MeetingInstructionsCard = ({ data, isExpanded, renderChevrons }: MeetingInstructionsCardProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container className={classes.meetingInstructionsContainer}>
            <Grid className={classes.headingContainer}>
                <Typography variant="h3" component="h4" className={classes.heading}>
                    {t('pages.singleRide.meetingInstructions.title')}
                </Typography>
                {renderChevrons}
            </Grid>
            {isExpanded && (
                <Typography
                    marginTop={isExpanded ? '1rem' : '0'}
                    color={COLORS.BLACK}
                >
                    {data?.pickupDescription}
                </Typography>)}
        </Grid>
    );
};

export default withExpandable(MeetingInstructionsCard);
