import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles({
    itemRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '0.25rem',
    },
});

interface SummaryListCardProps {
    title?: string;
    driverLate?: number;
    driverNoShow?: number;
    vehicleCondition?: number;
    serviceQuality?: number;
    safetyIncident?: number;
    onRouteDelay?: number;
    wrongVehicle?: number;
    other?: number;
}

const SummaryListCard = ({
    title = '',
    driverLate,
    driverNoShow,
    vehicleCondition,
    serviceQuality,
    safetyIncident,
    onRouteDelay,
    wrongVehicle,
    other,
}: SummaryListCardProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid
            container
            style={{
                width: '100%',
                height: 'calc(100% - 1rem)',
                flexDirection: 'column',
                border: `1px solid ${COLORS.BLACK_12}`,
                borderRadius: '3px',
                marginTop: '1rem',
            }}
        >
            <Grid item padding="1rem" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="1.25rem">
                    <Typography fontWeight="700" color={COLORS.SLATE_GREY}>
                        {title}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column" width="50%" paddingRight="1rem">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography>{t('pages.reports.situationTypes.driverLate')}</Typography>
                            <Typography>{driverLate}</Typography>
                        </Box>
                        <Box className={classes.itemRow}>
                            <Typography>{t('pages.reports.situationTypes.driverNoShow')}</Typography>
                            <Typography>{driverNoShow}</Typography>
                        </Box>
                        <Box className={classes.itemRow}>
                            <Typography>{t('pages.reports.situationTypes.vehicleCondition')}</Typography>
                            <Typography>{vehicleCondition}</Typography>
                        </Box>
                        <Box
                            className={classes.itemRow}
                        >
                            <Typography>{t('pages.reports.situationTypes.serviceQuality')}</Typography>
                            <Typography>{serviceQuality}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" width="50%">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography>{t('pages.reports.situationTypes.safetyIncident')}</Typography>
                            <Typography>{safetyIncident}</Typography>
                        </Box>
                        <Box className={classes.itemRow}>
                            <Typography>{t('pages.reports.situationTypes.onRouteDelay')}</Typography>
                            <Typography>{onRouteDelay}</Typography>
                        </Box>
                        <Box className={classes.itemRow}>
                            <Typography>{t('pages.reports.situationTypes.wrongVehicle')}</Typography>
                            <Typography>{wrongVehicle}</Typography>
                        </Box>
                        <Box
                            className={classes.itemRow}
                        >
                            <Typography>{t('pages.reports.situationTypes.other')}</Typography>
                            <Typography>{other}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default SummaryListCard;
