import { RideResponse } from '../../pages/ride/api/types';
import { Ride } from '../../pages/ride/components/RideInformation';
import { RideMapType } from '../../pages/ride/customer/context/RideContext';

export const mapRideToContext = (
    ride: RideResponse,
    rideValues: Partial<Ride>,
): RideMapType => ({
    prettifiedUnid: ride.prettifiedUnid,
    pickupCoordinates:
    rideValues.pickUpLocation?.latitude && rideValues.pickUpLocation?.longitude
        ? {
            lat: rideValues.pickUpLocation?.latitude,
            lon: rideValues.pickUpLocation?.longitude,
        }
        : undefined,
    dropOffCoordinates:
    rideValues.dropOffLocation?.latitude && rideValues.dropOffLocation?.longitude
        ? {
            lat: rideValues.dropOffLocation?.latitude,
            lon: rideValues.dropOffLocation?.longitude,
        }
        : undefined,
    dropOffPlaceId: rideValues.dropOffLocation?.id,
    pickUpPlaceId: rideValues.pickUpLocation?.id,
    lastPositionUpdate: ride?.lastPositionUpdate,
});
