import { Grid, useMediaQuery } from '@mui/material';
import {
    DataGridPro,
    useGridApiRef,
    GridRowId,
    GridEventListener,
    GridColDef,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useDataGridStyles from '../../../../styles/DataGrid';
import theme, { TABLE_ROW_HEIGHT } from '../../../../styles/theme';
import { ReportTabProps } from '../api/types';
import { useDriverAnalysisColumns } from '../hooks/useDriverAnalysisColumns';
import { useDriversAddedPercentage } from '../hooks/useDriversAddedPercentage';
import { useTrackedDriversPercentage } from '../hooks/useTrackedDriversPercentage';
import SummaryBasicCard from '../SummaryBasicCard';

export enum DriverUpdatesStatus {
    ACCURATE = 'Accurate',
    NOT_ACCURATE = 'Not accurate',
    NO_UPDATES = 'No updates',
}

export enum LocationSharingStatus {
    ACCURATE = 'Accurate',
    NOT_ACCURATE = 'Not accurate',
    NO_UPDATES = 'No updates',
}

const DriverAnalysisTab = ({ currentPeriod, pastPeriod, period }: ReportTabProps) => {
    const { t } = useTranslation();
    const dataGridClases = useDataGridStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const gridApiRef = useGridApiRef();
    const [columns, rowDetails, rowDetailsMobile] = useDriverAnalysisColumns();

    const onRowClick = useCallback<GridEventListener<'rowClick'>>(
        (params: { id: GridRowId }) => {
            gridApiRef.current.toggleDetailPanel(params.id);
        },
        [gridApiRef]
    );

    const {
        driversAddedCurrentPeriodPercentage,
        driversAddedPreviousPeriodPercentage,
    } = useDriversAddedPercentage(currentPeriod, pastPeriod);

    const {
        trackedDriversCurrentPeriodPercentage,
        trackedDriversPreviousPeriodPercentage,
    } = useTrackedDriversPercentage(
        currentPeriod,
        pastPeriod
    );

    const columnVisibilityModel = useMemo(() => {
        return {
            ride: isMobile,
            name: !isMobile,
            totalReviews: !isMobile,
            totalTrips: !isMobile,
            ridesTrackedAccuratelyPercent: !isMobile,
        };
    }, [isMobile]);

    return (
        <Grid container width="100%">
            <Grid container columnSpacing={3} marginTop={3}>
                <Grid item xs={12} md={4} lg={4}>
                    <SummaryBasicCard
                        title={t('pages.reports.driversAssigned')}
                        percentage1={driversAddedCurrentPeriodPercentage}
                        text1={`${driversAddedPreviousPeriodPercentage}% ${t('inPreviousPeriod')}`}
                        percentage2={(Number(driversAddedCurrentPeriodPercentage)
                            - Number(driversAddedPreviousPeriodPercentage)).toString()}
                        text2={period}
                        titleTooltip={t('pages.reports.driversAssignedTooltip')}
                        includeTotalRidesText
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <SummaryBasicCard
                        title={t('pages.reports.ridesTrackedAccurately')}
                        percentage1={trackedDriversCurrentPeriodPercentage}
                        percentage2={(Number(trackedDriversCurrentPeriodPercentage)
                                - Number(trackedDriversPreviousPeriodPercentage)).toString()}
                        text1={`${trackedDriversPreviousPeriodPercentage} ${t('inPreviousPeriod')}`}
                        text2={period}
                        titleTooltip={t('pages.reports.ridesTrackedAccuratelyTooltip')}
                        includeTotalRidesText
                    />
                </Grid>
            </Grid>
            <DataGridPro
                apiRef={gridApiRef}
                className={dataGridClases.dataGrid}
                autoHeight
                hideFooter
                disableColumnSelector
                disableColumnFilter
                rows={currentPeriod?.drivers || []}
                getRowHeight={() => (isMobile ? 100 : TABLE_ROW_HEIGHT)}
                columns={columns as GridColDef[]}
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={isMobile ? rowDetailsMobile : rowDetails}
                onRowClick={onRowClick}
                columnVisibilityModel={columnVisibilityModel}
            />
        </Grid>
    );
};

export default DriverAnalysisTab;
