import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { GridFooterContainer } from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconedData from '../../../components/iconedData/IconedData';
import LabeledData from '../../../components/labeledData/LabeledData';
import { COLORS } from '../../../constants/colors';
import useRidesStyles from './Rides.styles';

const useStyles = makeStyles(() => ({
    buttonStyle: { padding: '.6rem 2rem' },
    disabled: {
        backgroundColor: COLORS.LIGHT_GRAY,
        color: COLORS.SLATE_GREY,
        cursor: 'pointer',
        borderRadius: '.25rem',
        '& :hover *': { color: `${COLORS.SLATE_GREY} !important` },
    },
}));

interface CustomFooterProps {
    handleExport: () => void;
    totalCount?: number;
    isLoadingExport?: boolean;
}

export const CustomFooter = ({ handleExport, totalCount = 0, isLoadingExport }: CustomFooterProps) => {
    const classes = useRidesStyles();
    const { t } = useTranslation();
    const customClasses = useStyles();

    return (
        <GridFooterContainer className={classes.footer}>
            <Box className={clsx(customClasses.buttonStyle, { [customClasses.disabled]: isLoadingExport })}>
                <IconedData
                    icon={CloudDownloadOutlinedIcon}
                    data={t('pages.rides.export')}
                    dataColor={COLORS.SLATE_GREY}
                    onClick={() => !isLoadingExport && handleExport()}
                    dataTextBold
                    bottomMargin="0"
                />
            </Box>
            <LabeledData
                className={classes.totalCount}
                label={`${t('pages.rides.total')}:`}
                labelClassName={classes.totalCountLabel}
                data={`${totalCount.toString()} ${t('rides').toLowerCase()}`}
                dataColor={COLORS.SLATE_GREY}
                ignoreColumns
            />
        </GridFooterContainer>
    );
};
