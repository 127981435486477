import { FlightDetails, FlightType, Location } from '../pages/rides/api/types';

export const isFlightMismatch = (flightDetails: FlightDetails | null, pickUpLocation: Location, dropOffLocation: Location) => {
    if (!flightDetails) {
        return false;
    }

    if (
        (flightDetails.type === FlightType.ARRIVAL
      && pickUpLocation.type === 'Airport'
      && pickUpLocation.name === flightDetails.arrival.iata)
    || (flightDetails.type === FlightType.DEPARTURE
      && dropOffLocation.type === 'Airport'
      && dropOffLocation.name === flightDetails.departure.iata)
    ) {
        return false;
    }

    return true;
};
