import { Typography, Box } from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';

import { COLORS } from '../../../../constants/colors';
import { TIME_FORMATS } from '../../../../constants/timeFormats';
import { FlightStatus } from '../helpers/flightStatusUtil';

interface FlightStatusDateTimeProps {
    status?: FlightStatus;
    flightTimes: {
        delayed: string;
        scheduled: string;
    };
}

export const FlightStatusDateTime = ({ status, flightTimes: flight }: FlightStatusDateTimeProps) => {
    const color = useMemo(() => {
        if (!flight?.delayed && (status === FlightStatus.ARRIVED
             || status === FlightStatus.ON_TIME || status === FlightStatus.DEPARTING_ON_TIME)) {
            return COLORS.GREEN;
        }

        return COLORS.SLATE_GREY;
    }, [status, flight?.delayed]);

    const scheduledFlightTimeStyle = useMemo(() => {
        return {
            textDecoration: flight?.delayed || status === FlightStatus.CANCELLED ? 'line-through' : 'none',
            color,
            fontWeight: flight?.delayed || status === FlightStatus.CANCELLED ? 400 : 600,
            fontSize: flight?.delayed && status !== FlightStatus.CANCELLED ? '0.75rem' : '',
        };
    }, [color, status, flight?.delayed]);

    const getTimeColor = (flightStatus?: FlightStatus): string => {
        switch (flightStatus) {
            case FlightStatus.ARRIVED:
            case FlightStatus.ON_TIME:
            case FlightStatus.DEPARTING_ON_TIME:
                return COLORS.GREEN_TEXT;
            case FlightStatus.DEPARTING_LATE:
            case FlightStatus.DEPARTING_EARLY:
            case FlightStatus.ARRIVED_EARLY:
            case FlightStatus.ARRIVED_LATE:
            case FlightStatus.EXPECTED_EARLY:
            case FlightStatus.EXPECTED_LATE:
            case FlightStatus.LOST_TRACKING:
                return COLORS.ORANGE_WARNING;
            default:
                return COLORS.BLACK;
        }
    };

    return (
        <>
            <Box display="flex" flexDirection="column">
                {flight?.delayed && status !== FlightStatus.CANCELLED && (
                    <Typography color={`${getTimeColor(status)} !important`} fontWeight="600">
                        {moment(flight?.delayed).format(TIME_FORMATS['HH:mm A'])}
                    </Typography>
                )}
                <Typography sx={scheduledFlightTimeStyle}>
                    {moment(flight?.scheduled).format(TIME_FORMATS['HH:mm A'])}
                </Typography>
            </Box>
        </>
    );
};
