import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/modal/Modal';
import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles({ confirmButtonDeactivate: { backgroundColor: COLORS.RED } });

interface ConfirmToggleDriverActivationModalProps {
    id: number;
    isDeactivated: boolean;
    onClose: () => void;
    onToggle: (id: number, isDeactivated: boolean) => void;
}

const ConfirmToggleDriverActivationModal = ({
    id,
    isDeactivated,
    onClose,
    onToggle,
}: ConfirmToggleDriverActivationModalProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={() => onToggle(id, isDeactivated)}
            title={t(isDeactivated
                ? 'modals.toggleDriverActivation.titleActivate'
                : 'modals.toggleDriverActivation.titleDeactivate')
            }
            confirmButtonLabel={t(isDeactivated ? 'buttonName.activate' : 'buttonName.deactivate')}
            cancelButtonLabel={t('buttonName.cancel')}
            maxWidth="sm"
            confirmButtonClassName={clsx({ [classes.confirmButtonDeactivate]: !isDeactivated })}
        >
            <Typography fontWeight="700">
                {t(isDeactivated
                    ? 'modals.toggleDriverActivation.descriptionActivate'
                    : 'modals.toggleDriverActivation.descriptionDeactivate')}
            </Typography>
            <Typography paddingTop="0.5rem" paddingBottom="1rem">
                {t(isDeactivated
                    ? 'modals.toggleDriverActivation.descriptionDetailedActivate'
                    : 'modals.toggleDriverActivation.descriptionDetailedDeactivate')}
            </Typography>
            <Typography fontWeight="700">
                {t('areYouSureToProceed')}
            </Typography>
        </Modal>
    );
};

export default ConfirmToggleDriverActivationModal;
