import { SvgIconComponent } from '@mui/icons-material';
import { SvgIcon, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { COLORS } from '../../constants/colors';

interface IconedLabelProps {
    icon: SvgIconComponent;
    label?: string;
    dataLabel?: string;
    suffix?: string;
    alignToTop?: boolean;
    colorOfIconAndLabel?: string;
    excludeAnotation?: boolean;
}

export const IconedLabel = ({
    icon, label, dataLabel,
    suffix = ': ', alignToTop,
    colorOfIconAndLabel, excludeAnotation,
}: IconedLabelProps) => {
    return (
        <Box display="flex" alignItems={alignToTop ? 'flex-start' : 'center'} justifyContent="flex-start">
            <SvgIcon
                component={icon}
                style={{
                    fontSize: '1rem',
                    marginRight: '.5rem',
                    color: colorOfIconAndLabel ?? COLORS.SLATE_GREY,
                }}
            />
            <Typography
                sx={{
                    fontSize: '0.75rem',
                    lineHeight: '0.875rem',
                    marginRight: '0.5rem',
                    overflow: 'hidden',
                }}
            >
                <strong style={{ color: colorOfIconAndLabel ?? COLORS.SLATE_GREY }}>{label}{!excludeAnotation && suffix} </strong>
                {dataLabel}
            </Typography>
        </Box>
    );
};

