/* eslint-disable complexity */
import { Box, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { getDateTimeFromDateTimePartials } from '../../../helpers/getDateTimeFromDateTimePartials';
import getRemainingTimeString from '../../../helpers/getRemainingTimeString';
import getTimeDelta from '../../../helpers/getTimeDelta';
import { RideStatus, getRideStatusKey } from '../../../helpers/rideStatusUtil';
import { CancellationPolicy } from '../api/types';
import withExpandable from './withExpandable';

const useStyles = makeStyles({
    heading: {
        marginBottom: '0',
        color: COLORS.BLUE_DARK,
        fontSize: '1rem',
        fontWeight: 700,
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    policyWrapper: { marginBottom: '0.5rem' },
});

interface CancellationPoliciesCardProps {
    isUtc: boolean;
    cancelledAt: string | null;
    cancellationPolicies: CancellationPolicy[];
    appliedCancellationPolicy?: CancellationPolicy | null;
    status: keyof typeof RideStatus | null;
    pickUpDate: Date | null;
    pickUpTime: Date | null;
    vehicleType?: string;
    departureTimeZone?: string;
    isVisible?: boolean;
    isTitleDisabled?: boolean;
    order?: number;
    renderChevrons: JSX.Element;
    isExpanded: boolean;
}

const CancellationPoliciesCard = ({
    isUtc,
    cancelledAt,
    cancellationPolicies,
    appliedCancellationPolicy,
    status,
    pickUpDate,
    pickUpTime,
    vehicleType,
    departureTimeZone,
    isVisible = true,
    isTitleDisabled,
    order,
    renderChevrons,
    isExpanded,
}: CancellationPoliciesCardProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const cancelledAtDateTimeFormatted = useMemo(
        () => `${moment(cancelledAt).utc()
            .format(DATE_FORMATS['ddd DD-MMM-YYYY HH:mm A'])} UTC`,
        [cancelledAt]
    );

    const cancelledAtLocalDateTimeFormatted = useMemo(() => {
        if (!cancelledAt || !departureTimeZone) {
            return '';
        }

        return `${moment(utcToZonedTime(cancelledAt, departureTimeZone)).format(DATE_FORMATS['ddd DD-MMM-YYYY HH:mm A'])} ${t(
            'localTime'
        ).toLowerCase()}`;
    }, [cancelledAt, departureTimeZone, t]);

    const combinedDateTime = pickUpDate && pickUpTime ? getDateTimeFromDateTimePartials(pickUpDate, pickUpTime) : null;

    const pickupTimeDifference = useMemo(
        () => getRemainingTimeString(cancelledAt ?? '', combinedDateTime?.toUTCString() ?? ''),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cancelledAt, pickUpDate, pickUpTime]
    );

    const cancelledPickupTimeDelta = useMemo(
        () => getTimeDelta(cancelledAt ?? '', combinedDateTime?.toUTCString() ?? ''),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [cancelledAt, pickUpDate, pickUpTime]
    );

    if (cancellationPolicies.length === 0 || !isVisible) {
        return null;
    }

    return (
        <Box paddingBottom="0" order={order ?? 0}>
            <Grid className={classes.headingContainer} marginBottom={isExpanded ? '1rem' : '0'}>
                <Typography
                    variant="h3"
                    component="h4"
                    className={classes.heading}
                    color={`${isTitleDisabled ? COLORS.SLATE_GREY : COLORS.BLUE_DARK} !important`}
                >
                    {t(
                        appliedCancellationPolicy
                            ? 'pages.singleRide.polices.appliedCancellationPolicy'
                            : 'pages.singleRide.polices.cancellationPolicies'
                    )}
                </Typography>
                {renderChevrons}
            </Grid>

            {isExpanded && <>
                {!appliedCancellationPolicy && cancellationPolicies.map(policy => (
                    <div key={`policy_${policy.id}`} className={classes.policyWrapper}>
                        <Typography key={policy.id}>
                            {t('pages.singleRide.polices.cancelled', {
                                cancelledUnderHours: policy.cancelledUnderHours,
                                chargePercentage: policy.chargePercentage,
                            })}
                        </Typography>
                        {vehicleType && policy.vehicleTypes.includes(vehicleType) && (
                            <Typography>{t(
                                'pages.singleRide.polices.appliableFor',
                                { appliableCarTypes: vehicleType }
                            )}</Typography>
                        )}
                    </div>
                ))}
                {appliedCancellationPolicy && (
                    <div className={classes.policyWrapper}>
                        <Typography>
                            {t('pages.singleRide.polices.cancelled', {
                                cancelledUnderHours: appliedCancellationPolicy.cancelledUnderHours,
                                chargePercentage: appliedCancellationPolicy.chargePercentage,
                            })}
                        </Typography>
                        {vehicleType && appliedCancellationPolicy.vehicleTypes.includes(vehicleType) && (
                            <Typography>
                                {t('pages.singleRide.polices.appliableFor', { appliableCarTypes: vehicleType })}
                            </Typography>
                        )}
                    </div>
                )}
                {cancelledAt && status === getRideStatusKey(RideStatus.CANCELLED) && (
                    <Typography variant="h3" component="h4" className={classes.heading}>
                        {t('pages.singleRide.polices.cancelledAt')}
                        <Typography component="span" style={{ color: COLORS.BLACK }}>
                            {isUtc ? cancelledAtDateTimeFormatted : cancelledAtLocalDateTimeFormatted}{' '}
                            {`(${pickupTimeDifference} ${cancelledPickupTimeDelta < 0 ? 'after' : 'before'} pickup time)`}
                        </Typography>
                    </Typography>
                )}
                {cancelledAt && status === getRideStatusKey(RideStatus.CANCELLED) && !appliedCancellationPolicy && (
                    <Typography variant="h3" component="h4" className={classes.heading}>
                        {t('pages.singleRide.polices.noCancellationCost')}
                    </Typography>
                )}
            </>}
        </Box>
    );
};

export default withExpandable(CancellationPoliciesCard);
