import { Box, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../constants/colors';
import noResultsIcon from '../../public/assets/images/emptyAirline.png';
import theme from '../../styles/theme';

const useStyles = makeStyles({
    noResultsImage: {
        maxWidth: '470px',
        maxHeight: '210px',
        margin: '0 auto',
        display: 'block',
    },
    noResultsImageMobile: {
        maxWidth: '300px',
        maxHeight: '160px',
        margin: '0 auto',
        display: 'block',
    },
});

interface NoResultsProps {
    iconUrl?: string;
    iconClassName?: string;
    iconComponent?: ReactElement;
    text?: string;
    description?: string;
    additionalContent?: JSX.Element;
}

const NoResults = ({ iconUrl, iconClassName, text, description, additionalContent, iconComponent }: NoResultsProps) => {
    const classes = useStyles();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            margin="2rem 0"
        >
            <Box>
                {iconComponent
                    ? (
                        iconComponent
                    )
                    : (
                        <img
                            src={iconUrl ?? noResultsIcon}
                            alt="No results found"
                            className={
                                iconClassName
                            ?? (mobileView ? classes.noResultsImageMobile : classes.noResultsImage)
                            }
                        />
                    )}
            </Box>
            <Typography marginTop="2rem" fontWeight="700" color={COLORS.SLATE_GREY}>
                {text ?? t('noResultsFound')}.
            </Typography>
            {description && (
                <Typography marginTop="0.5rem" color={COLORS.SLATE_GREY} textAlign="center">
                    {description}
                </Typography>
            )}
            {additionalContent}
        </Box>
    );
};

export default NoResults;
