import moment from 'moment';

import { TIME_FORMATS } from '../constants/timeFormats';

export default function getScheduledArrival(
    pickUpTime: Date | null,
    durationInSeconds: number | undefined
) {
    if (!pickUpTime || !durationInSeconds) {
        return '';
    }

    const time = new Date(pickUpTime);

    const calculatedDateInSeconds = time.setSeconds(time.getSeconds() + durationInSeconds);

    return moment(calculatedDateInSeconds).format(TIME_FORMATS['HH:mm A']);
}
