import { SecondaryButton } from '@get-e/react-components';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import CopyToClipboardButton from '../../../components/buttons/CopyToClipboardButton';
import PageActionButton from '../../../components/buttons/PageActionButton';
import { SINGLE_RIDE_PAGE } from '../../../constants/amplitude/commonKeys';
import { SINGLE_RIDE_ADD_REMARKS, SINGLE_RIDE_CLOSE, SINGLE_RIDE_CONFIRM } from '../../../constants/amplitude/supplierKeys';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { RideStatus, getRideStatusKey } from '../../../helpers/rideStatusUtil';
import theme from '../../../styles/theme';
import StatusBadge from '../../rides/components/StatusBadge';
import CloseRideModal from '../../rides/supplier/components/CloseRideModal';
import { RideAmplitudeEventProperties } from '../api/types';
import { useRide } from '../api/useRide';
import CancellationPoliciesCard from '../components/CancellationPoliciesCard';
import RideReview from '../components/RideReview';
import AdditionalCostsCard from './components/AdditionalCostsCard';
import ConfirmRideModal from './components/ConfirmRideModal';
import DriverInformationCard from './components/DriverInformationCard';
import ReferenceCard from './components/ReferenceCard';
import RemarksModal from './components/RemarksModal';
import RideLoadingSkeleton from './components/rideLoadingSkeleton/RideLoadingSkeleton';
import RideMapCard from './components/RideMapCard';
import RideSummaryCard from './components/rideSummaryCard/RideSummaryCard';
import { RideContext } from './context/RideContext';
import rideStyles from './Ride.styles';

const Ride = () => {
    const classes = rideStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { rideId }: { rideId: string } = useParams();
    const [isConfirmRideModalOpen, setIsConfirmRideModalOpen] = useState(false);
    const [isCloseRideModalOpen, setIsCloseRideModalOpen] = useState(false);
    const [isRemarksModalOpen, setIsRemarksModalOpen] = useState(false);
    const [supplierReference, setSupplierReference] = useState<string | null>(null);
    const [mapKey, setMapKey] = useState(0);
    const isPageOpened = useRef(false);

    const { data, isLoading, isFetching: isFetchingRide, refetch } = useRide(rideId);

    useEffect(() => {
        if (data?.supplierReference) {
            setSupplierReference(data.supplierReference);
        }
    }, [data?.supplierReference, setSupplierReference]);

    const amplitudeEventProperties = useMemo(() => {
        return {
            'Ride number': data?.prettifiedUnid,
            'Ride created time': moment.utc(data?.createdAt).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
            'Ride pickup time': moment.utc(data?.pickUp.departAt).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
            'Ride customer': data?.customer,
            'Ride supplier': data?.supplier,
            'Ride status': data?.status,
            'Ride situation tags': data?.situationTag,
            'Ride qc status': data?.qcStatus,
            'Ride has driver': Boolean(data?.driver),
        } as RideAmplitudeEventProperties;
    }, [
        data?.createdAt,
        data?.customer,
        data?.driver,
        data?.pickUp.departAt,
        data?.prettifiedUnid,
        data?.qcStatus,
        data?.situationTag,
        data?.status,
        data?.supplier,
    ]);

    const isConfirmRideButtonVisible = useMemo(
        () => data?.status && [
            getRideStatusKey(RideStatus.TO_CONFIRM),
            getRideStatusKey(RideStatus.TO_CONFIRM_CHANGE),
        ].includes(data.status),
        [data?.status]
    );

    const isCloseRideButtonVisible = useMemo(
        () => data?.status === getRideStatusKey(RideStatus.TO_CLOSE),
        [data?.status]
    );

    const isReferenceAndRemarksButtonEnabled = useMemo(
        () => data?.status && [
            getRideStatusKey(RideStatus.TO_CONFIRM_CHANGE),
            getRideStatusKey(RideStatus.CONFIRMED),
            getRideStatusKey(RideStatus.DRIVER_ON_THE_WAY),
            getRideStatusKey(RideStatus.DRIVER_AT_PICKUP),
            getRideStatusKey(RideStatus.PASSENGER_ON_BOARD),
            getRideStatusKey(RideStatus.ACTIVE),
            getRideStatusKey(RideStatus.TO_CLOSE),
        ].includes(data.status),
        [data?.status]
    );

    const pickUpDate = useMemo(() => moment(data?.pickUp?.departAt).toDate(), [data?.pickUp?.departAt]);

    const handleConfirmRide = () => {
        setIsConfirmRideModalOpen(true);
        logAmplitudeEvent(SINGLE_RIDE_CONFIRM, amplitudeEventProperties);
    };

    const handleCloseRide = () => {
        setIsCloseRideModalOpen(true);
        logAmplitudeEvent(SINGLE_RIDE_CLOSE, amplitudeEventProperties);
    };

    const handleAddRemarks = async () => {
        setIsRemarksModalOpen(true);
        await refetch();
        logAmplitudeEvent(SINGLE_RIDE_ADD_REMARKS, amplitudeEventProperties);
    };

    const handleConfirmCloseFinished = async () => {
        await refetch();
        setMapKey(Math.random());
    };

    const handleDrivarManageFinished = async () => {
        await refetch();
        setMapKey(Math.random());
    };

    if (!data || isLoading) {
        return <RideLoadingSkeleton />;
    }

    if (!isPageOpened.current) {
        isPageOpened.current = true;
        logAmplitudeEvent(SINGLE_RIDE_PAGE, amplitudeEventProperties);
    }

    return (
        <RideContext.Provider
            value={{
                isDisabled: false,
                rideResponse: data,
                amplitudeEventProperties,
            }}
        >
            <Grid container className={classes.mainWrapper}>
                <Grid item xs className={classes.headerTitleWrapper}>
                    <Grid item className={classes.headerTitle}>
                        <Typography className={classes.headerSecondaryTitle} >
                            {t('ride')} {data?.prettifiedUnid}
                            <CopyToClipboardButton text={data.prettifiedUnid} padding="0 1rem 0 0.5rem" />
                        </Typography>
                        <StatusBadge
                            status={data?.status}
                            trackingStatus={data?.trackingStatus}
                            pickupDateTimeUtc={data?.pickUp.departAt}
                        />
                    </Grid>
                    <Grid item>
                        <PageActionButton
                            isVisible={isConfirmRideButtonVisible}
                            onClick={handleConfirmRide}
                            isLoading={isFetchingRide}
                        >
                            {t('buttonName.confirmRide')}
                        </PageActionButton>
                        <PageActionButton
                            isVisible={isCloseRideButtonVisible}
                            onClick={handleCloseRide}
                            isLoading={isFetchingRide}
                        >
                            {t('buttonName.closeRide')}
                        </PageActionButton>
                    </Grid>
                </Grid>
                <Grid container display="flex" direction="row" columnSpacing={4}>
                    <Grid item md={8} xs={12}>
                        <RideMapCard mapKey={mapKey} isVisible={isMobile} marginBottom="1rem" />
                        <RideSummaryCard />
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                        paddingBottom="1.5rem"
                        display="flex"
                        flexDirection="column"
                    >
                        <RideMapCard mapKey={mapKey} isVisible={!isMobile} />
                        <RideReview
                            isVisible={data.status === getRideStatusKey(RideStatus.COMPLETED)}
                            feedbacks={data.feedbacks}
                            displayTitle={t('pages.rides.review')}
                            disableEditing
                            isCompactDesign
                        />
                        <DriverInformationCard
                            driver={data.driver}
                            rideId={data.unid}
                            onDriverManageFinished={handleDrivarManageFinished}
                            isVisible
                            order={isMobile ? 1 : 0}
                        />
                        <ReferenceCard
                            isVisible
                            isDisabled
                            reference={supplierReference}
                            setReference={setSupplierReference}
                            order={isMobile ? 2 : 0}
                        />
                        <Box marginBottom="1rem" order={isMobile ? 2 : 0}>
                            <SecondaryButton
                                onClick={handleAddRemarks}
                                className={classes.referenceAndRemarksButton}
                                disabled={!isReferenceAndRemarksButtonEnabled}
                            >
                                {t('buttonName.referenceAndRemarks')}
                            </SecondaryButton>
                        </Box>
                        <CancellationPoliciesCard
                            isUtc
                            isVisible
                            cancelledAt={data?.cancelledAt}
                            cancellationPolicies={data?.cancellationPolicies ?? []}
                            status={data?.status}
                            pickUpDate={pickUpDate}
                            pickUpTime={pickUpDate}
                            vehicleType={data?.vehicle.type}
                            departureTimeZone={data?.pickUp.departureTimeZone}
                            appliedCancellationPolicy={data?.appliedCancellationPolicy}
                            order={isMobile ? 3 : 0}
                        />
                        <AdditionalCostsCard order={isMobile ? 4 : 0} />
                    </Grid>
                </Grid>
            </Grid>
            {isConfirmRideModalOpen && (
                <ConfirmRideModal
                    isModalOpen={isConfirmRideModalOpen}
                    onClose={() => {
                        setIsConfirmRideModalOpen(false);
                        setMapKey(Math.random());
                    }}
                    onFinished={handleConfirmCloseFinished}
                    ride={data}
                    supplierReference={supplierReference ? supplierReference : undefined}
                />
            )}
            {isCloseRideModalOpen && (
                <CloseRideModal
                    isModalOpen={isCloseRideModalOpen}
                    onClose={() => setIsCloseRideModalOpen(false)}
                    onFinished={handleConfirmCloseFinished}
                    ride={data}
                    supplierReference={supplierReference ? supplierReference : undefined}
                />
            )}
            {isRemarksModalOpen && (
                <RemarksModal
                    rideId={rideId}
                    isModalOpen={isRemarksModalOpen}
                    onClose={() => setIsRemarksModalOpen(false)}
                    onFinished={handleConfirmCloseFinished}
                    reference={data.supplierReference || undefined}
                />
            )}
        </RideContext.Provider>
    );
};

export default Ride;
