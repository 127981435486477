import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconedData from '../../../../components/iconedData/IconedData';
import { COLORS } from '../../../../constants/colors';
import { RideResponse } from '../../api/types';
import withExpandable from '../../components/withExpandable';

const useStyles = makeStyles({
    referencesContainer: {
        width: '100%',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '0.5rem',
        marginTop: '1rem',
        padding: '1rem',
        position: 'relative',
    },
    headingContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    heading: {
        marginBottom: '0',
        color: COLORS.BLUE,
        fontSize: '1rem',
        fontWeight: 700,
    },
});

interface ReferencesCardProps {
    data?: RideResponse;
    renderChevrons: JSX.Element;
    isExpanded: boolean;
}

const ReferencesCard = ({ data, renderChevrons, isExpanded }: ReferencesCardProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container className={classes.referencesContainer}>
            <Grid className={classes.headingContainer}>
                <Typography variant="h3" component="h4" className={classes.heading}>
                    {t('pages.singleRide.references.title')}
                </Typography>
                {renderChevrons}
            </Grid>

            {isExpanded && <>
                <IconedData
                    topMargin={isExpanded ? '1rem' : '0'}
                    dataTextBold
                    icon={ReceiptLongIcon}
                    dataLabel={t('pages.singleRide.references.reference')}
                    data={data?.customerReference || '-'}
                    dataLabelColor={COLORS.BLACK}
                    isColumn
                />

                {data?.customFields?.map(field => (
                    <IconedData
                        topMargin="0.5rem"
                        key={field.id}
                        dataTextBold
                        icon={ReceiptLongIcon}
                        dataLabel={field.label}
                        data={field.value || '-'}
                        dataLabelColor={COLORS.BLACK}
                        isColumn
                    />
                ))}
            </>}
        </Grid>
    );
};

export default withExpandable(ReferencesCard);
