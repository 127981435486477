/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { NumberedTab, NumberedTabs } from '@get-e/react-components';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GRID_DETAIL_PANEL_TOGGLE_FIELD, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import PageActionButton from '../../components/buttons/PageActionButton';
import { COLORS } from '../../constants/colors';
import { USE_ACTIVE_USERS, USE_DEACTIVE_USERS } from '../../constants/queryKeys';
import { USERS } from '../../constants/urlPaths';
import { useCurrentProfileContext } from '../../context/CurrentProfileContext';
import { Severity, useNotificationContext } from '../../context/NotificationContext';
import theme from '../../styles/theme';
import { useInvitedUsers, useUsers } from './api';
import { User } from './api/types';
import ActiveUsersDataGrid from './components/ActiveUsersDataGrid';
import AddUserModal from './components/AddUserModal';
import ChangeUserStatusModal from './components/ChangeUserStatusModal';
import DeactivatedUsersDataGrid from './components/DeactivatedUsersDataGrid';
import EditUserModal from './components/EditUserModal';
import EditUserProfileModal from './components/EditUserProfileModal';
import InvitedUsersDataGrid from './components/InvitedUsersDataGrid';
import RevokeUserModal from './components/RevokeUserModal';

const useStyles = makeStyles(styleTheme => ({
    mainWrapper: {
        alignItems: 'center',
        padding: 0,
    },
    mainWrapperTopPadding: { paddingTop: '3.5rem' },
    headerTitleWrapper: {
        marginBottom: '2rem',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        [styleTheme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            marginTop: '2rem',
        },
    },
    headerTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 'auto',
        [styleTheme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0.5rem',
        },
    },
    content: { order: 2 },
}));

export enum ChangeStatus {
    REVOKE_USER = 'buttonName.revokeInvite',
    EDIT_USER = 'buttonName.editUser',
    DEACTIVATE_USER = 'buttonName.deactivate',
    REACTIVATE_USER = 'buttonName.reactivate',
}

export enum ActiveTab {
    Active,
    Invited,
    Deactivated,
}

export enum ActiveTabUrl {
    Active = 'active',
    Invited = 'invited',
    Deactivated = 'deactivated',
}

const getActiveTab = (url: string): ActiveTab => {
    switch (url) {
        case USERS:
        case `${USERS}/${ActiveTabUrl.Active}`:
            return ActiveTab.Active;
        case `${USERS}/${ActiveTabUrl.Invited}`:
            return ActiveTab.Invited;
        case `${USERS}/${ActiveTabUrl.Deactivated}`:
            return ActiveTab.Deactivated;
        default:
            throw new Error(`Unhandled URL "${url}"`);
    }
};

export const MOBILE_TABLE_HEIGHT = 'calc(100vh - 235px)';
export const TABLE_HEIGHT = 'calc(100vh - 217px)';

export const getTableWrapperHeight = (isMobile: boolean, isNoRows: boolean): string => {
    const height = isMobile ? MOBILE_TABLE_HEIGHT : TABLE_HEIGHT;

    return isNoRows ? 'auto' : height;
};

export const getTableHeight = (isMobile: boolean, isNoRows: boolean): string => {
    const height = isMobile ? MOBILE_TABLE_HEIGHT : TABLE_HEIGHT;

    return isNoRows ? height : '100%';
};

const Users = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const activeTab = getActiveTab(history.location.pathname);
    const { url } = useRouteMatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [invitedCount, setInvitedCount] = useState<number | null>(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
    const [isRevokeUserModalOpen, setIsRevokeUserModalOpen] = useState(false);
    const [isEditUserProfileModal, setIsEditUserProfileModal] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isLoadingActive, setIsLoadingActive] = useState(true);
    const [isLoadingInvited, setIsLoadingInvited] = useState(true);
    const [isLoadingDeactivated, setIsLoadingDeactivated] = useState(true);
    const [activeCount, setActiveCount] = useState<number | null>(null);
    const [deactivatedCount, setDeactivatedCount] = useState<number | null>(null);
    const selectedUser = useRef<User>();
    const isUserStatusActivate = useRef(false);
    const { showNotification } = useNotificationContext();
    const { currentProfile, refetchUserProfile } = useCurrentProfileContext();

    const {
        data: activeUsers,
        isFetching: isFetchingActiveUsers,
        refetch: refetchActiveUsers,
    } = useUsers(USE_ACTIVE_USERS, false);

    const { data: invitedUsers, isFetching: isFetchingInvitedUsers, refetch: refetchInvitedUsers } = useInvitedUsers();

    const {
        data: deactivatedUsers,
        isFetching: isFetchingDeativatedUsers,
        refetch: refetchDeactivatedUsers,
    } = useUsers(USE_DEACTIVE_USERS, true);

    useEffect(() => {
        if (activeUsers) {
            setActiveCount(activeUsers.length);
            setIsLoadingActive(false);
        }
    }, [activeUsers]);

    useEffect(() => {
        if (invitedUsers) {
            setInvitedCount(invitedUsers.length);
            setIsLoadingInvited(false);
        }
    }, [invitedUsers]);

    useEffect(() => {
        if (deactivatedUsers) {
            setDeactivatedCount(deactivatedUsers.length);
            setIsLoadingDeactivated(false);
        }
    }, [deactivatedUsers]);

    const onInviteUser = () => {
        showNotification(t('pages.users.notifications.inviteSuccessfullySent'), Severity.Info);
        refetchInvitedUsers();
    };

    const onEditUser = () => {
        showNotification(t('pages.users.notifications.userSuccessfullyEdited'), Severity.Info);
        refetchActiveUsers();
        refetchDeactivatedUsers();
    };

    const onRevokeUser = () => {
        showNotification(t('pages.users.notifications.invitationSuccessfullyRevoked'), Severity.Info);
        refetchInvitedUsers();
    };

    const onUpdateUserStatus = () => {
        showNotification(
            t(
                isUserStatusActivate.current
                    ? 'pages.users.notifications.userSuccessfullyActivated'
                    : 'pages.users.notifications.userSuccessfullyDeactivated'
            ),
            Severity.Info
        );
        refetchActiveUsers();
        refetchDeactivatedUsers();
    };

    const handleEdit = (user: User) => {
        selectedUser.current = user;

        if (currentProfile.id.toString() === user.id.toString()) {
            setIsEditUserProfileModal(true);
        } else {
            setIsEditModalOpen(true);
        }
    };

    const handleChangeStatus = (user: User, isActive: boolean) => {
        selectedUser.current = user;
        isUserStatusActivate.current = isActive;
        setIsDeactivateModalOpen(true);
    };

    const handleRevoke = (user: User) => {
        selectedUser.current = user;
        setIsRevokeUserModalOpen(true);
    };

    const handleUserProfileUpdate = async () => {
        await refetchUserProfile();
    };

    const columnVisibilityModel: GridColumnVisibilityModel = useMemo(() => ({
        email: !isMobile,
        permissions: !isMobile,
        lastSeen: activeTab === ActiveTab.Invited ? false : !isMobile,
        actions: !isMobile,
        [GRID_DETAIL_PANEL_TOGGLE_FIELD]: isMobile,
    }
    ), [activeTab, isMobile]);

    return (
        <Grid container className={classes.mainWrapper}>
            <Grid item xs={12} className={classes.headerTitleWrapper}>
                <Grid item className={classes.headerTitle}>
                    <Typography
                        style={{
                            color: COLORS.BLUE,
                            fontSize: '1.5rem',
                            fontWeight: 700,
                            paddingRight: '1rem',
                        }}
                    >
                        {t('users')}
                    </Typography>
                </Grid>
                <Grid item>
                    <PageActionButton
                        onClick={() => setIsAddModalOpen(true)}
                        isLoading={isFetchingActiveUsers || isFetchingInvitedUsers || isFetchingDeativatedUsers}
                    >
                        <AddIcon style={{ paddingRight: '0.5rem' }} />
                        {t('pages.users.inviteUser')}
                    </PageActionButton>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <NumberedTabs value={activeTab}>
                    <NumberedTab
                        number={activeCount}
                        label={t('active')}
                        url={`${url}/${ActiveTabUrl.Active}`}
                        index={ActiveTab.Active}
                        selectedIndex={activeTab}
                        loading={isLoadingActive}
                    />
                    <NumberedTab
                        number={invitedCount}
                        label={t('pages.users.tabs.invited')}
                        url={`${url}/${ActiveTabUrl.Invited}`}
                        index={ActiveTab.Invited}
                        selectedIndex={activeTab}
                        loading={isLoadingInvited}
                    />
                    <NumberedTab
                        number={deactivatedCount}
                        label={t('deactivated')}
                        url={`${url}/${ActiveTabUrl.Deactivated}`}
                        index={ActiveTab.Deactivated}
                        selectedIndex={activeTab}
                        loading={isLoadingDeactivated}
                    />
                </NumberedTabs>
            </Grid>
            <Grid container spacing={0} className={classes.content}>
                {activeTab === ActiveTab.Active && (
                    <ActiveUsersDataGrid
                        data={activeUsers}
                        isFetching={isFetchingActiveUsers}
                        value={activeTab}
                        onEdit={handleEdit}
                        onChangeStatus={handleChangeStatus}
                        columnVisibilityModel={columnVisibilityModel}
                    />
                )}
                {activeTab === ActiveTab.Invited && (
                    <InvitedUsersDataGrid
                        data={invitedUsers}
                        isFetching={isFetchingInvitedUsers}
                        value={activeTab}
                        onRevoke={handleRevoke}
                        columnVisibilityModel={columnVisibilityModel}
                    />
                )}
                {activeTab === ActiveTab.Deactivated && (
                    <DeactivatedUsersDataGrid
                        data={deactivatedUsers}
                        isFetching={isFetchingDeativatedUsers}
                        value={activeTab}
                        onEdit={handleEdit}
                        onChangeStatus={handleChangeStatus}
                        columnVisibilityModel={columnVisibilityModel}
                    />
                )}
            </Grid>
            {isAddModalOpen && (
                <AddUserModal isOpen={isAddModalOpen} onInviteUser={onInviteUser} onClose={() => setIsAddModalOpen(false)} />
            )}
            {isEditModalOpen && selectedUser.current && (
                <EditUserModal
                    user={selectedUser.current}
                    isOpen={isEditModalOpen}
                    onEditUser={onEditUser}
                    onClose={() => setIsEditModalOpen(false)}
                />
            )}
            {isDeactivateModalOpen && selectedUser.current && (
                <ChangeUserStatusModal
                    user={selectedUser.current}
                    isActive={isUserStatusActivate.current}
                    onClose={() => setIsDeactivateModalOpen(false)}
                    onUpdateUserStatus={onUpdateUserStatus}
                />
            )}
            {isRevokeUserModalOpen && selectedUser.current && (
                <RevokeUserModal
                    user={selectedUser.current}
                    onClose={() => setIsRevokeUserModalOpen(false)}
                    onRevokeUser={onRevokeUser}
                />
            )}
            {isEditUserProfileModal && currentProfile && (
                <EditUserProfileModal
                    user={currentProfile}
                    onClose={() => setIsEditUserProfileModal(false)}
                    onUserProfileUpdated={handleUserProfileUpdate}
                />)}
        </Grid>
    );
};

export default Users;
