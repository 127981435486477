import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarIcon from '@mui/icons-material/Star';
import { Box, Grid, Rating, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';

interface SummaryBasicCardProps {
    title?: string;
    percentage1?: string;
    percentage2?: string;
    text1?: string;
    text2?: string;
    numberOfReviews?: number;
    rating?: number;
    titleTooltip?: string;
    excludePercentage1?: boolean;
    includeTotalRidesText?: boolean;
}

const SummaryBasicCard = ({
    title = '',
    percentage1 = '',
    percentage2,
    text1,
    text2,
    numberOfReviews,
    rating,
    titleTooltip = '',
    excludePercentage1,
    includeTotalRidesText,
}: SummaryBasicCardProps) => {
    const { t } = useTranslation();

    return (
        <Grid
            container
            style={{
                width: '100%',
                height: 'calc(100% - 1rem)',
                flexDirection: 'column',
                border: `1px solid ${COLORS.BLACK_12}`,
                borderRadius: '3px',
                marginTop: '1rem',
            }}
        >
            <Grid item padding="1rem" display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                <Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="1.25rem">
                        <Box display="flex" flexDirection="row">
                            <Typography fontWeight="700" color={COLORS.SLATE_GREY}>
                                {title}
                            </Typography>
                            {percentage2 && (
                                <Typography
                                    fontWeight="700"
                                    color={parseInt(percentage2) < 0
                                        ? COLORS.RED
                                        : COLORS.GREEN}
                                    paddingLeft="0.25rem"
                                >
                                    {`${percentage2}%`}
                                </Typography>
                            )}
                        </Box>
                        {titleTooltip && (
                            <Tooltip title={titleTooltip} arrow placement="top" enterTouchDelay={0}>
                                <InfoOutlinedIcon style={{ color: COLORS.SLATE_GREY }} />
                            </Tooltip>
                        )}
                    </Box>
                    {percentage1 && (
                        <Box display="flex" alignItems="baseline" marginBottom="1.875rem">
                            <Typography fontSize="1.5rem" fontWeight="700" color={COLORS.BLACK}>
                                {percentage1}{Boolean(!excludePercentage1) && '%'}
                            </Typography>
                            {includeTotalRidesText && <Typography marginLeft=".5rem" fontSize="0.75rem" color={COLORS.SLATE_GREY}>
                                {t('pages.reports.ofTotalRide')}</Typography>}
                        </Box>
                    )}
                    {(rating || rating === 0) && (
                        <Box display="flex" alignItems="center" marginBottom="1rem">
                            <Box display="flex" flexDirection="column" alignItems="center" marginRight="0.5rem">
                                <Typography fontSize="1.5rem" fontWeight="700" color={COLORS.BLACK}>
                                    {rating}
                                </Typography>
                                {(numberOfReviews || numberOfReviews === 0) && (
                                    <Typography fontSize="0.75rem" color={COLORS.SLATE_GREY} textTransform="lowercase">
                                        {`${numberOfReviews} ${t('reviews')}`}
                                    </Typography>
                                )}
                            </Box>
                            <Rating
                                value={rating}
                                readOnly
                                style={{ paddingBottom: '0.25rem' }}
                                icon={<StarIcon style={{ color: COLORS.ORANGE_OFFICIAL }} />}
                            />
                        </Box>
                    )}
                </Box>
                <Box>
                    {text1 && <Typography color={COLORS.BLACK}>{text1}</Typography>}
                    {text2 && (
                        <Typography fontSize="0.75rem" color={COLORS.SLATE_GREY}>
                            {text2}
                        </Typography>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default SummaryBasicCard;
