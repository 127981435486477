import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PersonRemoveOutlined from '@mui/icons-material/PersonRemoveOutlined';
import { Tooltip, Typography, useMediaQuery } from '@mui/material';
import {
    GridRenderCellParams,
    GridRowParams,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridActionsCellItem,
    GridRowId,
} from '@mui/x-data-grid-pro';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DetailPanelToggle } from '../../../components/detailPanelToggle/DetailPanelToggle';
import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { useCurrentProfileContext } from '../../../context/CurrentProfileContext';
import useDataGridStyles from '../../../styles/DataGrid';
import theme from '../../../styles/theme';
import { User } from '../api/types';
import { ActiveTab, ChangeStatus } from '../Users';

const COLUMN_WIDTH = 230;

export interface UseUserColumnsProps {
    activeTab?: ActiveTab;
    handleDeactivate?: (user: User) => void;
    handleReactivate?: (user: User) => void;
    handleEdit?: (user: User) => void;
    handleRevoke?: (user: User) => void;
}

export const useUsersColumns = ({
    handleDeactivate,
    handleReactivate,
    handleEdit,
    handleRevoke,
}: UseUserColumnsProps) => {
    const { t } = useTranslation();
    const dataGridClases = useDataGridStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { currentProfile } = useCurrentProfileContext();

    const onActionItemClick = useCallback((user: User, action: ChangeStatus) => {
        switch (action) {
            case ChangeStatus.EDIT_USER:
                handleEdit && handleEdit(user);
                break;
            case ChangeStatus.DEACTIVATE_USER:
                handleDeactivate && handleDeactivate(user);
                break;
            case ChangeStatus.REACTIVATE_USER:
                handleReactivate && handleReactivate(user);
                break;
            case ChangeStatus.REVOKE_USER:
                handleRevoke && handleRevoke(user);
                break;
            default:
                throw new Error('Driver action not supported');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(
        () => [
            {
                field: 'fullName',
                headerName: t('name'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    if (isMobile) {
                        return (
                            <Tooltip title={params.row.fullName} hidden={!params.row.fullName} placement="top" arrow>
                                <Typography
                                    fontWeight="700"
                                    fontSize="1rem"
                                    overflow="hidden"
                                    whiteSpace="nowrap"
                                    textOverflow="ellipsis"
                                    paddingLeft="0.375rem"
                                >
                                    {params.row.fullName}
                                </Typography>
                            </Tooltip>
                        );
                    }

                    return <Typography>{params.row.fullName}</Typography>;
                },
            },
            {
                field: 'email',
                headerName: t('email'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
            },
            {
                field: 'permissions',
                headerName: t('pages.users.fields.permissions'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                    <>
                        {!params.row.isDeactivated && params.row.permissions.manageUsers && (
                            <div className="permissionsCell">
                                <PersonOutlinedIcon fontSize="small" />
                                {t('users')}
                            </div>
                        )}
                        {!params.row.isDeactivated && params.row.permissions.manageRidesBookedByOthers && (
                            <div className="permissionsCell">
                                <DirectionsCarOutlinedIcon fontSize="small" />
                                {t('rides')}
                            </div>
                        )}
                        {params.row.isDeactivated && (
                            <div className="permissionsCell">
                                <PersonOffOutlinedIcon fontSize="small" />
                                {t('deactivated')}
                            </div>
                        )}
                    </>
                ),
            },
            {
                field: 'lastSeen',
                headerName: t('pages.users.fields.lastSeen'),
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                    <Typography>
                        {params.row.lastSeen === null
                            ? t('pages.users.lastSeenNever')
                            : moment(params.row.lastSeen).format(DATE_FORMATS['DD MMM YYYY HH:mm'])}
                    </Typography>
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                flex: 1,
                maxWidth: 50,
                headerClassName: dataGridClases.hideSeparator,
                getActions: (params: GridRowParams) => {
                    const actionItems: JSX.Element[] = [];

                    if (handleEdit) {
                        actionItems.push(
                            <GridActionsCellItem
                                key={ChangeStatus.EDIT_USER}
                                className={dataGridClases.menuItem}
                                icon={<CreateOutlinedIcon fontSize="small" style={{ color: COLORS.BLUE_DARK }} />}
                                label={t('pages.users.menu.edit')}
                                onClick={() => onActionItemClick(params.row, ChangeStatus.EDIT_USER)}
                                showInMenu
                            />
                        );
                    }

                    if (handleDeactivate
                        && currentProfile.id.toString() !== params.row.id.toString() && !params.row.isDeactivated) {
                        actionItems.push(
                            <GridActionsCellItem
                                key={ChangeStatus.DEACTIVATE_USER}
                                className={dataGridClases.menuItem}
                                icon={<PersonRemoveOutlined fontSize="small" style={{ color: COLORS.BLUE_DARK }} />}
                                label={t('pages.users.menu.deactivate')}
                                onClick={() => onActionItemClick(params.row, ChangeStatus.DEACTIVATE_USER)}
                                showInMenu
                            />
                        );
                    }

                    if (handleReactivate) {
                        actionItems.push(
                            <GridActionsCellItem
                                key={ChangeStatus.REACTIVATE_USER}
                                className={dataGridClases.menuItem}
                                icon={<PersonAddAlt1OutlinedIcon fontSize="small" style={{ color: COLORS.BLUE_DARK }} />}
                                label={t('pages.users.menu.reactivate')}
                                onClick={() => onActionItemClick(params.row, ChangeStatus.REACTIVATE_USER)}
                                showInMenu
                            />
                        );
                    }

                    if (handleRevoke) {
                        actionItems.push(
                            <GridActionsCellItem
                                key={ChangeStatus.REVOKE_USER}
                                className={dataGridClases.menuItem}
                                icon={<PersonOffOutlinedIcon fontSize="small" style={{ color: COLORS.BLUE_DARK }} />}
                                label={t('pages.users.menu.revoke')}
                                onClick={() => onActionItemClick(params.row, ChangeStatus.REVOKE_USER)}
                                showInMenu
                            />
                        );
                    }

                    return actionItems;
                },
            },
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                headerClassName: dataGridClases.hideSeparator,
                renderCell: (params: { id: GridRowId; value: boolean }) =>
                    <DetailPanelToggle id={params.id} value={params.value} />,
            },
        ],
        [
            t,
            dataGridClases.hideSeparator,
            dataGridClases.menuItem,
            isMobile,
            handleEdit,
            handleDeactivate,
            currentProfile.id,
            handleReactivate,
            handleRevoke,
            onActionItemClick,
        ]
    );
};
