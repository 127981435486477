import moment from 'moment';

import { CancellationPolicy, RideResponse } from '../pages/ride/api/types';
import { RideStatus, getRideStatusKey } from './rideStatusUtil';

export const calculateCancelledPrice = (data: RideResponse): string => {
    if (data?.status !== getRideStatusKey(RideStatus.CANCELLED)) {
        return (Math.round((data?.price?.amount ?? 0) * 100) / 100).toFixed(2);
    }

    const cancelledAtMoment = moment(data?.cancelledAt);
    const departAtMoment = moment(data?.pickUp.departAt);
    const minutesUntilPickup = departAtMoment.diff(cancelledAtMoment, 'minutes');
    const hoursUntilPickup = minutesUntilPickup / 60;

    const applicablePolicy: CancellationPolicy | undefined = data?.cancellationPolicies
        .sort((one: CancellationPolicy, two: CancellationPolicy) => one.cancelledUnderHours - two.cancelledUnderHours)
        .find((policy: CancellationPolicy) =>
            (policy.vehicleTypes.length === 0 || policy.vehicleTypes.includes(data?.vehicle.type))
            && hoursUntilPickup <= policy.cancelledUnderHours);

    return applicablePolicy
        ? (data?.price.amount * (applicablePolicy.chargePercentage / 100)).toFixed(2)
        : (Math.round((data?.price?.amount ?? 0) * 100) / 100).toFixed(2);
};
