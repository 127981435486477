import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { USE_FLIGHT_SEARCH } from '../../../../constants/queryKeys';
import apiClient from '../../../../services/api';
import { SEARCH_FLIGHT } from '../../../../services/routes';
import { FlightDetails, FlightSearchResponse, FlightType } from '../../../rides/api/types';

export function useFlightSearch(flightId: string, flightDate: string) {
    const {
        data,
        isLoading,
        isError,
        refetch,
    } = useQuery([USE_FLIGHT_SEARCH + flightId], () => searchFlight(flightId, flightDate), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
    });

    return useMemo(() => {
        const flightDetails: FlightDetails = {
            number: data?.id.toString() ?? '',
            type: FlightType.ARRIVAL,
            airlineName: data?.airlineName ?? '',
            departure: {
                datetimeLocal: data?.departure.dateTimeLocal ?? '',
                datetimeZulu: data?.departure.dateTimeZulu ?? '',
                iata: data?.departure.iata ?? '',
                name: data?.departure.name ?? '',
            },
            arrival: {
                datetimeLocal: data?.arrival.dateTimeLocal ?? '',
                datetimeZulu: data?.arrival.dateTimeZulu ?? '',
                iata: data?.arrival.iata ?? '',
                name: data?.arrival.name ?? '',
            },
        };

        return {
            flightDetails,
            isLoading,
            refetch,
            isError,
        };
    }, [data, isLoading, refetch, isError]);
}

export const searchFlight = async (flightId: string, flightDate: string): Promise<FlightSearchResponse> => {
    const { data } = await apiClient.get<FlightSearchResponse>(SEARCH_FLIGHT, {
        params: {
            flightId,
            flightDate,
        },
    });

    return data;
};
