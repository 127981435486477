import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { COLORS } from '../../constants/colors';
import { BUTTON_WIDTH } from '../../constants/layout';

const useModalStyles = makeStyles(styleTheme => createStyles({
    title: {
        padding: '0 2rem',
        margin: '2rem 0',
        fontWeight: 700,
        color: COLORS.BLUE,
    },
    content: {
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
    },
    actions: {
        padding: '2rem',
        position: 'relative',
    },
    buttonContainer: {
        display: 'flex',
        padding: 0,
        margin: '0 !important',
        justifyContent: 'flex-start',
        width: '100%',
        [styleTheme.breakpoints.only('xs')]: {
            justifyContent: 'space-between',
            marginTop: 'auto',
        },
    },
    button: {
        width: `${BUTTON_WIDTH} !important`,
        height: '44px',
    },
    confirmButton: { marginRight: '1rem' },
    closeButton: {
        position: 'absolute',
        right: 16,
        top: 28,
        color: COLORS.BLACK_12,
    },
    overlayGradient: {
        position: 'absolute',
        top: '-2rem',
        left: '2rem',
        width: 'calc(100% - 4rem)',
        height: '40px',
        backgroundImage: `linear-gradient(180deg, ${COLORS.WHITE_10}, ${COLORS.WHITE})`,
    },
}));

export default useModalStyles;
