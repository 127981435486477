import { ColorOption } from '../colorOptions';

export enum ChangeStatus {
    EDIT = 'EDIT',
    DEACTIVATE = 'DEACTIVATE',
    DUPLICATE = 'DUPLICATE',
    ACTIVATE = 'ACTIVATE'
}

export interface Vehicle {
    id: number;
    licensePlate: string;
    identifier: string;
    brand?: Entity | null;
    color?: string | ColorOption;
    model?: Entity | null;
    status: string;
    pictures: Photo[];
    isArchived?: boolean;
}

export interface Photo {
    id: number;
    url: string;
    isDefault: boolean;
}

export interface Entity {
    id?: number;
    name: string;
}

export interface ImageUrl {
    url: string;
    isDefault?: boolean;
}

export interface VehicleVariables {
    licensePlate: string;
    identifier?: string;
    brandId?: number;
    color: string;
    modelId?: number;
    pictures?: ImageUrl[];
}

export interface VehicleUpdateVariables extends VehicleVariables {
    id: number;
}
