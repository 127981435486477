import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { ColorOption } from '.';

const useStyles = makeStyles(() => ({
    circle: (props: { color?: string }) => ({
        width: '16px',
        height: '16px',
        border: `1px solid ${COLORS.LIGHT_GRAY}`,
        borderRadius: '50%',
        backgroundColor: props.color || COLORS.WHITE,
        marginRight: '10px',
    }),
}));

interface ColorCircleProps {
    option: ColorOption;
    props?: HTMLAttributes<HTMLLIElement>;
    paddingBox?: string;
    textBold?: boolean;
}

const ColorCircle = ({ option, props, paddingBox, textBold }: ColorCircleProps) => {
    const { t } = useTranslation();
    const classes = useStyles({ color: option.color });

    return (
        <Box
            component="li"
            {...props}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                color: COLORS.SLATE_GREY,
                padding: paddingBox || '.75rem 1rem',
            }}
        >
            <Box className={classes.circle}></Box>
            <Typography marginRight="0.2rem" color={COLORS.BLACK} fontWeight={textBold ? 700 : 400}>
                {t(option.name)}
            </Typography>
        </Box>
    );
};

export default ColorCircle;
