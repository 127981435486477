export enum PrimaryTimeZone {
    LOCAL = 'LOCAL',
    UTC = 'UTC'
}

export enum AccountType {
    CUSTOMER = 'Customer',
    SUPPLIER = 'Supplier'
}

export interface Coordinates {
    lat: number;
    lon: number;
}

export const AccountTypeAbbreviation = {
    Customer: 'A',
    Supplier: 'P',
};

export enum FileType {
    EXCEL = 'excel',
    PDF = 'pdf'
}
