import { Dispatch, SetStateAction, createContext } from 'react';

export enum RideSummaryAccordions {
    RideInformationSummary = 'RideInformationSummary',
    VehicleInformationSummary = 'VehicleInformationSummary',
    PassengerInformationSummary = 'PassengerInformationSummary'
}

export interface RideSummaryAccordionsExpanded {
    [RideSummaryAccordions.RideInformationSummary]: boolean;
    [RideSummaryAccordions.VehicleInformationSummary]: boolean;
    [RideSummaryAccordions.PassengerInformationSummary]: boolean;
}

export interface RideSummaryContext {
    expanded: RideSummaryAccordionsExpanded;
    setExpanded: Dispatch<SetStateAction<RideSummaryAccordionsExpanded>>;
}

export const initialExpandedAccordions = {
    RideInformationSummary: true,
    VehicleInformationSummary: true,
    PassengerInformationSummary: true,
} as RideSummaryAccordionsExpanded;

export const RideSummaryCardContext = createContext<RideSummaryContext>({
    expanded: initialExpandedAccordions,
    setExpanded: () => {},
});
