import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { logAmplitudeEvent } from '../../../../../amplitude/amplitude';
import IconedData from '../../../../../components/iconedData/IconedData';
import { SINGLE_RIDE_DOWNLOAD_DOCUMENTATION } from '../../../../../constants/amplitude/supplierKeys';
import { COLORS } from '../../../../../constants/colors';
import theme from '../../../../../styles/theme';
import downloadRideOrder from '../../../../rides/api/downloadRideOrder';
import { RideContext } from '../../context/RideContext';
import PassengerInformationSummary from './components/PassengerInformationSummary';
import RideInformationSummary from './components/RideInformationSummary';
import VehicleInformationSummary from './components/VehicleInformationSummary';
import { initialExpandedAccordions, RideSummaryAccordions, RideSummaryCardContext } from './RideSummaryCardContext';

const useStyles = makeStyles({
    gridItemMobile: { padding: 0 },
    gridItem: {
        padding: '1rem 1rem 0 1rem',
        width: '100%',
    },
});

const RideSummaryCard = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { rideResponse, amplitudeEventProperties } = useContext(RideContext);
    const [expanded, setExpanded] = useState(initialExpandedAccordions);

    const { mutate: downloadRideOrderMutation } = useMutation(downloadRideOrder);

    const price = useMemo(() => {
        if (!rideResponse?.price?.isoCurrency && !rideResponse?.price?.amount) {
            return '';
        }

        return `${rideResponse.price.isoCurrency} ${rideResponse.price.amount.toFixed(2)}`;
    }, [rideResponse?.price?.amount, rideResponse?.price?.isoCurrency]);

    return (
        <RideSummaryCardContext.Provider
            value={{
                expanded,
                setExpanded,
            }}
        >
            <Grid
                container
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    border: `1px solid ${COLORS.BLACK_12}`,
                    borderRadius: '3px',
                }}
            >
                <Grid item className={isMobile ? classes.gridItemMobile : classes.gridItem}>
                    <RideInformationSummary
                        unid={RideSummaryAccordions.RideInformationSummary}
                        prettifiedUnid={rideResponse?.prettifiedUnid}
                        pickUp={rideResponse?.pickUp}
                        dropOff={rideResponse?.dropOff}
                        flightDetails={rideResponse?.flightDetails}
                        durationInSeconds={rideResponse?.durationInSeconds ?? 0}
                        specialRequest={rideResponse?.specialRequest}
                        vehicle={rideResponse?.vehicle.name}
                        numberOfPassengers={rideResponse?.numberOfPassengers}
                        numberOfBags={rideResponse?.numberOfBags}
                        passengers={rideResponse?.passengers}
                    />
                </Grid>
                <Grid item className={isMobile ? classes.gridItemMobile : classes.gridItem} position="relative">
                    <VehicleInformationSummary
                        unid={RideSummaryAccordions.VehicleInformationSummary}
                        vehicleClass={rideResponse?.vehicle.class}
                        vehicleType={rideResponse?.vehicle.type}
                        vehicleModel={rideResponse?.vehicle.brandAndModel}
                        vehicleImageUrl={rideResponse?.vehicle.imageUrl}
                        meetingInstructions={rideResponse?.pickupDescription}
                    />
                </Grid>
                <Grid item className={isMobile ? classes.gridItemMobile : classes.gridItem}>
                    <PassengerInformationSummary
                        unid={RideSummaryAccordions.PassengerInformationSummary}
                        passengers={rideResponse?.passengers}
                        numberOfPassengers={rideResponse?.numberOfPassengers.toString() ?? ''}
                        luggage={rideResponse?.numberOfBags.toString() ?? ''}
                        clientInstructions={rideResponse?.notesToSupplier}
                    />
                </Grid>
                <Grid
                    container
                    style={{
                        flexDirection: 'row',
                        padding: '1rem 1rem',
                        marginTop: isMobile ? 0 : '1rem',
                        borderTop: isMobile ? 'none' : `1px solid ${COLORS.BLACK_12}`,
                    }}
                >
                    <Grid
                        item
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="flexStart"
                        xs={12}
                    >
                        <Typography
                            style={{
                                color: COLORS.BLACK,
                                margin: 0,
                            }}
                        >
                            {t('price')}:
                        </Typography>
                        <Typography
                            style={{
                                color: COLORS.ORANGE,
                                fontSize: '1.5rem',
                                fontWeight: 700,
                            }}
                        >
                            {price}
                        </Typography>
                    </Grid>
                    <Grid item padding="1rem 0 0 0" display="flex" flexDirection="column" alignItems="flexStart">
                        <IconedData
                            icon={DownloadOutlinedIcon}
                            data={t('pages.rides.downloadRideOrder')}
                            iconColor={COLORS.BLUE_DARK}
                            dataColor={COLORS.BLACK}
                            dataTextBold
                            onClick={() => {
                                downloadRideOrderMutation(rideResponse?.unid ?? '');
                                logAmplitudeEvent(SINGLE_RIDE_DOWNLOAD_DOCUMENTATION, amplitudeEventProperties);
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </RideSummaryCardContext.Provider>
    );
};

export default RideSummaryCard;
