/* eslint-disable @typescript-eslint/indent */
/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable no-confusing-arrow */
/* eslint-disable multiline-ternary */
/* eslint-disable  newline-per-chained-call */
import { Spinner, TextField } from "@get-e/react-components";
import { alpha } from "@material-ui/core/styles";
import CheckIcon from "@mui/icons-material/Check";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import LuggageOutlinedIcon from "@mui/icons-material/LuggageOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { FormControlLabel, Grid, Switch, Box, Typography, InputAdornment, Tooltip, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DatePicker, TimePicker } from "@mui/x-date-pickers-pro";
import clsx from "clsx";
import moment from "moment";
import React, { SetStateAction, Dispatch, useState, KeyboardEvent, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";

import { logAmplitudeEvent } from "../../../../amplitude/amplitude";
import CopyToClipboardButton from "../../../../components/buttons/CopyToClipboardButton";
import IconedData from "../../../../components/iconedData/IconedData";
import {
  RIDE_INFORMATION_CAR_PICKUP_TIME,
  RIDE_INFORMATION_FROM,
  RIDE_INFORMATION_LUGGAGE,
  RIDE_INFORMATION_PASSENGERS,
  RIDE_INFORMATION_PICKUP_DATE,
  RIDE_INFORMATION_SPECIAL_REQUEST,
  RIDE_INFORMATION_TO,
} from "../../../../constants/amplitude/customerKeys";
import { COLORS } from "../../../../constants/colors";
import { DATE_FORMATS } from "../../../../constants/dateFormats";
import { datePickerPlaceholder } from "../../../../constants/datePickerPlaceholder";
import { ENTER } from "../../../../constants/keyCodes";
import { TIME_FORMATS } from "../../../../constants/timeFormats";
import { InputError } from "../../../../helpers/inputValidation/InputError";
import { getAirlineIataImage } from "../../../../services/routes";
import { PrimaryTimeZone } from "../../../../services/types";
import theme from "../../../../styles/theme";
import { FlightDetails } from "../../../rides/api/types";
import { SearchLocation } from "../../api/types";
import { NewRideContext } from "../../customer/context/RideContext";
import { SearchLocationComponent } from "../SearchLocationComponent";

const useStyles = makeStyles(styleTheme => ({
  centerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > div > span": { fontWeight: 700 },
  },
  timeSepartor: {
    "& .MuiTimePickerToolbar-hourMinuteLabel": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiTimePickerToolbar-separator": { marginTop: "-.5rem" },
  },
  noFlightTracking: {
    "& .MuiInputBase-root": {
      borderBottom: `2px solid ${COLORS.ORANGE_TEXT}`,
      "&:hover:before": { borderBottom: "none" },
      "&:before": { borderBottom: "none" },
      "&:after": { borderBottom: "none" },
      "&.Mui-focused": { borderBottom: `2px solid ${COLORS.ORANGE_TEXT}` },
    },
  },
  flightTracking: {
    "& .MuiInputBase-root": {
      borderBottom: `2px solid ${COLORS.GREEN}`,
      "&:hover:before": { borderBottom: "none" },
      "&:before": { borderBottom: "none" },
      "&:after": { borderBottom: "none" },
      "&.Mui-focused": { borderBottom: `2px solid ${COLORS.GREEN}` },
    },
  },
  utcSwitch: {
    whiteSpace: "nowrap",
    marginRight: ".3rem",
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: COLORS.ORANGE_WARNING,
      "&:hover": { backgroundColor: alpha(COLORS.ORANGE_WARNING, theme.palette.action.hoverOpacity) },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": { backgroundColor: COLORS.ORANGE_WARNING },
  },
  utcSwitchChecked: {
    color: COLORS.ORANGE_WARNING,
    fontWeight: 600,
  },
  utcBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const AIRLINE_IMAGE_WIDTH = 75;
const AIRLINE_IMAGE_HEIGHT = 25;

export interface RideError {
  pickUpDate: InputError | null;
  pickUpTime: InputError | null;
  travellers: InputError | null;
  luggages: InputError | null;
  pickUpLocation: InputError | null;
  dropOffLocation: InputError | null;
}

export const initialRideErrors = {
  pickUpDate: null,
  pickUpTime: null,
  travellers: null,
  luggages: null,
  pickUpLocation: null,
  dropOffLocation: null,
};

export interface Ride {
  id: string;
  pickUpLocation: SearchLocation | null;
  dropOffLocation: SearchLocation | null;
  pickUpDate: Date | null;
  pickUpTime: Date | null;
  pickUpDateLocal: Date | null;
  pickUpTimeLocal: Date | null;
  dropOffDate: Date | null;
  dropOffDateLocal: Date | null;
  travellers: string;
  luggages: string;
  flightNumber: string;
  specialRequest: string;
  customReference: string;
  flightDetails: FlightDetails;
  primaryTimeZone: PrimaryTimeZone | null;
}

export const initialRide = {
  id: uuid(),
  pickUpLocation: null,
  dropOffLocation: null,
  pickUpDate: null,
  pickUpDateLocal: null,
  pickUpTime: null,
  pickUpTimeLocal: null,
  dropOffDate: null,
  dropOffDateLocal: null,
  travellers: "",
  luggages: "",
  flightNumber: "",
  specialRequest: "",
  customReference: "",
  flightDetails: {} as FlightDetails,
  primaryTimeZone: null,
} as Ride;

interface ChangeRideInformationProps {
  rideValues: Ride;
  setRideValues: Dispatch<SetStateAction<Ride>>;
  errors: RideError;
  setErrors: Dispatch<SetStateAction<RideError>>;
  handleFlightNumberFinishing: () => void;
  isLoadingFlight?: boolean;
  isErrorFlight?: boolean;
  isUtc: boolean;
  initialRideValues?: Ride | null;
  setIsUtc: Dispatch<SetStateAction<boolean>>;
  flightDetails: FlightDetails;
  setFlightDetails: Dispatch<SetStateAction<FlightDetails>>;
}

// eslint-disable-next-line complexity
const ChangeRideInformation = ({
  rideValues,
  setRideValues,
  errors,
  setErrors,
  handleFlightNumberFinishing,
  isLoadingFlight,
  isErrorFlight,
  initialRideValues,
  isUtc,
  setIsUtc,
  flightDetails,
  setFlightDetails,
}: ChangeRideInformationProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [inputValueDropOff, setInputValueDropOff] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [openTime, setOpenTime] = useState(false);
  const [imageError, setImageError] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isFlightTracked = !isErrorFlight && Boolean(flightDetails.number);
  const { amplitudeEventProperties } = useContext(NewRideContext);

  const handleChangeValue = (field: keyof Ride, value: string | Date | SearchLocation | null, fieldKey?: string) => {
    let newValues = { ...rideValues };

    newValues = {
      ...rideValues,
      [field]: value,
    };

    const newErrors = { ...errors };

    newErrors.pickUpDate = null;
    newErrors.pickUpTime = null;
    newErrors[field] = null;

    if (field === "pickUpDateLocal") {
      newValues.pickUpTimeLocal = moment(
        moment(value as Date).format(DATE_FORMATS["YYYY-MM-DDT"]) +
          moment(newValues.pickUpTimeLocal).format(TIME_FORMATS["HH:mm:ss"])
      ).toDate();
    }

    if (field === "pickUpDate") {
      newValues.pickUpTime = moment(
        moment(value as Date).format(DATE_FORMATS["YYYY-MM-DDT"]) + moment(newValues.pickUpTime).format(TIME_FORMATS["HH:mm:ss"])
      ).toDate();
    }

    setRideValues(newValues);
    setErrors(newErrors);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER) {
      handleFlightNumberFinishing();
    }
  };

  const flightDepartureDateTime = useMemo(() => {
    const dateTime = isUtc ? flightDetails.departure?.datetimeZulu : flightDetails.departure?.datetimeLocal;

    return dateTime && moment(dateTime).utc().format(DATE_FORMATS["DD MMM at HH:mm A"]);
  }, [isUtc, flightDetails.departure?.datetimeLocal, flightDetails.departure?.datetimeZulu]);

  const flightArrivalDateTime = useMemo(() => {
    const dateTime = isUtc ? flightDetails.arrival?.datetimeZulu : flightDetails.arrival?.datetimeLocal;

    return dateTime && moment(dateTime).utc().format(DATE_FORMATS["DD MMM at HH:mm A"]);
  }, [isUtc, flightDetails.arrival?.datetimeLocal, flightDetails.arrival?.datetimeZulu]);

  const airlineAbrevation = useMemo(() => rideValues.flightNumber.replace(/[^a-zA-Z]/g, ""), [rideValues.flightNumber]);

  const handleImageError = () => {
    setImageError(true);
  };

  const flightIcon = useMemo(() => {
    if (isFlightTracked) {
      return airlineAbrevation && flightDepartureDateTime && !imageError ? (
        <img
          src={getAirlineIataImage(airlineAbrevation, AIRLINE_IMAGE_WIDTH, AIRLINE_IMAGE_HEIGHT)}
          onError={handleImageError}
          alt="Airline"
        />
      ) : (
        <CheckIcon style={{ color: COLORS.GREEN }} />
      );
    }

    return rideValues.flightNumber ? <DangerousOutlinedIcon style={{ color: COLORS.ORANGE_TEXT }} /> : <></>;
  }, [airlineAbrevation, flightDepartureDateTime, imageError, isFlightTracked, rideValues.flightNumber]);

  return (
    <>
      <Box
        sx={{
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            fontWeight: 700,
            color: COLORS.BLUE_DARK,
          }}
        >
          {t("rideInformation")}
        </Typography>
        <Box className={classes.utcBlock}>
          <FormControlLabel
            className={classes.utcSwitch}
            control={<Switch checked={isUtc} onChange={event => setIsUtc?.(event.target.checked)} name="primaryTimeZone" />}
            label="Local"
            labelPlacement={"start"}
          />
          <Typography className={clsx({ [classes.utcSwitchChecked]: isUtc })}>UTC</Typography>
        </Box>
      </Box>
      <Grid item rowGap="1.5rem" display="flex" flexDirection="column">
        <Grid display="flex" flexDirection={"column"}>
          <Grid item xs={12} marginBottom="1.7rem">
            <SearchLocationComponent
              error={errors.pickUpLocation}
              helperText={(errors.pickUpLocation && t(errors.pickUpLocation)) ?? ""}
              inputValue={inputValue}
              setInputValue={setInputValue}
              address={rideValues.pickUpLocation}
              setAddress={(newValue: SearchLocation | null) => {
                handleChangeValue("pickUpLocation", newValue);
              }}
              label={t("pickUpLocation")}
              logEvent={() => logAmplitudeEvent(RIDE_INFORMATION_FROM, amplitudeEventProperties)}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchLocationComponent
              error={errors.dropOffLocation}
              helperText={(errors.dropOffLocation && t(errors.dropOffLocation)) ?? ""}
              inputValue={inputValueDropOff}
              setInputValue={setInputValueDropOff}
              address={rideValues.dropOffLocation}
              setAddress={(newValue: SearchLocation | null) => {
                handleChangeValue("dropOffLocation", newValue);
              }}
              label={t("dropOffLocation")}
              logEvent={() => logAmplitudeEvent(RIDE_INFORMATION_TO, amplitudeEventProperties)}
            />
          </Grid>
        </Grid>
        <Grid gap={isMobile ? 2 : 4} display="flex" flexDirection={isMobile ? "column" : "row"}>
          <Tooltip
            title={
              isUtc
                ? moment(rideValues.pickUpDate).format(DATE_FORMATS["DD MMM yyyy"])
                : moment(rideValues.pickUpDateLocal).format(DATE_FORMATS["DD MMM yyyy"])
            }
            placement="top"
            arrow
          >
            <Grid item xs={12} md={6} lg={6}>
              <DatePicker
                sx={{ width: "100%" }}
                label={t(isUtc ? "pages.singleRide.fields.pickUpDateUtc" : "pages.singleRide.fields.pickUpDate")}
                value={isUtc ? rideValues.pickUpDate : rideValues.pickUpDateLocal}
                onChange={newValue => handleChangeValue(isUtc ? "pickUpDate" : "pickUpDateLocal", newValue)}
                format={DATE_FORMATS["dd MMM yyyy"]}
                onClose={() => {
                  logAmplitudeEvent(RIDE_INFORMATION_PICKUP_DATE, amplitudeEventProperties);
                  setOpenDate(false);
                }}
                slotProps={{
                  textField: {
                    error: errors.pickUpDate !== null,
                    helperText: errors.pickUpDate ? t(errors.pickUpDate) : "",
                    variant: "filled",
                    required: true,
                    inputProps: { readOnly: true },
                    onClick: () => setOpenDate(true),
                  },
                }}
                localeText={datePickerPlaceholder}
                open={openDate}
              />
            </Grid>
          </Tooltip>
          <Tooltip
            title={
              isUtc
                ? moment(rideValues.pickUpTime).format(TIME_FORMATS["HH:mm A"])
                : moment(rideValues.pickUpTimeLocal).format(TIME_FORMATS["HH:mm A"])
            }
            placement="top"
            arrow
          >
            <Grid item xs={12} md={6} lg={6}>
              <TimePicker
                sx={{ width: "100%" }}
                label={t(isUtc ? "pages.singleRide.fields.pickUpTimeUtc" : "pages.singleRide.fields.pickUpTime")}
                value={isUtc ? rideValues.pickUpTime : rideValues.pickUpTimeLocal}
                onChange={newValue => handleChangeValue(isUtc ? "pickUpTime" : "pickUpTimeLocal", newValue)}
                format={TIME_FORMATS["HH:mm a"]}
                onClose={() => {
                  logAmplitudeEvent(RIDE_INFORMATION_CAR_PICKUP_TIME, amplitudeEventProperties);
                  setOpenTime(false);
                }}
                slotProps={{
                  textField: {
                    error: errors.pickUpTime !== null,
                    helperText: errors.pickUpTime ? t(errors.pickUpTime) : "",
                    variant: "filled",
                    required: true,
                    inputProps: { readOnly: true },
                    onClick: () => setOpenTime(true),
                  },
                  mobilePaper: { className: classes.timeSepartor },
                }}
                ampm={false}
                open={openTime}
              />
            </Grid>
          </Tooltip>
        </Grid>
        <Grid gap={4} display="flex" flexDirection={isMobile ? "column" : "row"}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label={t("passengers")}
              required
              type="number"
              value={rideValues.travellers}
              onChange={event => handleChangeValue("travellers", event.target.value)}
              error={errors.travellers !== null}
              helperText={errors.travellers && t(errors.travellers)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlineOutlinedIcon />
                  </InputAdornment>
                ),
                inputProps: { min: 1 },
              }}
              onBlur={() =>
                initialRideValues?.travellers !== rideValues.travellers
                  ? logAmplitudeEvent(RIDE_INFORMATION_PASSENGERS, amplitudeEventProperties)
                  : {}
              }
              noHelperTextSpace
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label={t("luggages")}
              required
              type="number"
              value={rideValues.luggages}
              onChange={event => handleChangeValue("luggages", event.target.value)}
              error={errors.luggages !== null}
              helperText={errors.luggages && t(errors.luggages)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LuggageOutlinedIcon />
                  </InputAdornment>
                ),
                inputProps: { min: 0 },
              }}
              onBlur={() =>
                initialRideValues?.luggages !== rideValues.luggages
                  ? logAmplitudeEvent(RIDE_INFORMATION_LUGGAGE, amplitudeEventProperties)
                  : {}
              }
              noHelperTextSpace
            />
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            value={rideValues.specialRequest}
            label={t("specialRequest")}
            onChange={event => handleChangeValue("specialRequest", event.target.value)}
            multiline
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipboardButton text={rideValues.specialRequest} />
                </InputAdornment>
              ),
            }}
            onBlur={() =>
              initialRideValues?.specialRequest !== rideValues.specialRequest
                ? logAmplitudeEvent(RIDE_INFORMATION_SPECIAL_REQUEST, amplitudeEventProperties)
                : {}
            }
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      >
        <Typography
          style={{
            fontWeight: 700,
            color: COLORS.BLUE_DARK,
          }}
        >
          Flight information
        </Typography>
      </Box>
      <Grid gap={isMobile ? 2 : 4} display="flex" flexDirection={isMobile ? "column" : "row"}>
        <Grid item xs={12}>
          <TextField
            label={t("pages.singleRide.fields.flightNumber")}
            value={rideValues.flightNumber}
            onChange={event => handleChangeValue("flightNumber", event.target.value)}
            onBlur={handleFlightNumberFinishing}
            onKeyDown={handleKeyDown}
            className={clsx({
              [classes.flightTracking]: isFlightTracked,
              [classes.noFlightTracking]: !isFlightTracked && rideValues.flightNumber !== "",
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={isFlightTracked ? flightDetails.airlineName : t("pages.singleRide.fields.flightNotTracked")}
                    arrow
                    placement="top"
                  >
                    {isLoadingFlight ? <Spinner size={24} /> : flightIcon}
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {isFlightTracked && flightDepartureDateTime && (
            <Box className={classes.centerContainer}>
              <IconedData
                icon={FlightTakeoffIcon}
                data={flightDetails.departure?.iata}
                dataColor={COLORS.BLACK}
                tooltip={flightDetails.departure?.name}
                dataTextBold
              />
              <Typography
                style={{
                  fontSize: ".75rem",
                  fontWeight: 700,
                  color: COLORS.BLUE_DARK,
                }}
                marginBottom=".25rem"
                marginLeft="1rem"
              >
                {flightDepartureDateTime} {isUtc ? "(UTC)" : ""}
              </Typography>
            </Box>
          )}
          {isFlightTracked && flightArrivalDateTime && (
            <Box className={classes.centerContainer}>
              <IconedData
                icon={FlightLandIcon}
                data={flightDetails.arrival?.iata}
                dataColor={COLORS.BLACK}
                tooltip={flightDetails.arrival?.name}
                dataTextBold
              />
              <Typography
                style={{
                  fontSize: ".75rem",
                  fontWeight: 700,
                  color: COLORS.BLUE_DARK,
                }}
                marginBottom=".25rem"
                marginLeft="1rem"
              >
                {flightArrivalDateTime} {isUtc ? "(UTC)" : ""}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ChangeRideInformation;
