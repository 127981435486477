import { Form, PrimaryButton, SignedOutLayout, TextField } from '@get-e/react-components';
import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';

import ShowPasswordButton from '../../components/buttons/showPasswordButton/ShowPasswordButton';
import { LOG_IN } from '../../constants/urlPaths';
import { Severity, useNotificationContext } from '../../context/NotificationContext';
import { InputError } from '../../helpers/inputValidation/InputError';
import allValid from '../../helpers/inputValidation/validators/allValid';
import isEqualString from '../../helpers/inputValidation/validators/isEqualString';
import isFilledString from '../../helpers/inputValidation/validators/isFilledString';
import isPasswordStrong from '../../helpers/inputValidation/validators/isPasswordStrong';
import useUrlQuery from '../../hooks/useUrlQuery';
import { logoIconLightBackground } from '../../public/assets/images';
import { acceptUserInvite } from './api';

const TOKEN_QUERY_PARAMETER = 'token';
const FIRST_NAME_QUERY_PARAMETER = 'firstName';
const LAST_NAME_QUERY_PARAMETER = 'lastName';

const useStyles = makeStyles({
    container: {
        padding: '2em',
        maxWidth: '430px',
        margin: '0 auto',
    },
    heading: {
        marginBottom: '1rem',
        marginTop: '2rem',
    },
    formField: { marginBottom: '2rem' },
    logoContainer: {
        display: 'block',
        margin: '0 auto',
        maxWidth: 110,
    },
});

const AcceptInvite = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const urlParams = useUrlQuery();
    const history = useHistory();
    const token = urlParams.get(TOKEN_QUERY_PARAMETER) ?? '';
    const firstNameParam = urlParams.get(FIRST_NAME_QUERY_PARAMETER) ?? '';
    const lastNameParam = urlParams.get(LAST_NAME_QUERY_PARAMETER) ?? '';
    const [firstName, setFirstName] = useState(firstNameParam);
    const [lastName, setLastName] = useState(lastNameParam);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstNameError, setFirstNameError] = useState<InputError | null>(null);
    const [lastNameError, setLastNameError] = useState<InputError | null>(null);
    const [passwordError, setPasswordError] = useState<InputError | null>(null);
    const [confirmPasswordError, setConfirmPasswordError] = useState<InputError | null>(null);
    const [showingPassword, setShowingPassword] = useState(false);
    const [showingConfirmPassword, setShowingConfirmPassword] = useState(false);
    const { showNotification } = useNotificationContext();

    const { mutate: acceptUserInviteMutation, isLoading } = useMutation(
        acceptUserInvite,
        {
            onSuccess: () => {
                history.push(LOG_IN);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
                showNotification(error?.response?.data?.message || t('errors.defaultError'), Severity.Error);
            },
        },
    );

    const validateFormFields = (): boolean => {
        setFirstNameError(null);
        setLastNameError(null);
        setPasswordError(null);
        setConfirmPasswordError(null);

        const validated = {
            firstName: isFilledString(firstName, InputError.Empty),
            lastName: isFilledString(lastName, InputError.Empty),
            password: isPasswordStrong(password, InputError.NotStrongPassword),
            confirmPassword: isEqualString(confirmPassword, password, InputError.NoMatch),
        };

        if (!allValid(validated)) {
            setFirstNameError(validated.firstName.isValid ? null : validated.firstName.error);
            setLastNameError(validated.lastName.isValid ? null : validated.lastName.error);
            setPasswordError(validated.password.isValid ? null : validated.password.error);
            setConfirmPasswordError(validated.confirmPassword.isValid ? null : validated.confirmPassword.error);

            return false;
        }

        return true;
    };

    const submitForm = () => {
        if (validateFormFields()) {
            acceptUserInviteMutation({
                firstName,
                lastName,
                password,
                password_confirmation: confirmPassword,
                token,
            });
        }
    };

    return (
        <SignedOutLayout linkPrefix={t('visit').toLowerCase()}>
            <Paper elevation={0} className={classes.container}>
                <img src={logoIconLightBackground} alt={'Logo'} className={classes.logoContainer} />
                <Form onSubmit={submitForm}>
                    <Typography variant="h2" component="h2" className={classes.heading}>
                        {t('pages.acceptInvite.welcome')}!
                    </Typography>
                    <Typography variant="body1" mb={'2em'}>
                        {t('pages.acceptInvite.completeBelowDetails')}
                    </Typography>
                    <TextField
                        id="first_name_field"
                        label={t('form.fields.firstName')}
                        className={classes.formField}
                        value={firstName}
                        onChange={event => {
                            setFirstName(event.target.value);
                            setFirstNameError(null);
                        }}
                        inputProps={{ form: { autocomplete: 'off' } }}
                        type="text"
                        name="firstName"
                        required
                        error={firstNameError !== null}
                        helperText={firstNameError ? t(firstNameError) : undefined}
                    />
                    <TextField
                        id="last_name_field"
                        label={t('form.fields.lastName')}
                        className={classes.formField}
                        value={lastName}
                        onChange={event => {
                            setLastName(event.target.value);
                            setLastNameError(null);
                        }}
                        inputProps={{ form: { autocomplete: 'off' } }}
                        type="text"
                        name="lastName"
                        required
                        error={lastNameError !== null}
                        helperText={lastNameError ? t(lastNameError) : undefined}
                    />
                    <TextField
                        id="first_name_field"
                        label={t('password')}
                        className={classes.formField}
                        type={showingPassword ? 'text' : 'password'}
                        value={password}
                        onChange={event => {
                            setPassword(event.target.value);
                            setPasswordError(null);
                        }}
                        InputProps={{
                            endAdornment: (
                                <ShowPasswordButton
                                    onShowPassword={isPasswordVisible => {
                                        setShowingPassword(isPasswordVisible);
                                    }}
                                />
                            ),
                        }}
                        required
                        error={passwordError !== null}
                        helperText={passwordError ? t(passwordError) : undefined}
                    />
                    <TextField
                        label={t('form.fields.confirmPassword')}
                        className={classes.formField}
                        type={showingConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={event => {
                            setConfirmPassword(event.target.value);
                            setConfirmPasswordError(null);
                        }}
                        InputProps={{
                            endAdornment: (
                                <ShowPasswordButton
                                    onShowPassword={isPasswordVisible => {
                                        setShowingConfirmPassword(isPasswordVisible);
                                    }}
                                />
                            ),
                        }}
                        required
                        error={confirmPasswordError !== null}
                        helperText={confirmPasswordError ? t(confirmPasswordError) : undefined}
                    />
                    <input name="token" type="hidden" value={token} />
                    <Typography variant="body1" mb={'2em'} fontSize="0.75rem">
                        {t('useEightOrMoreCharacters')}
                    </Typography>
                    <Box>
                        <PrimaryButton onClick={submitForm} submitsForm loading={isLoading}>
                            {t('pages.acceptInvite.activatePrifile')}
                        </PrimaryButton>
                    </Box>
                </Form>
            </Paper>
        </SignedOutLayout>
    );
};

export default AcceptInvite;
