import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { COLORS } from '../../constants/colors';
import { useCurrentProfileContext } from '../../context/CurrentProfileContext';
import { isMatchPath } from '../../helpers/isMatchPath';

const useStyles = makeStyles({
    selectedItem: { background: `${COLORS.WHITE_20} !important` },
    item: {
        padding: '.5rem',
        '&:hover': { background: `${COLORS.WHITE_10} !important` },
        '& > div': { paddingLeft: '.1rem' },
    },
    separatedItem: { marginTop: '.5rem' },
});

interface MenuListItemProps {
    icon: JSX.Element;
    label: string;
    href: string;
    onClick?: () => void;
    disableMathcingPath?: boolean;
    isSeparateItem?: boolean;
}

export const MenuListItem = (
    { icon, label, href, onClick, disableMathcingPath = false, isSeparateItem }: MenuListItemProps
) => {
    const classes = useStyles();
    const { isMenuClosed } = useCurrentProfileContext();

    return (
        <Tooltip
            title={label}
            arrow
            placement="right"
            disableHoverListener={Boolean(!isMenuClosed)}
        >
            <ListItemButton
                selected={isMatchPath(href)}
                component={NavLink}
                to={href}
                onClick={onClick}
                className={clsx({
                    [classes.item]: true,
                    [classes.selectedItem]: isMatchPath(href) && !disableMathcingPath,
                    [classes.separatedItem]: isSeparateItem,
                })}
            >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{label}</ListItemText>
            </ListItemButton>
        </Tooltip>
    );
};
