import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../components/modal/Modal';
import { RemarksCard, RemarksValues, initialRemarks } from '../../../rides/supplier/components/RemarksCard';
import useConfirmCloseAddRemarksRide from '../hooks/useConfirmCloseAddRemarksRide';
import ReferenceCard from './ReferenceCard';

export interface ReferencesAndRemarksData {
    referenceNumber: string;
    minutesWaiting: string;
    minutesCosts: string;
    parkingCosts: string;
    otherCosts: string;
    remarks: string;
}

interface RemarksModalProps {
    rideId: string;
    isModalOpen: boolean;
    onClose: () => void;
    onFinished: () => void;
    reference?: string;
}

const RemarksModal = ({
    rideId,
    isModalOpen,
    onClose,
    onFinished,
    reference,
}: RemarksModalProps) => {
    const { t } = useTranslation();
    const [remarksValues, setRemarksValues] = useState<RemarksValues>(initialRemarks);
    const [supplierReference, setSupplierReference] = useState<string | null>(reference ?? null);

    const { onAddRideRemarks, isSaving } = useConfirmCloseAddRemarksRide({
        rideId,
        remarksValues,
        reference: supplierReference,
    });

    const handleAddRemarks = async () => {
        await onAddRideRemarks();
        onFinished();
        onClose();
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            onSubmit={handleAddRemarks}
            title={t(reference ? 'buttonName.referenceAndRemarks' : 'modals.referencesAndRemarks.additionalCosts')}
            confirmButtonLabel={t('buttonName.update')}
            cancelButtonLabel={t('buttonName.back')}
            isDisabled={isSaving}
            maxWidth="md"
            fullWidth
        >
            <Grid container flexDirection="column">
                <Grid item>
                    <ReferenceCard
                        isVisible
                        reference={supplierReference}
                        setReference={setSupplierReference}
                    />
                    <RemarksCard
                        remarksValues={remarksValues}
                        setRemarksValues={setRemarksValues}
                        isAdditionalButtonHidden
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default RemarksModal;
