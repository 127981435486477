import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { GridRowModel } from '@mui/x-data-grid-pro';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { IconedLabel } from '../../../../../components/iconedLabel/IconedLabel';
import { Passenger } from '../../../api/types';
import CellText from '../../../components/CellText';

interface RideInformationCellProps {
    row: GridRowModel;
}

export const RideInformationCell = ({ row }: RideInformationCellProps) => {
    const { t } = useTranslation();
    const leadPassenger = row.passengers.filter((passenger: Passenger) => passenger.isLead)[0];

    return (
        <>
            {leadPassenger && <CellText marginBottom=".25rem">
                <IconedLabel
                    icon={HailOutlinedIcon}
                    label={t('name')}
                    dataLabel={`${leadPassenger.firstName} ${leadPassenger.lastName}`}
                />
            </CellText>}
            <CellText marginBottom=".25rem">
                <IconedLabel
                    icon={PersonOutlineIcon}
                    label={t('passengers')}
                    dataLabel={row.passengersCount}
                />
            </CellText>
            <CellText marginBottom=".25rem">
                <IconedLabel
                    icon={LuggageOutlinedIcon}
                    label={t('luggages')}
                    dataLabel={row.luggages}
                />
            </CellText>
        </>
    );
};
