import AirlineSeatReclineNormalOutlinedIcon from '@mui/icons-material/AirlineSeatReclineNormalOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconedData from '../../../components/iconedData/IconedData';
import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles({
    heading: {
        marginBottom: '1rem',
        color: COLORS.BLUE_DARK,
        fontSize: '1rem',
        fontWeight: 700,
    },
    vehicleImage: {
        position: 'absolute',
        bottom: '16px',
        right: 0,
        height: '70px',
        width: '180px',
    },
});

interface RideVehicleInformationCardProps {
    vehicleClass: string;
    vehicleType: string;
    vehicleImage?: string;
    travellers: number;
    luggages: number;
}

const RideVehicleInformationCard = ({
    vehicleClass,
    vehicleType,
    vehicleImage,
    travellers,
    luggages,
}: RideVehicleInformationCardProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid
            container
            style={{
                width: '100%',
                flexDirection: 'column',
                border: `1px solid ${COLORS.BLACK_12}`,
                borderRadius: '3px',
                marginTop: '2rem',
                padding: '1rem',
                position: 'relative',
            }}
        >
            <Grid item>
                <Typography variant="h3" component="h4" className={classes.heading}>
                    {t('pages.singleRide.currentVehicle')}
                    <Typography
                        sx={{
                            color: COLORS.SLATE_GREY,
                            paddingBottom: '.6rem',
                        }}
                        component="span"
                    >
                        {` ${vehicleClass} ${vehicleType}`}
                    </Typography>
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <IconedData
                        icon={AirlineSeatReclineNormalOutlinedIcon}
                        data={travellers.toString()}
                        dataLabel={t('seats')}
                        dataColor={COLORS.BLUE_DARK}
                        dataLabelColor={COLORS.SLATE_GREY}
                        tooltip={t('maxSeats')}
                    />
                    <IconedData
                        icon={WorkOutlineOutlinedIcon}
                        data={luggages.toString()}
                        dataLabel={t('capacity')}
                        dataColor={COLORS.BLUE_DARK}
                        dataLabelColor={COLORS.SLATE_GREY}
                        tooltip={t('maxLuggage')}
                    />
                </Box>
            </Grid>
            <img src={vehicleImage} alt="Vehicle" className={classes.vehicleImage} />
        </Grid>
    );
};

export default RideVehicleInformationCard;
