import { COLORS } from '../../../../constants/colors';

export enum FlightStatus {
    NOT_TRACKED = 'NOT_TRACKED',
    SCHEDULED = 'SCHEDULED',
    DEPARTING_ON_TIME = 'DEPARTING_ON_TIME',
    DEPARTING_EARLY = 'DEPARTING_EARLY',
    DEPARTING_LATE = 'DEPARTING_LATE',
    CANCELLED = 'CANCELLED',
    ON_TIME = 'ON_TIME',
    EXPECTED_EARLY = 'EXPECTED_EARLY',
    EXPECTED_LATE = 'EXPECTED_LATE',
    DIVERTED = 'DIVERTED',
    LOST_TRACKING = 'LOST_TRACKING',
    ARRIVED = 'ARRIVED',
    ARRIVED_LATE = 'ARRIVED_LATE',
    ARRIVED_EARLY = 'ARRIVED_EARLY'
}

export const FlightStatusColor = {
    NOT_TRACKED: COLORS.SLATE_GREY,
    SCHEDULED: COLORS.SLATE_GREY,
    DEPARTING_ON_TIME: COLORS.GREEN_TEXT,
    DEPARTING_EARLY: COLORS.ORANGE_WARNING,
    DEPARTING_LATE: COLORS.ORANGE_WARNING,
    CANCELLED: COLORS.RED_WARNING,
    ON_TIME: COLORS.GREEN_TEXT,
    EXPECTED_EARLY: COLORS.ORANGE_WARNING,
    EXPECTED_LATE: COLORS.ORANGE_WARNING,
    DIVERTED: COLORS.RED_WARNING,
    LOST_TRACKING: COLORS.ORANGE_WARNING,
    ARRIVED: COLORS.GREEN_TEXT,
    ARRIVED_LATE: COLORS.ORANGE_WARNING,
    ARRIVED_EARLY: COLORS.ORANGE_WARNING,
} as {
    [key in keyof typeof FlightStatus]: keyof typeof COLORS;
};

export const FlightStatusBorderColor = {
    NOT_TRACKED: COLORS.SLATE_GREY,
    SCHEDULED: COLORS.SLATE_GREY,
    DEPARTING_ON_TIME: COLORS.GREEN_TEXT,
    DEPARTING_EARLY: COLORS.ORANGE_WARNING,
    DEPARTING_LATE: COLORS.ORANGE_WARNING,
    CANCELLED: COLORS.LIGHT_RED,
    ON_TIME: COLORS.GREEN_TEXT,
    EXPECTED_EARLY: COLORS.ORANGE_WARNING,
    EXPECTED_LATE: COLORS.ORANGE_WARNING,
    DIVERTED: COLORS.LIGHT_RED,
    LOST_TRACKING: COLORS.ORANGE_WARNING,
    ARRIVED: COLORS.GREEN_TEXT,
    ARRIVED_LATE: COLORS.ORANGE_WARNING,
    ARRIVED_EARLY: COLORS.ORANGE_WARNING,
} as {
    [key in keyof typeof FlightStatus]: keyof typeof COLORS;
};

export const FlightStatusBackgroundColor = {
    NOT_TRACKED: COLORS.WHITE,
    SCHEDULED: COLORS.WHITE,
    DEPARTING_ON_TIME: COLORS.WHITE,
    DEPARTING_EARLY: COLORS.WHITE,
    DEPARTING_LATE: COLORS.WHITE,
    CANCELLED: COLORS.LIGHT_RED,
    ON_TIME: COLORS.WHITE,
    EXPECTED_EARLY: COLORS.WHITE,
    EXPECTED_LATE: COLORS.WHITE,
    DIVERTED: COLORS.LIGHT_RED,
    LOST_TRACKING: COLORS.WHITE,
    ARRIVED: COLORS.WHITE,
    ARRIVED_LATE: COLORS.WHITE,
    ARRIVED_EARLY: COLORS.WHITE,
} as {
    [key in keyof typeof FlightStatus]: keyof typeof COLORS;
};

export const FlightStatusTooltip = {
    NOT_TRACKED: 'Flight status is not tracked',
    SCHEDULED: 'Flight is scheduled',
    DEPARTING_ON_TIME: 'Departing on time',
    DEPARTING_EARLY: 'Departing earlier than scheduled',
    DEPARTING_LATE: 'The flight is delayed on departure',
    CANCELLED: 'The flight has been cancelled',
    ON_TIME: 'Flight is on time',
    EXPECTED_EARLY: 'Expected to arrive early',
    EXPECTED_LATE: 'Expected to arrive late',
    DIVERTED: 'Flight has been diverted',
    LOST_TRACKING: 'Flight tracking has been lost',
    ARRIVED: 'Flight has arrived',
    ARRIVED_LATE: 'Flight has arrived late',
    ARRIVED_EARLY: 'Flight has arrived early',
} as {
    [key in keyof typeof FlightStatus]: string;
};

export const getFlightStatusColor = (status: keyof typeof FlightStatus) => FlightStatusColor[status];

export const getFlightStatusBorderColor = (status: keyof typeof FlightStatus) => FlightStatusBorderColor[status];

export const getFlightStatusBackgroundColor = (status: keyof typeof FlightStatus) => FlightStatusBackgroundColor[status];

export const getFlightStatusKeyName = (value: string): keyof typeof FlightStatus => {
    return Object.entries(FlightStatus).find(([_, val]) => val === value)?.[0] as keyof typeof FlightStatus;
};

export const getFlightStatusTooltip = (status: keyof typeof FlightStatus) => FlightStatusTooltip[status];
