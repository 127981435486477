import List from '@material-ui/core/List';
import FolderIcon from '@material-ui/icons/Folder';
import ListIcon from '@material-ui/icons/List';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import StarIcon from '@mui/icons-material/Star';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AVAILABILITY, DOCUMENTS, DRIVERS, INVOICES, REPORTS, RIDES, USERS, VEHICLES } from '../../../constants/urlPaths';
import { useCurrentProfileContext } from '../../../context/CurrentProfileContext';
import { MenuListItem } from '../../menuListItem/MenuListItem';
import navigationStyles from '../NavigationMenu.styles';

const SupplierList = ({ showDocuments }) => {
    const classes = navigationStyles();
    const { t } = useTranslation();
    const { useDriversPage, useVehiclePage } = useFlags();
    const { currentProfile } = useCurrentProfileContext();

    return (
        <>
            <List
                role="menu"
                className={classes.listContainer}
                data-testid="supplier-navigation"
            >
                <div className={classes.separateListContainer}>
                    <MenuListItem
                        icon={<ListIcon fontSize="small" />}
                        label={t('rides')}
                        href={RIDES}
                    />
                    {useVehiclePage && (
                        <MenuListItem
                            icon={<DirectionsCarFilledIcon fontSize="small" />}
                            label={t('navigation.supplier.vehicles')}
                            href={VEHICLES}
                        />
                    )}
                    {useDriversPage && (
                        <MenuListItem
                            icon={<ContactMailIcon fontSize="small" />}
                            label={t('navigation.supplier.drivers')}
                            href={DRIVERS}
                        />
                    )}
                    <MenuListItem
                        icon={<EventBusyIcon fontSize="small" />}
                        label={t('navigation.supplier.availability')}
                        href={AVAILABILITY}
                    />
                    <MenuListItem
                        icon={<StarIcon fontSize="small" />}
                        label={t('navigation.supplier.reports')}
                        href={REPORTS}
                    />
                    {currentProfile.permissions.manageUsers
                        && (
                            <MenuListItem
                                icon={<PersonOutlineIcon fontSize="small" />}
                                label={t('users')}
                                href={USERS}
                                data-testid="users-tab"
                            />
                        )
                    }
                    {showDocuments && (
                        <MenuListItem
                            icon={<FolderIcon fontSize="small" />}
                            label={t('documents')}
                            href={DOCUMENTS}
                        />
                    )}
                    {currentProfile.permissions.seeInvoices && (
                        <MenuListItem
                            icon={<ReceiptLongIcon fontSize="small" />}
                            label={t('navigation.supplier.invoices')}
                            href={INVOICES}
                        />
                    )}
                </div>
            </List>
        </>
    );
};

export default SupplierList;
