/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/indent */
import { Select, SelectOption } from "@get-e/react-components";
import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { COLORS } from "../../constants/colors";
import { LANGUAGES } from "../../constants/languages";
import { PREFERED_USER_LANGUAGE } from "../../constants/localStorageKeys";
import { PortalLocale, useLocaleContext } from "../../context/LocaleContext";
import settingsStyles from "./Settings.styles";

export const Settings = () => {
  const classes = settingsStyles();
  const [language, setLanguage] = useState(localStorage.getItem(PREFERED_USER_LANGUAGE) ?? "en-GB");
  const { updateLocale } = useLocaleContext();
  const { t } = useTranslation();

  const handleLanguage = (newValue: PortalLocale) => {
    localStorage.setItem(PREFERED_USER_LANGUAGE, newValue);

    setLanguage(newValue);

    updateLocale(newValue);
  };

  return (
    <Grid container className={classes.mainWrapper}>
      <Grid item xs={12} className={classes.headerTitleWrapper}>
        <Grid item className={classes.headerTitle}>
          <Typography
            style={{
              color: COLORS.BLUE,
              fontSize: "1.5rem",
              fontWeight: 700,
              paddingRight: "1rem",
            }}
          >
            {t("settings")}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} marginTop="1rem">
        <Typography marginBottom="1rem">{t("chooseLanguage")}</Typography>
        <Select label={t("language")} value={language} onChangeValue={newValue => handleLanguage(newValue as PortalLocale)}>
          <SelectOption value={LANGUAGES.ENGLISH.value}>{LANGUAGES.ENGLISH.label}</SelectOption>
          <SelectOption value={LANGUAGES.SPANISH.value}>{LANGUAGES.SPANISH.label}</SelectOption>
        </Select>
      </Grid>
    </Grid>
  );
};
