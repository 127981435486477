import {
    SummaryResponse,
    SituationsResponse,
    DriversResponse,
    ReviewsResponse,
    QualityReport,
    SituationType,
    QualityValuationEnum,
    Driver,
} from '../components/api/types';

const DISTANCE_THRESHOLD = 4000;
const TIME_DIFFERENCE_THRESHOLD_SEC = 60;

export const situationTypesRecord: Record<string, SituationType> = {
    [SituationType.DriverLate]: SituationType.DriverLate,
    [SituationType.DriverNoShow]: SituationType.DriverNoShow,
    [SituationType.WrongVehicle]: SituationType.WrongVehicle,
    [SituationType.SafetyIncident]: SituationType.SafetyIncident,
    [SituationType.VehicleCondition]: SituationType.VehicleCondition,
    [SituationType.ServiceQuality]: SituationType.ServiceQuality,
    [SituationType.OnRouteDelay]: SituationType.OnRouteDelay,
    [SituationType.Other]: SituationType.Other,
};

const checkGPSTracking = (arrival?: number, departure?: number): QualityValuationEnum => {
    if (!arrival || !departure) {
        return QualityValuationEnum.NO_UPDATES;
    }

    if (arrival >= DISTANCE_THRESHOLD || departure >= DISTANCE_THRESHOLD) {
        return QualityValuationEnum.NOT_ACCURATE;
    }

    return QualityValuationEnum.ACCURATE;
};

const driverUpdateCheck = (timeDifferenceInSeconds?: number): QualityValuationEnum => {
    if (!timeDifferenceInSeconds) {
        return QualityValuationEnum.NO_UPDATES;
    }

    if (timeDifferenceInSeconds < TIME_DIFFERENCE_THRESHOLD_SEC) {
        return QualityValuationEnum.NOT_ACCURATE;
    }

    return QualityValuationEnum.ACCURATE;
};

export const mapQualityReportData = (
    summary: SummaryResponse,
    situations: SituationsResponse,
    drivers: DriversResponse,
    reviews: ReviewsResponse
): QualityReport => {
    return {
        summary: {
            totalRides: summary.totalRides,
            totalTripsWithDriversAdded: drivers.summary.totalTripsWithDriversAdded,
            totalAccuratelyTrackedRides: drivers.summary.totalAccuratelyTrackedRides,
            situations: {
                totalSituations: situations.summary.totalSituations,
                totalDriverLate: situations.summary.totalSituationsByType.totalDriverLate,
                totalOnRouteDelay: situations.summary.totalSituationsByType.totalOnRouteDelay,
                totalDriverNoShow: situations.summary.totalSituationsByType.totalDriverNoShow,
                totalVehicleCondition: situations.summary.totalSituationsByType.totalVehicleCondition,
                totalServiceQuality: situations.summary.totalSituationsByType.totalServiceQuality,
                totalSafetyIncident: situations.summary.totalSituationsByType.totalSafetyIncident,
                totalWrongVehicle:
                  situations.summary.totalSituationsByType.totalWrongVehicle,
                totalOther: situations.summary.totalSituationsByType.totalOther,
            },
            reviews: reviews.summary,
        },
        situations: situations.situations.map(situation => {
            return {
                pickupTime: new Date(situation.pickupTime),
                rideNumber: situation.rideNumber,
                driver: situation.driver?.name ?? '-',
                situationType: situationTypesRecord[situation.situationType ?? SituationType.Other],
            };
        }),
        drivers:
      drivers.drivers?.map(driver => {
          return {
              id: driver.driver.id,
              name: driver.driver.name,
              totalTrips: driver.totalTrips,
              totalReviews: driver.totalReviews,
              averageRate: driver.averageRate,
              rides: driver.rides.map(ride => {
                  return {
                      pickupTime: new Date(ride.pickupTimeLocal),
                      rideNumber: ride.tripUnid,
                      gpsTracking: checkGPSTracking(
                          ride.closestDistanceToArrivalPointInMeters,
                          ride.closestDistanceToDeparturePointInMeters
                      ),
                      driverUpdates: driverUpdateCheck(ride.timeDifferenceBetweenFirstAndLastStatusUpdate),
                  };
              }),
          };
      }) ?? [] as Driver[],
        reviews: reviews.reviews,
    };
};
