import { PrimaryButton, SecondaryButton } from '@get-e/react-components';
import CloseIcon from '@mui/icons-material/Close';
import {
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import React, { FC } from 'react';

import theme from '../../styles/theme';
import useModalStyles from './Modal.styles';

export interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
    title: string;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
    maxWidth?: DialogProps['maxWidth'];
    isFullScreen?: boolean;
    isDisabled?: boolean;
    confirmButtonClassName?: string;
    fullWidth?: boolean;
    modalContentClassName?: string;
}

const Modal: FC<React.PropsWithChildren<ModalProps>> = ({
    children,
    isOpen,
    onClose,
    onSubmit,
    title,
    confirmButtonLabel,
    cancelButtonLabel,
    maxWidth,
    isFullScreen,
    isDisabled,
    confirmButtonClassName,
    fullWidth,
    modalContentClassName,
}) => {
    const classes = useModalStyles();
    const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullScreen={isFullScreen ?? isExtraSmall}
            maxWidth={maxWidth ?? 'md'}
            fullWidth={fullWidth}
            className={modalContentClassName}
        >
            <IconButton className={classes.closeButton} aria-label="close" onClick={onClose} size="large">
                <CloseIcon />
            </IconButton>
            <DialogTitle className={classes.title}>
                {title}
            </DialogTitle>
            <DialogContent className={classes.content}>
                {children}
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.overlayGradient}></div>
                <Container className={classes.buttonContainer}>
                    {confirmButtonLabel && (
                        <PrimaryButton
                            onClick={onSubmit}
                            className={clsx(
                                classes.button,
                                classes.confirmButton,
                                confirmButtonClassName
                            )}
                            disabled={isDisabled}
                        >
                            {confirmButtonLabel}
                        </PrimaryButton>
                    )}
                    {cancelButtonLabel && (
                        <SecondaryButton
                            className={classes.button}
                            onClick={onClose}
                        >
                            {cancelButtonLabel}
                        </SecondaryButton>
                    )}
                </Container>
            </DialogActions>
        </Dialog>
    );
};

export default Modal;
