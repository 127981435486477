/* eslint-disable no-useless-escape */
/* eslint-disable prefer-named-capture-group */
export const IS_EMAIL
    = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

// At least one lowercase letter, one uppercase letter, one digit, one special character, and is at least eight characters.
export const IS_PASSWORD
    = /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))/;

export const IS_PHONE_NUMBER = /^([0-9\s\-\+\(\)]{11,})$/;

export const IS_EDIT_AVAILABILITY_ROUTE = /\/availability\/\d+\/edit/;

// Allows only digits and point as a decimal separator
export const IS_DECIMAL_NUMBER = /^\d*\.?\d*$/;

export const IS_LETTER_AND_NUMBER = /^[a-zA-Z0-9]*$/;
