import { useQueries } from 'react-query';

import { mapQualityReportData } from '../../helpers/mapQualityReportData';
import { ReportParams } from './types';
import { listSummary, listDrivers, listSituations, listReviews } from '.';

export function useReport(variables: ReportParams) {
    const queryResults = useQueries([
        {
            queryKey: [`USE_SUMMARY${variables.queryKey}`, 1],
            queryFn: () => listSummary(variables),
            enabled: Boolean(variables.accountId),
            refetchOnWindowFocus: false,
            retry: false,
        },
        {
            queryKey: [`USE_SITUATIONS${variables.queryKey}`, 2],
            queryFn: () => listSituations(variables),
            enabled: Boolean(variables.accountId),
            refetchOnWindowFocus: false,
            retry: false,
        },
        {
            queryKey: [`USE_DRIVERS${variables.queryKey}`, 3],
            queryFn: () => listDrivers(variables),
            enabled: Boolean(variables.accountId),
            refetchOnWindowFocus: false,
            retry: false,
        },
        {
            queryKey: [`USE_REVIEWS${variables.queryKey}`, 4],
            queryFn: () => listReviews(variables),
            enabled: Boolean(variables.accountId),
            refetchOnWindowFocus: false,
            retry: false,
        },
    ]);

    const [
        { data: summary, isLoading: isLoadingSummary, isRefetching: isRefetchingSummary },
        { data: situations, isLoading: isLoadingSituations, isRefetching: isRefetchingSituations },
        { data: drivers, isLoading: isLoadingDrivers, isRefetching: isRefetchingDrivers },
        { data: reviews, isLoading: isLoadingReviews, isRefetching: isRefetchingReviews },
    ] = queryResults;

    const report = summary && situations && drivers && reviews && mapQualityReportData(
        summary,
        situations,
        drivers,
        reviews,
    );

    const isLoading = isLoadingSummary || isLoadingSituations || isLoadingDrivers
    || isLoadingReviews || isRefetchingSummary || isRefetchingSituations || isRefetchingDrivers || isRefetchingReviews;

    const refetchAll = () => {
        queryResults.forEach(query => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            query.refetch();
        });
    };

    return {
        report,
        isLoading,
        refetchAll,
    };
}
