import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import StarIcon from '@mui/icons-material/Star';
import { Box, Grid, Typography } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import DropdownButtonMenu from '../../../../components/dropdownButtonMenu/DropdownButtonMenu';
import { DropdownMenuOption } from '../../../../components/dropdownMenu/DropdownMenu';
import IconedData from '../../../../components/iconedData/IconedData';
import {
    SINGLE_RIDE_ADD_DRIVER,
    SINGLE_RIDE_CHANGE_DRIVER,
    SINGLE_RIDE_DRIVER_UNASSIGN,
} from '../../../../constants/amplitude/supplierKeys';
import { COLORS } from '../../../../constants/colors';
import { Severity, useNotificationContext } from '../../../../context/NotificationContext';
import { Driver } from '../../../drivers/api/types';
import CellWrapper from '../../../rides/components/CellWrapper';
import { unassignDriverFromRide } from '../../../rides/supplier/api';
import AddDriverModalRidesOverview from '../../../rides/supplier/components/AddDriverModalRidesOverview';
import ChangeDriverModalRidesOverview from '../../../rides/supplier/components/ChangeDriverModalRidesOverview';
import RemoveDriverConfirmModal from '../../../rides/supplier/components/RemoveDriverConfirmModal';
import { RideContext } from '../context/RideContext';

interface DriverInformationCardProps {
    driver: Driver | null;
    rideId: string;
    onDriverManageFinished: () => void;
    isVisible?: boolean;
    isTitileDisabled?: boolean;
    order?: number;
}

const DriverInformationCard = ({
    driver,
    rideId,
    onDriverManageFinished,
    isVisible,
    isTitileDisabled,
    order,
}: DriverInformationCardProps) => {
    const { t } = useTranslation();
    const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);
    const [isChangeDriverModalOpen, setIsChangeDriverModalOpen] = useState(false);
    const [isRemoveDriverConfirmModalOpen, setIsRemoveDriverConfirmModalOpen] = useState(false);
    const [isSavingRemove, setIsSavingRemove] = useState(false);
    const { amplitudeEventProperties } = useContext(RideContext);
    const { showNotification } = useNotificationContext();

    const handleManageDriver = () => {
        if (driver) {
            setIsChangeDriverModalOpen(true);
            logAmplitudeEvent(SINGLE_RIDE_CHANGE_DRIVER, amplitudeEventProperties);
        } else {
            setIsAddDriverModalOpen(true);
            logAmplitudeEvent(SINGLE_RIDE_ADD_DRIVER, amplitudeEventProperties);
        }
    };

    const driverButtonOptionsMap = useMemo(() => {
        if (driver) {
            return {
                mainButton: 'buttonName.changeDriver',
                options: ['UNASSIGN_DRIVER'],
            };
        }

        return {
            mainButton: 'buttonName.addDriver',
            options: [],
        };
    }, [driver]);

    const driverButtonOptions = useMemo(() => {
        const options: DropdownMenuOption[] = [];

        if (!driverButtonOptionsMap) {
            return options;
        }

        if (driverButtonOptionsMap.options.includes('UNASSIGN_DRIVER')) {
            options.push({
                id: 1,
                label: t('buttonName.unassignDriver'),
                icon: DeleteOutlineOutlinedIcon,
                onClick: () => {
                    setIsRemoveDriverConfirmModalOpen(true);
                    logAmplitudeEvent(SINGLE_RIDE_DRIVER_UNASSIGN, amplitudeEventProperties);
                },
            });
        }

        return options;
    }, [amplitudeEventProperties, driverButtonOptionsMap, t]);

    const onConfirmRemoveDriver = async () => {
        try {
            setIsSavingRemove(true);
            await unassignDriverFromRide(rideId);
            showNotification(t('alert.edit.successRemoveDriver'), Severity.Info);
            logAmplitudeEvent(SINGLE_RIDE_DRIVER_UNASSIGN, amplitudeEventProperties);
            onDriverManageFinished();
        } catch (error) {
            showNotification(t('alert.driverUnassignError'), Severity.Error);
        } finally {
            setIsRemoveDriverConfirmModalOpen(false);
            setIsSavingRemove(false);
        }
    };

    if (!isVisible) {
        return null;
    }

    return (
        <Box marginBottom="1rem" marginTop="0.75rem" order={order ?? 0}>
            <Box marginBottom="0.5rem">
                <Typography
                    style={{
                        fontWeight: 700,
                        color: isTitileDisabled ? COLORS.SLATE_GREY : COLORS.BLUE_DARK,
                    }}
                >
                    {t('driver')}
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="0.25rem">
                <BadgeOutlinedIcon
                    style={{
                        color: COLORS.SLATE_GREY,
                        marginRight: '0.625rem',
                        alignSelf: 'flex-start',
                    }}
                />
                <Box display="flex" flexWrap="wrap" alignItems="center">
                    <Box display="flex" marginRight="0.5rem">
                        <Typography color={COLORS.SLATE_GREY} fontWeight={700} marginRight="0.25rem">
                            {`${t('name')}:`}
                        </Typography>
                        <Typography color={COLORS.BLACK}>{driver?.name ?? t('notAssigned')}</Typography>
                    </Box>
                    {Boolean(driver?.feedbacks) && (
                        <Box display="flex" alignItems="center">
                            <Typography color={COLORS.SLATE_GREY}>{(driver?.feedbacks?.averageRate ?? 0).toFixed(1)}</Typography>
                            <StarIcon
                                style={{
                                    color: COLORS.SLATE_GREY,
                                    fontSize: '1rem',
                                    padding: '0 0.25rem',
                                }}
                            />
                            <Typography color={COLORS.SLATE_GREY}>
                                {`(${driver?.feedbacks?.count ?? 0} ${t('reviews').toLowerCase()})`}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box>
                <IconedData
                    icon={PhoneOutlinedIcon}
                    data={driver?.phoneNumber ?? t('notAvailable')}
                    dataLabel={`${t('form.fields.phoneNumber')}:`}
                    dataLabelColor={COLORS.SLATE_GREY}
                />
            </Box>
            <Grid item xs={12} marginTop="1rem">
                <CellWrapper>
                    <Box display="flex" justifyContent={'flex-start'}>
                        <DropdownButtonMenu
                            buttonLabel={driverButtonOptionsMap.mainButton}
                            onClick={handleManageDriver}
                            options={driverButtonOptions}
                            width={driverButtonOptionsMap.options.length === 0 ? '204px' : '164px'}
                            fontSize="1rem"
                            buttonHeight="44px"
                        />
                    </Box>
                </CellWrapper>
            </Grid>
            {isAddDriverModalOpen && (
                <AddDriverModalRidesOverview
                    isModalOpen={isAddDriverModalOpen}
                    onClose={() => setIsAddDriverModalOpen(false)}
                    rideId={rideId}
                    onFinished={onDriverManageFinished}
                    amplitudeEventProperties={amplitudeEventProperties}
                />
            )}
            {isChangeDriverModalOpen && driver && (
                <ChangeDriverModalRidesOverview
                    isModalOpen={isChangeDriverModalOpen}
                    onClose={() => setIsChangeDriverModalOpen(false)}
                    rideId={rideId}
                    initialDriver={driver}
                    onFinished={onDriverManageFinished}
                    amplitudeEventProperties={amplitudeEventProperties}
                />
            )}
            {isRemoveDriverConfirmModalOpen && (
                <RemoveDriverConfirmModal
                    onClose={() => setIsRemoveDriverConfirmModalOpen(false)}
                    onConfirm={onConfirmRemoveDriver}
                    isLoading={isSavingRemove}
                />
            )}
        </Box>
    );
};

export default DriverInformationCard;
