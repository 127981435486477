import List from '@material-ui/core/List';
import AddIcon from '@material-ui/icons/Add';
import BarChartIcon from '@material-ui/icons/BarChart';
import FolderIcon from '@material-ui/icons/Folder';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BOOKING_TOOL, DOCUMENTS, EXPORT, RIDES, USERS } from '../../../constants/urlPaths';
import { useCurrentProfileContext } from '../../../context/CurrentProfileContext';
import { MenuListItem } from '../../menuListItem/MenuListItem';
import navigationStyles from '../NavigationMenu.styles';

const CustomerList = ({ showDocuments }) => {
    const classes = navigationStyles();
    const { t } = useTranslation();
    const { currentProfile } = useCurrentProfileContext();

    return (
        <>
            <List role="menu" className={classes.listContainer} data-testid="customer-navigation">
                <div className={classes.separateListContainer}>
                    <MenuListItem
                        icon={<AddIcon fontSize="small" />}
                        label={t('navigation.customer.bookingTool')}
                        href={BOOKING_TOOL}
                    />
                    <MenuListItem icon={<ListIcon fontSize="small" />} label={t('rides')} href={RIDES} />
                    <MenuListItem
                        icon={<BarChartIcon fontSize="small" />}
                        label={t('navigation.customer.export')}
                        href={EXPORT}
                    />
                    {currentProfile.permissions.manageUsers && (
                        <MenuListItem
                            icon={<PersonIcon fontSize="small" />}
                            label={t('users')}
                            href={USERS}
                            data-testid="users-tab"
                        />
                    )}
                    {showDocuments && (
                        <MenuListItem
                            icon={<FolderIcon fontSize="small" />}
                            label={t('documents')}
                            href={DOCUMENTS}
                        />
                    )}
                </div>
            </List>
        </>
    );
};

export default CustomerList;
