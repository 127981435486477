import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Box, Rating, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MopinionForm from 'react-mopinion-forms';

import { COLORS } from '../../../constants/colors';
import { FeedbackTopics, RideFeedback } from '../api/types';

const useStyles = makeStyles({
    mainWrapperWebForm: {
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '3px',
        padding: '1rem',
        [`& #mopinion--${process.env.REACT_APP_WEB_FEEDBACK_FORM_RIDES ?? ''}`]: {
            '& .control-group.question': {
                padding: '0 !important',
                marginBottom: '1rem',
            },
            '& .block-title': { margin: '0' },
            '& .block-title > *': {
                color: `${COLORS.BLUE_DARK} !important`,
                fontWeight: '400 !important',
            },
            '& .stars-group .ui-stars-star': { fontSize: '1.5rem !important' },
            '& .form-actions': { padding: '0' },
            '& .mopinion-survey-content .ui-stars-star-on .mopicon-star': { color: COLORS.ORANGE_OFFICIAL },
            '& .mopinion-survey-content .ui-stars-star-hover .mopicon-star': { color: COLORS.ORANGE_OFFICIAL },
            '& .btn-submit': {
                backgroundColor: `${COLORS.ORANGE} !important`,
                borderColor: `${COLORS.ORANGE} !important`,
                boxShadow: 'unset !important',
                '&:hover': {
                    backgroundColor: `${COLORS.ORANGE_TEXT} !important`,
                    borderColor: `${COLORS.ORANGE_TEXT} !important`,
                    transform: 'unset',
                },
            },
        },
    },
    mainWrapperCustomForm: {
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '3px',
    },
    wrapper: { flexDirection: 'column' },
    heading: {
        marginBottom: '1rem',
        color: COLORS.BLUE_DARK,
        fontSize: '1rem',
        fontWeight: 700,
    },
    rating: {
        color: COLORS.ORANGE_OFFICIAL,
        fontSize: '1.5rem',
    },
    accordion: {
        '& .MuiAccordionSummary-root': { padding: '0' },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'unset',
            borderBottom: `1px solid ${COLORS.BLACK_12}`,
            borderTop: `1px solid ${COLORS.BLACK_12}`,
        },
        '& .MuiAccordionSummary-content': {
            margin: '0',
            padding: '0 1rem',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': { margin: '1rem 0' },
        '& .MuiAccordionSummary-expandIconWrapper': { marginRight: '0.5rem' },
        '& .MuiAccordionDetails-root': { padding: '1rem !important' },

        boxShadow: 'none',
        marginBottom: '0 !important',
        marginTop: '0 !important',
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem 0.3rem',
    },
    firstAccordion: { '& .MuiAccordionSummary-root.Mui-expanded': { borderTop: '0 !important' } },
});

interface RideReviewProps {
    feedbacks: RideFeedback[];
    isVisible?: boolean;
    disableEditing?: boolean;
    marginBottom?: string;
    displayTitle?: string;
    isCompactDesign?: boolean;
}

const RideReview = (
    {
        feedbacks,
        isVisible = true,
        disableEditing,
        marginBottom,
        displayTitle,
        isCompactDesign,
    }: RideReviewProps
) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (!isVisible) {
        return null;
    }

    if (!feedbacks.length && !disableEditing) {
        return (
            <Box className={classes.mainWrapperWebForm}>
                <Typography variant="h3" component="h4" className={classes.heading}>
                    {t('pages.rides.review')}
                </Typography>
                <MopinionForm formKey={process.env.REACT_APP_WEB_FEEDBACK_FORM_RIDES ?? ''} />
            </Box>
        );
    }

    return (
        <Box marginTop={isCompactDesign ? '1rem' : 0}>
            {displayTitle && feedbacks.length > 0 && (
                <Typography
                    style={{
                        fontWeight: 700,
                        color: COLORS.BLUE_DARK,
                        marginBottom: isCompactDesign ? '0.5rem' : '1rem',
                    }}
                >
                    {displayTitle}
                </Typography>
            )}
            {feedbacks.length > 0 && (
                <Box className={classes.mainWrapperCustomForm} marginBottom={marginBottom ?? 0}>
                    {feedbacks.map((feedback, index) => {
                        const feedbackRates = {
                            SERVICE_RATE: feedback.topics.find(topic => topic.topic === FeedbackTopics.SERVICE_RATE)?.rate,
                            MEETING_DRIVER_RATE: feedback.topics.find(topic =>
                                topic.topic === FeedbackTopics.MEETING_DRIVER_RATE)?.rate,
                            DRIVER_RATE: feedback.topics.find(topic => topic.topic === FeedbackTopics.DRIVER_RATE)?.rate,
                            VEHICLE_RATE: feedback.topics.find(topic => topic.topic === FeedbackTopics.VEHICLE_RATE)?.rate,
                        };

                        const comment = feedback.comment ?? '';

                        return (
                            <Accordion className={clsx(classes.accordion, { [classes.firstAccordion]: index === 0 })} key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                        <Typography fontWeight="700">{t('averageRating')}</Typography>
                                        <Box display="flex" alignItems="center">
                                            <Typography fontWeight="700" paddingRight="0.5rem">
                                                {feedback.averageRate.toFixed(1)}
                                            </Typography>
                                            <Rating className={classes.rating} value={feedback.averageRate} readOnly />
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    {feedbackRates?.SERVICE_RATE && (
                                        <Box marginBottom="0.5rem">
                                            <Typography marginBottom="0.5rem">
                                                {t('pages.rides.howYouRateThisService')}
                                            </Typography>
                                            <Box display="flex" alignItems="center">
                                                <Typography fontWeight="700" paddingRight="0.5rem">
                                                    {feedbackRates.SERVICE_RATE.toFixed(1)}
                                                </Typography>
                                                <Rating className={classes.rating} value={feedbackRates.SERVICE_RATE} readOnly />
                                            </Box>
                                        </Box>
                                    )}
                                    {feedbackRates?.MEETING_DRIVER_RATE && (
                                        <Box marginBottom="0.5rem">
                                            <Typography marginBottom="0.5rem">{t('pages.rides.wasMeetingDriverEasy')}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <Typography fontWeight="700" paddingRight="0.5rem">
                                                    {feedbackRates.MEETING_DRIVER_RATE.toFixed(1)}
                                                </Typography>
                                                <Rating
                                                    className={classes.rating}
                                                    value={feedbackRates.MEETING_DRIVER_RATE}
                                                    readOnly
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                    {feedbackRates?.DRIVER_RATE && (
                                        <Box marginBottom="0.5rem">
                                            <Typography marginBottom="0.5rem">{t('pages.rides.howYouRateDriver')}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <Typography fontWeight="700" paddingRight="0.5rem">
                                                    {feedbackRates.DRIVER_RATE.toFixed(1)}
                                                </Typography>
                                                <Rating className={classes.rating} value={feedbackRates.DRIVER_RATE} readOnly />
                                            </Box>
                                        </Box>
                                    )}
                                    {feedbackRates?.VEHICLE_RATE && (
                                        <Box marginBottom="0.5rem">
                                            <Typography marginBottom="0.5rem">{t('pages.rides.howYouRateVehicle')}</Typography>
                                            <Box display="flex" alignItems="center">
                                                <Typography fontWeight="700" paddingRight="0.5rem">
                                                    {feedbackRates.VEHICLE_RATE.toFixed(1)}
                                                </Typography>
                                                <Rating className={classes.rating} value={feedbackRates.VEHICLE_RATE} readOnly />
                                            </Box>
                                        </Box>
                                    )}
                                    <Box>
                                        <Typography marginBottom="0.5rem" fontWeight="700">
                                            {t('comment')}
                                        </Typography>
                                        <Typography>{comment}</Typography>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

export default RideReview;
