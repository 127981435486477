import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

import { TOOLBAR_HEIGHT } from './theme';

const containerStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        minHeight: '100%',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
    },
    rootBookingTool: { maxWidth: 'unset' },
    rootWithoutNavigation: {
        padding: theme.spacing(4, 0, 0, 0),
        height: 'calc(100% - 32px)',
        backgroundColor: theme.palette.grey[100],
    },
    iframePage: {
        padding: `${theme.spacing(0)} !important`,

        // Lines 19-20 required to prevent double scrollbars in iframes
        height: '100vh',
        margin: '0 0 -4em',
        '& [class*="content"]': { padding: `${theme.spacing(0)} !important` },
    },
    bookingTool: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
    },
    content: {
        flexGrow: 1,
        height: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
        marginTop: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: '0',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '3em',
            padding: '1em 1em',
            '&[class*="iframePage"]': { padding: `${theme.spacing(0)} !important` },
        },
        [theme.breakpoints.down('sm')]: { marginTop: TOOLBAR_HEIGHT },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '3em',
            padding: '2em 2em 3em',
            '&[class*="iframePage"]': {
                padding: `${theme.spacing(0)} !important`,

                /*
                 * 48 === 3em and accounts for body padding.
                 * Required to prevent scroll bars
                 */
                height: `calc(100vh - ${TOOLBAR_HEIGHT + 48}px)`,
            },
        },
    },
    contentWithoutNavigation: {
        height: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
        paddingTop: TOOLBAR_HEIGHT,
        '& > *': { height: '100%' },
        backgroundColor: theme.palette.grey[100],
    },
    contentShift: {
        [theme.breakpoints.up('sm')]: {
            padding: '2em 2em 3em',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),

            '&[class*="iframePage"]': {
                /*
                 * 48 === 3em and accounts for body padding.
                 * Required to prevent scroll bars
                 */
                height: `calc(100vh - ${TOOLBAR_HEIGHT + 48}px)`,
                padding: `${theme.spacing(0)} !important`,
            },

            marginLeft: '200px',
        },
    },
    companyInformationContainer: {
        width: '100%',
        paddingBottom: '2em',
        textAlign: 'center',
    },
    fullHeight: {
        // Enables full screen height in IE11.
        height: '100vh',
    },
    framedFullHeight: {
        // Prevent scroll bars in framed error pages.
        minHeight: `calc(100vh - (${TOOLBAR_HEIGHT}px + 5em))`,
    },

}));

export default containerStyles;
