import { FormHelperText, RadioGroup } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { InputError } from '../../../../helpers/inputValidation/InputError';
import PassengerInformation, { Passenger } from './PassengerInformation';

export interface PassengerError {
    id: string;
    firstName: InputError | null;
    lastName: InputError | null;
    email: InputError | null;
    phone: InputError | null;
}

interface PassengerListProps {
    passengers: Passenger[];
    setValues: Dispatch<SetStateAction<Passenger[]>>;
    errors: PassengerError[];
    setErrors: Dispatch<SetStateAction<PassengerError[]>>;
}

export const PassengerList = ({ passengers, setValues, errors, setErrors }: PassengerListProps) => {
    const { t } = useTranslation();
    const [contentKey, setContentKey] = useState(0);
    const [isMainPassengerRequiredError, setIsMainPassengerRequiredError] = useState(false);
    const isOnlyOnePassenger = passengers.length === 1;

    useEffect(() => {
        if (!passengers.find(el => el.isLead)) {
            setIsMainPassengerRequiredError(true);
        } else {
            setIsMainPassengerRequiredError(false);
        }
    }, [passengers]);

    const handleAddNewPassenger = () => {
        const initialPassengerId = uuidv4();

        const updatedValues = [
            ...passengers,
            {
                id: initialPassengerId,
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                isLead: false,
            },
        ];

        const updatedErrorValues = [
            ...errors,
            {
                id: initialPassengerId,
                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                isLead: false,
            },
        ];

        setContentKey(contentKey + 1);
        setValues(updatedValues);
        setErrors(updatedErrorValues);
    };

    const handleRemovePassenger = (id: string) => {
        const updatedValues = passengers.filter(traveller => traveller.id !== id);

        setValues(updatedValues);
    };

    const updatePassengers = (updatedPassenger: Passenger, updatedError: PassengerError) => {
        const updatedValues = passengers.map(passenger => (passenger.id === updatedPassenger.id ? updatedPassenger : passenger));
        const updatedErrors = errors.map(passenger => (passenger.id === updatedPassenger.id ? updatedError : passenger));

        setValues(updatedValues);
        setErrors(updatedErrors);
    };

    const handleChangeMainPassenger = (passengerId: string) => {
        setValues(prevPassengers => prevPassengers.map(passenger => ({
            ...passenger,
            isLead: passenger.id === passengerId,
        })));
        setContentKey(contentKey + 1);
    };

    return (
        <RadioGroup
            value={passengers.find(el => el.isLead)?.id}
            onChange={event => handleChangeMainPassenger((event.target as HTMLInputElement).value)}
        >
            <div key={contentKey}>
                {isMainPassengerRequiredError && (
                    <FormHelperText error>
                        {t('errors.mainPassengerRequired')}
                    </FormHelperText>
                )}
                {passengers?.map((passenger, index) => {
                    return (
                        <PassengerInformation
                            passenger={passenger}
                            key={passenger.id}
                            handleAddNewPassenger={handleAddNewPassenger}
                            handleRemovePassenger={handleRemovePassenger}
                            updatePassengers={updatePassengers}
                            passengerCount={index + 1}
                            errorValues={errors.find(el => el.id === passenger.id)}
                            isOnlyOnePassenger={isOnlyOnePassenger}
                        />
                    );
                })}
            </div>
        </RadioGroup>
    );
};
