import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../components/modal/Modal';
import { AssignDriverToRide } from '../../../rides/supplier/api/types';
import { RemarksValues, initialRemarks } from '../../../rides/supplier/components/RemarksCard';
import { RideResponse } from '../../api/types';
import RideInformationCard from '../../customer/components/RideInformationCard';
import useConfirmCloseAddRemarksRide from '../hooks/useConfirmCloseAddRemarksRide';
import useSupplierDriverInformation from '../hooks/useSupplierDriverInformation';
import ReferenceCard from './ReferenceCard';
import SelectedVehicleCard from './SelectedVehicleCard';

const useStyles = makeStyles({ confirmRideModal: { '& .MuiDialog-container .MuiPaper-root': { maxWidth: '700px' } } });

interface ConfirmRideModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    onFinished: (updatedRide?: RideResponse) => void;
    ride: RideResponse;
    supplierReference?: string;
    driverChangeRequest?: AssignDriverToRide;
    isDriverUnassign?: boolean;
}

const ConfirmRideModal = ({
    isModalOpen,
    onClose,
    onFinished,
    ride,
    supplierReference,
    driverChangeRequest,
    isDriverUnassign,
}: ConfirmRideModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [remarksValues] = useState<RemarksValues>(initialRemarks);
    const [reference, setReference] = useState(supplierReference ?? null);
    const { handleChangeDriver, handleUnassignDriver } = useSupplierDriverInformation();

    const { onConfirmRide, isSaving } = useConfirmCloseAddRemarksRide({
        rideId: ride.unid,
        reference,
        remarksValues,
    });

    const handleConfirm = async () => {
        if (driverChangeRequest) {
            await handleChangeDriver(driverChangeRequest);
        }

        if (isDriverUnassign) {
            await handleUnassignDriver(ride.unid);
        }

        const data = await onConfirmRide();

        onClose();
        onFinished(data);
    };

    return (
        <Modal
            modalContentClassName={classes.confirmRideModal}
            isOpen={isModalOpen}
            onClose={onClose}
            onSubmit={handleConfirm}
            title={t('pages.rides.confirmRide')}
            confirmButtonLabel={t('buttonName.confirm')}
            cancelButtonLabel={t('buttonName.back')}
            isDisabled={isSaving}
            maxWidth="md"
            fullWidth
        >
            <Grid
                container
                style={{
                    width: '100%',
                    flexDirection: 'column',
                }}
            >
                <Grid item xs display="flex" justifyItems="flex-start">
                    <RideInformationCard isSupplierPage data={ride} showUtcSwitch={false} isInModal />
                </Grid>

                <Grid item xs display="flex" justifyItems="flex-start">
                    <SelectedVehicleCard data={ride} isInModal />
                </Grid>
                <Grid item marginTop="1rem">
                    <ReferenceCard
                        isVisible
                        reference={reference}
                        setReference={setReference}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ConfirmRideModal;
