import { GridRowsProp, GridValidRowModel } from '@mui/x-data-grid-pro';

import { DATE_FORMATS } from '../../../../constants/dateFormats';
import { TIME_FORMATS } from '../../../../constants/timeFormats';
import { getDateTimeWithoutOffsetFormatted } from '../../../../helpers/getDateTimeWithoutOffset';
import { RideStatus } from '../../../../helpers/rideStatusUtil';
import { RideResponse } from '../../../ride/api/types';

const isUpdatedRideStatusMatching = (
    updatedStatus: keyof typeof RideStatus,
    statusFilterKeys: Array<keyof typeof RideStatus>
) => {
    // If no status filters are selected that nmeans all statuses are applied
    if (statusFilterKeys.length === 0) {
        return true;
    }

    switch (updatedStatus) {
        case 'COMPLETED':
        case 'CANCELLED':
        case 'TO_CLOSE':
            return statusFilterKeys.includes(updatedStatus);
        case 'TO_CONFIRM':
        case 'TO_CONFIRM_CHANGE':
            return statusFilterKeys.includes('TO_CONFIRM');
        case 'ACTIVE':
        case 'CONFIRMED':
            return statusFilterKeys.includes('CONFIRMED');
        default:
            return false;
    }
};

export const mapRidesToRows = (props: {
    rows: RideResponse[];
    updatedRide?: RideResponse;
    statusFilterKeys: Array<keyof typeof RideStatus>;
}): GridRowsProp => {
    const result: GridValidRowModel[] = [];

    props.rows.forEach(row => {
        let data = row;

        if (props.updatedRide && props.updatedRide.unid === row.unid) {
            if (!isUpdatedRideStatusMatching(props.updatedRide.status, props.statusFilterKeys)) {
                return;
            }

            data = props.updatedRide;
        }

        result.push({
            id: data.unid,
            rideNumber: data.prettifiedUnid,
            status: data.status,
            trackingStatus: data.trackingStatus,
            originalPickupUtc: data.pickUp.departAt,
            pickupDateTime:
            getDateTimeWithoutOffsetFormatted(data.pickUp?.departAtLocal, DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']),
            pickupDate: getDateTimeWithoutOffsetFormatted(data.pickUp?.departAtLocal, DATE_FORMATS['ddd DD MMM']),
            pickupTime: getDateTimeWithoutOffsetFormatted(data.pickUp?.departAtLocal, TIME_FORMATS['HH:mm A']),
            pickupDateSorting: getDateTimeWithoutOffsetFormatted(data.pickUp?.departAtLocal, DATE_FORMATS['DD MMM YYYY']),
            passengers: data.passengers,
            vehicleType: `${data.vehicle?.class} ${data.vehicle?.type} ${
                data.vehicle?.brandAndModel ? `(${data.vehicle.brandAndModel})` : ''
            }`,
            customerReference: data.customerReference,
            driver: data.driver,
            price: `${data.price?.isoCurrency} ${data.price?.amount.toFixed(2)}`,
            passengersCount: data.numberOfPassengers,
            luggages: data.numberOfBags,
            flightStatus: data.flightDetails?.status,
            flightDetails: data.flightDetails,
            distanceInMeters: data.distanceInMeters,
            durationInSeconds: data.durationInSeconds,
            numberOfPassengers: data.numberOfPassengers,
            numberOfBags: data.numberOfBags,
            vehicle: data.vehicle,
            specialRequest: data.specialRequest,
            pickupDescription: data.pickupDescription,
            notesForTheCustomer: data.notesForTheCustomer,
            pickUp: data.pickUp,
            dropOff: data.dropOff,
            supplierReference: data.supplierReference,
            unid: data.unid,
        });
    });

    return result;
};
