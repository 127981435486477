import { TextField } from '@get-e/react-components';
import { Grid, Box, Typography } from '@mui/material';
import React, { SetStateAction, Dispatch, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { RIDE_INFORMATION_REFERENCE } from '../../../../constants/amplitude/customerKeys';
import { COLORS } from '../../../../constants/colors';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import { CustomField } from '../../api/types';
import { RideContext } from '../../customer/context/RideContext';

export interface ReferenceError {
    customFields: { [key: string]: InputError | null };
}

export const initialReferenceErrors = { customFields: {} };

export interface ReferenceField {
    id?: string;
    customReference: string;
    customFields: CustomField[];
}

export const initialReference = {
    id: uuid(),
    customReference: '',
    customFields: [],
} as ReferenceField;

interface ChangeReferencesProps {
    referenceValues: ReferenceField;
    setReferenceValues: Dispatch<SetStateAction<ReferenceField>>;
    errors: ReferenceError;
    setErrors: Dispatch<SetStateAction<ReferenceError>>;
    initialReferenceValues?: ReferenceField | null;
}

const ChangeReferences = ({
    referenceValues,
    setReferenceValues,
    errors,
    setErrors,
    initialReferenceValues,
}: ChangeReferencesProps) => {
    const { t } = useTranslation();
    const { amplitudeEventProperties } = useContext(RideContext);

    const handleChangeValue = (field: keyof ReferenceField, value: string | null, fieldKey?: string) => {
        let newValues = { ...referenceValues };

        newValues = {
            ...referenceValues,
            [field]: value,
        };

        if (field === 'customFields') {
            const updatedCustomFields = referenceValues.customFields?.map(customField => {
                if (customField.key === fieldKey) {
                    return {
                        ...customField,
                        value,
                    } as CustomField;
                }

                return customField;
            });

            newValues.customFields = updatedCustomFields;
        }

        const newErrors = { ...errors };

        if (field !== 'customFields') {
            newErrors[field] = null;
        } else if (fieldKey) {
            newErrors.customFields[fieldKey] = null;
        }

        setReferenceValues(newValues);
        setErrors(newErrors);
    };

    return (
        <>
            <Box sx={{ marginBottom: '1rem' }}>
                <Typography
                    fontWeight={700}
                    color={COLORS.BLUE_DARK}
                >
                    {t('pages.rides.references')}
                </Typography>
            </Box>
            <Grid item rowGap=".75rem" display="flex" flexDirection="column">
                <Grid item key={referenceValues.id}>
                    <TextField
                        label={t('reference')}
                        value={referenceValues.customReference}
                        onChange={event => handleChangeValue('customReference', event.target.value)}
                        onBlur={() =>
                            (initialReferenceValues?.customReference
                                    !== referenceValues.customReference
                                ? logAmplitudeEvent(RIDE_INFORMATION_REFERENCE, amplitudeEventProperties)
                                : {})
                        }
                    />
                </Grid>
                {referenceValues.customFields?.map(customField => {
                    const customFieldError = errors.customFields[customField.key] !== undefined
                                && errors.customFields[customField.key] !== null;

                    return (
                        <Grid item key={customField.id}>
                            <TextField
                                label={customField.label}
                                value={customField.value}
                                onChange={event => handleChangeValue('customFields', event.target.value, customField.key)}
                                required={customField.isRequired}
                                error={customFieldError}
                                helperText={customFieldError ? t('form.helperText.empty') : ' '}
                                onBlur={() =>
                                    (initialReferenceValues?.customFields[customField.key]
                                            !== referenceValues.customFields[customField.key]
                                        ? logAmplitudeEvent(RIDE_INFORMATION_REFERENCE, amplitudeEventProperties)
                                        : {})
                                }
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default ChangeReferences;
