import { AxiosResponse } from 'axios';

import apiClient from '../../../services/api';
import {
    assignVehicleAndDriverRoute,
    getCloseRideRoute,
    getConfirmRideRoute,
    getRideAddRemarksRoute,
} from '../../../services/routes';
import { ConfirmCloseAddRemarksRideRequest, RideResponse } from './types';

export const confirmRide = async (
    unid: string,
    request: ConfirmCloseAddRemarksRideRequest
): Promise<AxiosResponse<RideResponse>> => {
    const response = await apiClient.post(getConfirmRideRoute(unid), request);

    return response;
};

export const closeRide = async (
    unid: string,
    request: ConfirmCloseAddRemarksRideRequest
): Promise<AxiosResponse<RideResponse>> => {
    const response = await apiClient.post(getCloseRideRoute(unid), request);

    return response;
};

export const addRideRemarks = async (
    unid: string,
    request: ConfirmCloseAddRemarksRideRequest
): Promise<AxiosResponse<RideResponse>> => {
    const response = await apiClient.post(getRideAddRemarksRoute(unid), request);

    return response;
};

export const assignVehicleAndDriver = async (rideId: string, payload: {
    driverId: number;
    vehicleId: number | null;
}): Promise<AxiosResponse> => {
    const response = await apiClient.post(assignVehicleAndDriverRoute(rideId), payload);

    return response;
};
