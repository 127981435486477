/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/quotes */
import { PortalLocale } from "../context/LocaleContext";

export type MomentLocale = "en" | "es";

const findMomentLocale = (value: PortalLocale): MomentLocale => {
  switch (value) {
    case "en-GB":
      return "en";
    case "es-ES":
      return "es";
    default:
      console.log(`${value as string} is unsupported`);
      return "en";
  }
};

export default findMomentLocale;
