import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

import { COLORS } from '../../constants/colors';

const useStyles = makeStyles(() => ({
    label: {
        color: COLORS.BLACK,
        fontSize: '1rem',
        marginRight: '1rem',
        fontWeight: '700',
    },
    data: {
        fontSize: '1rem',
        lineHeight: '1rem',
    },
    dataSuffix: {
        fontSize: '1rem',
        lineHeight: '1rem',
        color: COLORS.DARK_GRAY,
        marginLeft: '1rem',
    },
}));

interface LabeledDataProps {
    label: string;
    data?: string;
    dataSuffix?: string;
    labelColor?: string;
    dataColor?: string;
    labelColumnNumber?: number;
    labelClassName?: string;
    dataColumnNumber?: number;
    ignoreColumns?: boolean;
    className?: string;
    itemClassName?: string;
    paddingBottom?: string;
}

const LabeledData: FunctionComponent<LabeledDataProps> = ({
    label = '',
    data = '',
    dataSuffix = '',
    labelColor = COLORS.BLUE,
    dataColor = COLORS.BLACK,
    labelColumnNumber = 4,
    labelClassName = '',
    dataColumnNumber = 8,
    ignoreColumns = false,
    className = '',
    itemClassName = '',
    paddingBottom,
}) => {
    const classes = useStyles();

    return (
        <Grid
            container
            spacing={1}
            className={className}
            sx={{
                paddingBottom: paddingBottom ?? '0.75rem',
                flexWrap: 'unset',
                alignItems: 'baseline',
            }}
        >
            <Grid
                item
                xs={ignoreColumns ? false : labelColumnNumber}
                className={itemClassName}
                component="span"
            >
                <Typography
                    className={clsx({
                        [classes.label]: true,
                        [labelClassName]: labelClassName,
                    })}
                    sx={{ color: `${labelColor} !important` }}
                    variant="caption"
                    gutterBottom
                >
                    {label}
                </Typography>
            </Grid>
            {data && (
                <Grid
                    item
                    xs={ignoreColumns ? false : dataColumnNumber}
                    className={itemClassName}
                    component="span"
                >
                    <Typography
                        sx={{ color: `${dataColor} !important` }}
                        className={classes.data}
                        variant="caption"
                        gutterBottom
                    >
                        {data}
                    </Typography>
                    {dataSuffix && (
                        <Typography className={classes.dataSuffix} variant="caption">
                            {dataSuffix}
                        </Typography>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default LabeledData;
