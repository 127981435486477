import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

import { COLORS } from '../../constants/colors';

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        padding: '.5rem',
        justifyContent: 'space-between',
        columnGap: '.5rem',
        height: 'fit-content',
    },
    addButton: {
        border: `1px solid ${COLORS.BLUE_TEXT}`,
        borderRadius: '.25rem',
        height: '24px',
        width: '24px',
        '& .MuiSvgIcon-root': {
            color: COLORS.BLUE_TEXT,
            height: '24px',
        },
    },
    deleteButton: {
        border: `1px solid ${COLORS.RED}`,
        '& .MuiSvgIcon-root': {
            color: COLORS.RED,
            height: '20px',
        },
    },
    disabledButton: {
        border: `1px solid ${COLORS.BLACK_12}`,
        '& .MuiSvgIcon-root': {
            color: COLORS.BLACK_12,
            height: '20px',
        },
    },
});

interface AddDeleteButtonsProps {
    onAddClick: () => void;
    onDeleteClick: () => void;
    isAddDisabled?: boolean;
    isDeleteDisabled?: boolean;
}

const AddDeleteButtons = ({ onAddClick, onDeleteClick, isAddDisabled, isDeleteDisabled }: AddDeleteButtonsProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.wrapper}>
            <IconButton
                className={clsx(
                    classes.addButton,
                    { [classes.disabledButton]: isAddDisabled }
                )}
                onClick={() => onAddClick?.()}
                disabled={isAddDisabled}
            >
                <AddOutlined />
            </IconButton>
            <IconButton
                className={clsx(
                    classes.addButton,
                    classes.deleteButton,
                    { [classes.disabledButton]: isDeleteDisabled }
                )}
                onClick={() => onDeleteClick?.()}
                disabled={isDeleteDisabled}
            >
                <DeleteOutlineOutlined />
            </IconButton>
        </Box>
    );
};

export default AddDeleteButtons;
