import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import IconedData from '../../../../../../components/iconedData/IconedData';
import { COLORS } from '../../../../../../constants/colors';
import metersToKmAndMiFormatted from '../../../../../../helpers/metersToKmAndMiFormatted';
import secondsToTimeFormatted from '../../../../../../helpers/secondsToTimeFormatted';

interface RideSummaryProps {
    distanceInMeters?: number;
    durationInSeconds?: number;
}

const RideSummary = ({
    distanceInMeters,
    durationInSeconds,
}: RideSummaryProps) => {
    const { t } = useTranslation();

    const distance = useMemo(() => metersToKmAndMiFormatted(distanceInMeters), [distanceInMeters]);

    const time = useMemo(() => secondsToTimeFormatted(durationInSeconds), [durationInSeconds]);

    return (
        <Box display="flex" justifyContent="space-between" marginTop="0.5rem">
            <IconedData
                icon={RouteOutlinedIcon}
                data={distance}
                tooltip={t('distance')}
                dataLabel={`${t('distance')}:`}
                dataLabelColor={COLORS.SLATE_GREY}
                iconRightMargin="0.25rem"
                fontSize="0.75rem"
                iconSize="1.25rem"
            />
            <IconedData
                icon={TimerOutlinedIcon}
                data={time}
                tooltip={t('duration')}
                dataLabel={`${t('duration')}:`}
                dataLabelColor={COLORS.SLATE_GREY}
                iconRightMargin="0.25rem"
                fontSize="0.75rem"
                iconSize="1.25rem"
            />
        </Box>
    );
};

export default RideSummary;
