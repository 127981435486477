import { FullScreenLoader } from '@get-e/react-components';
import React, { FunctionComponent } from 'react';

import { useCurrentProfileContext } from '../../../../context/CurrentProfileContext';
import { AccountType } from '../../../../services/types';
import { default as CustomerRides } from '../../customer/Rides';
import { default as SupplierRides } from '../../supplier/Rides';

const RidesPageSwitch: FunctionComponent = () => {
    const { currentProfile: { accountType } } = useCurrentProfileContext();

    switch (accountType) {
        case AccountType.CUSTOMER:
            return <CustomerRides />;
        case AccountType.SUPPLIER:
            return <SupplierRides />;
        default:
            return <FullScreenLoader />;
    }
};

export default RidesPageSwitch;
