import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CircleWrapper from '../../../components/circleWrapper/CircleWrapper';
import { COLORS } from '../../../constants/colors';
import { RideStatus } from '../../../helpers/rideStatusUtil';

const useStyles = makeStyles({
    heading: {
        marginBottom: '1rem',
        color: COLORS.BLUE_DARK,
        fontSize: '1rem',
        fontWeight: 700,
    },
    horizontalDivider: {
        margin: '1.5rem 0',
        height: '1px',
        border: 'none',
        background: COLORS.BLACK_12,
    },
});

interface DriverInformationProps {
    rideStatus: keyof typeof RideStatus;
    onClick?: () => void;
    name?: string;
    phone?: string;
    pickupDescription?: string;
}

const DriverInformation = ({ rideStatus, onClick, name = '', phone = '', pickupDescription = '' }: DriverInformationProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const isOnlyPickUpDescriptionAvailable = pickupDescription && !name && !phone;

    return (
        <Grid
            container
            style={{
                width: '100%',
                flexDirection: 'column',
                border: `1px solid ${COLORS.BLACK_12}`,
                borderRadius: '3px',
                marginTop: '2rem',
                marginBottom: '2rem',
            }}
        >
            <Grid item padding="1rem">
                {!isOnlyPickUpDescriptionAvailable && (
                    <Box key="section1" display="block">
                        <Typography variant="h3" component="h4" className={classes.heading}>
                            {t('pages.rides.driverInformation')}
                        </Typography>
                        {!(name || phone || pickupDescription) && <Typography style={{ color: COLORS.BLACK }}>
                            {t('pages.rides.descriptionInformation')}
                        </Typography>}
                    </Box>
                )}
                {(name || phone) && (
                    <Box key="section2">
                        <Grid
                            container
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                alignItems: 'center',
                                margin: '1rem 0',
                            }}
                        >
                            <Grid item sx={{ marginRight: '1rem' }}>
                                <CircleWrapper>
                                    <BadgeOutlinedIcon style={{ color: COLORS.WHITE }} />
                                </CircleWrapper>
                            </Grid>
                            <Grid item>
                                {name && (
                                    <Typography>
                                        <strong>{t('name')}:</strong> {name}
                                    </Typography>
                                )}
                                {phone && (
                                    <Typography>
                                        <strong>{t('form.fields.phoneNumber')}:</strong> {phone}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        {pickupDescription && <Divider className={classes.horizontalDivider} />}
                    </Box>
                )}
                {pickupDescription && rideStatus !== 'TO_CONFIRM' && (
                    <Box key="section3">
                        <Typography
                            variant="h3"
                            component="h4"
                            className={classes.heading}
                            style={{ color: isOnlyPickUpDescriptionAvailable ? COLORS.BLUE_DARK : COLORS.SLATE_GREY }}
                        >
                            {t('meetingInstructions')}
                        </Typography>
                        <Typography style={{ color: COLORS.BLACK }}>{pickupDescription}</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default DriverInformation;
