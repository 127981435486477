import AirlineSeatReclineNormalOutlinedIcon from '@mui/icons-material/AirlineSeatReclineNormalOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { Card, CardContent, Grid, Typography, Box, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconedData from '../../../components/iconedData/IconedData';
import { COLORS } from '../../../constants/colors';
import theme from '../../../styles/theme';

const useStyles = makeStyles({
    image: {
        height: '120px',
        width: '350px',
    },
    cardContainer: {
        marginBottom: '1rem',
        cursor: 'pointer',
        height: 'auto',
        margin: '0',
        overflow: 'hidden',
        transition: 'height 1.2s ease-in-out, margin 1.2s ease-in-out',
        '&:hover': {
            backgroundColor: COLORS.EXTRA_LIGHT_GRAY,
            boxShadow: '0px 12px 24px -4px rgba(148, 158, 171, 0.12), 0px 0px 2px rgba(148, 158, 171, 0.2)',
        },
        '& .MuiCardContent-root:last-child': { paddingBottom: '1rem' },
    },
    cardSelected: { backgroundColor: `${COLORS.EXTRA_LIGHT_GRAY} !important` },
    cardHidden: {
        height: 0,
        margin: 0,
        padding: 0,
        border: 'none',
    },
});

interface VehicleInformationCardProps {
    vehicleClass?: string;
    vehicleType?: string;
    vehicleImage?: string;
    travellers?: string;
    luggages?: string;
    price?: string;
    priceCurrency?: string;
    isVehicleSelected?: boolean;
    onCardClick?: () => void;
}

const VehicleInformationCard = ({
    vehicleClass = '',
    vehicleType = '',
    vehicleImage,
    travellers = '0',
    luggages = '0',
    price = '0',
    priceCurrency = 'eur',
    onCardClick,
    isVehicleSelected,
}: VehicleInformationCardProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Card
            variant="outlined"
            className={`${classes.cardContainer} ${isVehicleSelected ? classes.cardSelected : ''}`}
            onClick={onCardClick}
        >
            <CardContent sx={{ paddingBottom: '1rem' }}>
                <Grid container flex="row" justifyContent="space-between">
                    <Grid item xs={12} lg={6} md={6}>
                        <Typography
                            sx={{
                                color: COLORS.BLUE_DARK,
                                paddingBottom: '.6rem',
                                fontWeight: 700,
                            }}
                        >
                            {`${vehicleClass} ${vehicleType}`}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                            }}
                        >
                            <IconedData
                                icon={AirlineSeatReclineNormalOutlinedIcon}
                                data={travellers}
                                dataColor={COLORS.SLATE_GREY}
                                dataTextBold
                                tooltip={t('pages.singleRide.passengersCapacity')}
                            />
                            <IconedData
                                icon={WorkOutlineOutlinedIcon}
                                data={luggages}
                                dataColor={COLORS.SLATE_GREY}
                                dataTextBold
                                tooltip={t('pages.singleRide.luggagesCapacity')}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '2rem',
                            }}
                        >
                            <Typography
                                variant="h5"
                                style={{
                                    color: COLORS.ORANGE,
                                    fontWeight: 700,
                                    lineHeight: '1.25rem',
                                }}
                            >
                                {priceCurrency} {price}
                            </Typography>
                            <Typography
                                variant="caption"
                                style={{
                                    color: COLORS.SLATE_GREY,
                                    lineHeight: '.9rem',
                                    paddingTop: '.5rem',
                                }}
                            >
                                {t('pages.singleRide.priceDescription')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        md={6}
                        display="flex"
                        justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                    >
                        <img src={vehicleImage} alt="Vehicle" className={classes.image} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default VehicleInformationCard;
