/* eslint-disable no-case-declarations */
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PersonRemoveOutlined from '@mui/icons-material/PersonRemoveOutlined';
import { Typography, Box, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridRowParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownButtonMenu from '../../../components/dropdownButtonMenu/DropdownButtonMenu';
import { DropdownMenuOption } from '../../../components/dropdownMenu/DropdownMenu';
import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { BUTTON_WIDTH } from '../../../constants/layout';
import { useCurrentProfileContext } from '../../../context/CurrentProfileContext';
import theme from '../../../styles/theme';
import { User } from '../api/types';
import { ActiveTab } from '../Users';
import { UseUserColumnsProps } from './useUsersColumns';

const useStyles = makeStyles({
    mobileContent: {
        color: COLORS.BLACK,
        marginLeft: '.5rem',
    },
    editButton: {
        width: BUTTON_WIDTH,
        marginRight: '1rem',
        marginLeft: '0.2rem',
        color: COLORS.BLUE,
        border: `2px solid ${COLORS.BLUE}`,
    },
    deactivateButton: {
        width: BUTTON_WIDTH,
        color: COLORS.RED,
        border: `2px solid ${COLORS.RED}`,
    },
    container: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '1rem',
        borderBottom: `1px solid ${COLORS.BLACK_12}`,
    },
});

export const useUsersColumnsMobile = ({
    activeTab,
    handleDeactivate,
    handleReactivate,
    handleEdit,
    handleRevoke,
}: UseUserColumnsProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const { currentProfile } = useCurrentProfileContext();

    return useMemo(
        () =>
            ({ row }: GridRowParams) => {
                const getDropDown = () => {
                    switch (activeTab) {
                        case ActiveTab.Active:
                            const options: DropdownMenuOption[] = [];

                            const isDeactivateEnabled = handleDeactivate && (currentProfile.id).toString() !== (row.id).toString()
                                && !row.isDeactivated;

                            if (isDeactivateEnabled) {
                                options.push({
                                    id: 1,
                                    label: t('buttonName.deactivate'),
                                    icon: PersonRemoveOutlined,
                                    onClick: () => handleDeactivate && handleDeactivate(row as User),
                                });
                            }

                            return (
                                <DropdownButtonMenu
                                    buttonLabel="buttonName.edit"
                                    buttonIcon={<CreateOutlinedIcon />}
                                    onClick={() => handleEdit && handleEdit(row as User)}
                                    options={options}
                                />
                            );
                        case ActiveTab.Invited:
                            return (
                                <DropdownButtonMenu
                                    buttonLabel="pages.users.menu.revoke"
                                    buttonIcon={<PersonOffOutlinedIcon />}
                                    onClick={() => handleRevoke && handleRevoke(row as User)}
                                    options={[]}
                                />
                            );
                        case ActiveTab.Deactivated:
                            return (
                                <DropdownButtonMenu
                                    buttonLabel="buttonName.edit"
                                    buttonIcon={<CreateOutlinedIcon />}
                                    onClick={() => handleEdit && handleEdit(row as User)}
                                    options={[
                                        {
                                            id: 1,
                                            label: t('pages.users.menu.reactivate'),
                                            icon: PersonAddAlt1OutlinedIcon,
                                            onClick: () => handleReactivate && handleReactivate(row as User),
                                        },
                                    ]}
                                />
                            );
                        default:
                            return <></>;
                    }
                };

                if (!isMobile) {
                    return <></>;
                }

                return (
                    <Box className={classes.container} onClick={() => handleEdit && handleEdit(row as User)}>
                        <Typography marginBottom="0.25rem">
                            <strong style={{ color: COLORS.SLATE_GREY }}>{t('email')}: </strong> {row.email}
                        </Typography>
                        {activeTab !== ActiveTab.Invited && (
                            <Typography marginBottom="0.25rem">
                                <strong style={{ color: COLORS.SLATE_GREY }}>{t('pages.users.fields.lastSeen')}: </strong>
                                {row.lastSeen === null
                                    ? t('pages.users.lastSeenNever')
                                    : moment(row.lastSeen).format(DATE_FORMATS['DD MMM YYYY HH:mm'])}
                            </Typography>
                        )}
                        {!row.isDeactivated && (
                            <Typography>
                                <strong style={{ color: COLORS.SLATE_GREY }}> {t('pages.users.fields.permissions')}: </strong>
                                {row.permissions.manageUsers && t('users')} &nbsp;
                                {row.permissions.manageRidesBookedByOthers && t('rides')}
                            </Typography>
                        )}
                        <Box display="flex" justifyContent="flex-start" margin="1rem 0 0 0" color={COLORS.SLATE_GREY}>
                            {getDropDown()}
                        </Box>
                    </Box>
                );
            },
        [
            activeTab,
            classes.container,
            currentProfile.id,
            handleDeactivate,
            handleEdit,
            handleReactivate,
            handleRevoke,
            isMobile,
            t,
        ]
    );
};
