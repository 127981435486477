import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import { getPercentage } from '../../../../helpers/getPercentage';
import { getPercentageChange } from '../../../../helpers/getPercentageChange';
import { ReportTabProps } from '../api/types';
import { useDriversAddedPercentage } from '../hooks/useDriversAddedPercentage';
import { useTrackedDriversPercentage } from '../hooks/useTrackedDriversPercentage';
import SummaryBasicCard from '../SummaryBasicCard';
import SummaryListCard from '../SummaryListCard';

const useStyles = makeStyles({
    tabContentHeaderWrapper: {
        marginTop: '2rem',
        marginBottom: '1rem',
        padding: 0,
    },
    tabContentHeader: {
        fontWeight: 700,
        color: COLORS.BLUE_DARK,
    },
});

const SummaryTab = ({ currentPeriod, pastPeriod, period }: ReportTabProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const {
        driversAddedCurrentPeriodPercentage,
        driversAddedPreviousPeriodPercentage,
    } = useDriversAddedPercentage(currentPeriod, pastPeriod);

    const {
        trackedDriversCurrentPeriodPercentage,
        trackedDriversPreviousPeriodPercentage,
    } = useTrackedDriversPercentage(
        currentPeriod,
        pastPeriod
    );

    return (
        <>
            <Grid className={classes.tabContentHeaderWrapper}>
                <Typography className={classes.tabContentHeader}>{t('pages.reports.ridePerformances')}</Typography>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.totalRides')}
                            percentage1={currentPeriod?.summary.totalRides.toString() ?? ''}
                            percentage2={getPercentageChange(
                                currentPeriod?.summary.totalRides ?? 0,
                                pastPeriod?.summary.totalRides ?? 0,
                                0
                            ).toString()}
                            text1={`${pastPeriod?.summary.totalRides.toString() ?? ''} ${t('inPreviousPeriod')}`}
                            text2={period}
                            titleTooltip={t('pages.reports.totalRidesTooltip')}
                            excludePercentage1
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.rateOfSituations')}
                            percentage1={getPercentage(
                                currentPeriod?.summary.situations.totalSituations ?? 0,
                                currentPeriod?.summary.totalRides ?? 0
                            ).toString()}
                            text1={`${getPercentage(
                                pastPeriod?.summary.situations.totalSituations ?? 0,
                                pastPeriod?.summary.totalRides ?? 0
                            ).toString()}% ${t('inPreviousPeriod')}`}
                            text2={period}
                            titleTooltip={t('pages.reports.rateOfSituationsTooltip')}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryListCard
                            title={t('pages.reports.situationsByType')}
                            driverLate={currentPeriod?.summary.situations.totalDriverLate}
                            driverNoShow={currentPeriod?.summary.situations.totalDriverNoShow}
                            vehicleCondition={currentPeriod?.summary.situations.totalVehicleCondition}
                            serviceQuality={currentPeriod?.summary.situations.totalServiceQuality}
                            safetyIncident={currentPeriod?.summary.situations.totalSafetyIncident}
                            onRouteDelay={currentPeriod?.summary.situations.totalOnRouteDelay}
                            wrongVehicle={currentPeriod?.summary.situations.totalWrongVehicle}
                            other={currentPeriod?.summary.situations.totalOther}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.tabContentHeaderWrapper}>
                <Typography className={classes.tabContentHeader}>{t('pages.reports.communicationsWithCustomers')}</Typography>
                <Grid container columnSpacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.driversAssigned')}
                            percentage1={driversAddedCurrentPeriodPercentage}
                            text1={`${driversAddedPreviousPeriodPercentage}% ${t('inPreviousPeriod')}`}
                            percentage2={(Number(driversAddedCurrentPeriodPercentage)
                                - Number(driversAddedPreviousPeriodPercentage)).toString()}
                            text2={period}
                            titleTooltip={t('pages.reports.driversAssignedTooltip')}
                            includeTotalRidesText
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.ridesTrackedAccurately')}
                            percentage1={trackedDriversCurrentPeriodPercentage}
                            percentage2={(Number(trackedDriversCurrentPeriodPercentage)
                                    - Number(trackedDriversPreviousPeriodPercentage)).toString()}
                            text1={`${trackedDriversPreviousPeriodPercentage}% ${t('inPreviousPeriod')}`}
                            text2={period}
                            titleTooltip={t('pages.reports.ridesTrackedAccuratelyTooltip')}
                            includeTotalRidesText
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <SummaryBasicCard
                            title={t('pages.reports.averageRatingScore')}
                            rating={Math.round((currentPeriod?.summary.reviews.totalRate ?? 0) * 100) / 100}
                            numberOfReviews={Math.round((currentPeriod?.summary.reviews.totalReviews ?? 0) * 100) / 100}
                            text1={`${pastPeriod?.summary.reviews.totalReviews.toString() ?? ''} ${t('inPreviousPeriod')}`}
                            text2={period}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default SummaryTab;
