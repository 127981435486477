import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../components/modal/Modal';
import { RideResponse } from '../../../ride/api/types';
import ReferenceCard from '../../../ride/supplier/components/ReferenceCard';
import useConfirmCloseAddRemarksRide from '../../../ride/supplier/hooks/useConfirmCloseAddRemarksRide';
import { RemarksCard, RemarksValues, initialRemarks } from './RemarksCard';

export interface ReferencesAndremarksData {
    referenceNumber: string;
    minutesWaiting: string;
    minutesCosts: string;
    parkingCosts: string;
    otherCosts: string;
    remarks: string;
}

interface ViewRideRemarksModalProps {
    rideId: string;
    isModalOpen: boolean;
    onClose: () => void;
    onFinished: (updatedRide?: RideResponse) => void;
    supplierReference?: string;
}

const ReferencesAndRemarksModal = ({
    rideId,
    isModalOpen,
    onClose,
    onFinished,
    supplierReference,
}: ViewRideRemarksModalProps) => {
    const { t } = useTranslation();
    const [reference, setReference] = useState<string | null>(supplierReference ?? null);
    const [remarksValues, setRemarksValues] = useState<RemarksValues>(initialRemarks);

    const { onAddRideRemarks, isSaving } = useConfirmCloseAddRemarksRide({
        rideId,
        reference,
        remarksValues,
    });

    const handleSubmit = async () => {
        const data = await onAddRideRemarks();

        onClose();
        onFinished(data);
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={t('modals.referencesAndRemarks.addReferencesAndRemarks')}
            confirmButtonLabel={t('buttonName.add')}
            cancelButtonLabel={t('buttonName.back')}
            isDisabled={isSaving}
            maxWidth="md"
            fullWidth
        >
            <Grid container flexDirection="column">
                <Grid item>
                    <ReferenceCard
                        isVisible
                        reference={reference}
                        setReference={setReference}
                    />
                </Grid>
                <Grid item>
                    <RemarksCard
                        remarksValues={remarksValues}
                        setRemarksValues={setRemarksValues}
                        isAdditionalButtonHidden
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ReferencesAndRemarksModal;
