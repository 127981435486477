export const BASE_URL_PATH = '/';

export const AVAILABILITY = '/availability';

export const REPORTS = '/reports';
export const REPORTS_SUMMARY = `${REPORTS}/summary`;
export const REPORTS_SITUATIONS = `${REPORTS}/situations`;
export const REPORTS_DRIVER_ANALYSIS = `${REPORTS}/driverAnalysis`;
export const REPORTS_REVIEWS = `${REPORTS}/reviews`;

export const LOG_IN = '/log-in';
export const LOG_IN_NEW = '/portal/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const ACCEPT_INVITE = '/accept-invite';

export const USERS = '/users';

export const RIDES = '/rides';
export const UPDATE_RIDE = '/update/ride';

export const EXPORT = '/export';

export const DRIVERS = '/drivers';
export const VEHICLES = '/vehicles';

export const BOOKING_TOOL = '/booking-tool';

export const DOCUMENTS = '/documents';

export const INVOICES = '/invoices';

export const SETTINGS = '/settings';

export const getSingleRideRoute = (id: string) => `${RIDES}/${id}`;
export const getUpdateRideRoute = (id: string) => `${UPDATE_RIDE}/${id}`;

export const AUTHENTICATE = '/authenticate';
export const INVALID_AUTH_CODE = '/invalid-auth-code';
export const INVALID_COOKIE = '/invalid-cookie';
export const INVALID_INVITE_TOKEN = '/invalid-invite-token';
export const EXPIRED_INVITE_TOKEN = '/expired-invite-token';
export const INVALID_PASSWORD_TOKEN = '/invalid-password-token';
export const EXPIRED_PASSWORD_TOKEN = '/expired-password-token';

