import {
    Box,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatInTimeZone } from 'date-fns-tz';
import React from 'react';
import { Link } from 'react-router-dom';

import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { RIDES } from '../../../constants/urlPaths';
import { TABLE_ROW_HEIGHT } from '../../../styles/theme';
import { getStatusIcon } from '../helpers';
import { DriverRide } from './api/types';

const useStyles = makeStyles({
    tableRow: {
        height: `${TABLE_ROW_HEIGHT}px`,
        transition: '150ms all ease-in-out',
    },
    tableCell: {
        color: COLORS.BLACK,
        background: COLORS.EXTRA_LIGHT_GRAY,
    },
});

interface DetailsRowProps {
    ride: DriverRide;
}

const DetailsRow = ({ ride }: DetailsRowProps) => {
    const classes = useStyles();

    return (
        <>
            <TableRow
                key={ride.rideNumber}
                className={classes.tableRow}
                hover
            >
                <TableCell
                    className={classes.tableCell}
                    sx={{ padding: '0.5rem' }}
                >
                    {formatInTimeZone(ride.pickupTime, 'UTC', DATE_FORMATS['d MMM yyyy at p'])}
                </TableCell>
                <TableCell
                    className={classes.tableCell}
                    sx={{ padding: '0.5rem' }}
                >
                    <Link to={`${RIDES}/${ride.rideNumber.replace(/-/g, '')}`} target="_blank">
                        <Typography color={COLORS.BLUE}>{ride.rideNumber}</Typography>
                    </Link>
                </TableCell>
                <TableCell
                    className={classes.tableCell}
                    sx={{ padding: '0.5rem 0.5rem 0.5rem 0' }}
                >
                    <Box display="flex" alignItems="center">
                        {getStatusIcon(ride.driverUpdates)}
                        {ride.driverUpdates}
                    </Box>
                </TableCell>
                <TableCell
                    className={classes.tableCell}
                    sx={{ padding: '0.5rem 0.5rem 0.5rem 0' }}
                >
                    <Box display="flex" alignItems="center">
                        {getStatusIcon(ride.gpsTracking)}
                        {ride.gpsTracking}
                    </Box>
                </TableCell>
            </TableRow>
        </>
    );
};

export default DetailsRow;
