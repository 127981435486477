import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';
import { Box, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GridRowParams } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownButtonMenu from '../../../components/dropdownButtonMenu/DropdownButtonMenu';
import LabeledData from '../../../components/labeledData/LabeledData';
import { COLORS } from '../../../constants/colors';
import theme from '../../../styles/theme';
import { Driver } from '../api/types';

const useStyles = makeStyles({
    mobileContent: {
        color: COLORS.BLACK,
        marginLeft: '.5rem',
    },
    editButton: {
        width: '100px',
        marginRight: '1rem',
        marginLeft: '0.2rem',
        color: COLORS.BLUE,
        border: `2px solid ${COLORS.BLUE}`,
        background: COLORS.WHITE,
    },
    toggleStatusButton: {
        width: '100px',
        color: COLORS.RED,
        border: `2px solid ${COLORS.RED}`,
        background: COLORS.WHITE,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '1rem .5rem 1rem .5rem',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
});

export const useDriversColumnsMobile = (onToggleStatus: (driver: Driver) => void, onEdit: (driver: Driver) => void) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return useMemo(
        () =>
            ({ row }: GridRowParams) => {
                const driver = row as Driver;

                if (!isMobile) {
                    return <></>;
                }

                return (
                    <Box className={classes.container}>
                        <LabeledData
                            label={`${t('phone')}:`}
                            data={driver.phoneNumber}
                            labelColor={COLORS.SLATE_GREY}
                            ignoreColumns
                            paddingBottom="0"
                        />
                        <LabeledData
                            label={`${t('pages.drivers.rideAssigned')}:`}
                            data={driver.tripsCount?.toString() ?? '-'}
                            labelColor={COLORS.SLATE_GREY}
                            ignoreColumns
                            paddingBottom="0"
                        />
                        <LabeledData
                            label={`${t('status')}:`}
                            data={t(driver.isDeactivated ? 'deactivated' : 'active')}
                            labelColor={COLORS.SLATE_GREY}
                            ignoreColumns
                            paddingBottom="0"
                        />
                        <Box display="flex" justifyContent="flex-start" margin="1rem 0 0 0" color={COLORS.SLATE_GREY}>
                            <DropdownButtonMenu
                                buttonLabel="buttonName.edit"
                                buttonIcon={<CreateOutlinedIcon />}
                                onClick={() => onEdit(driver)}
                                options={[
                                    {
                                        id: 1,
                                        label: t(driver.isDeactivated ? 'buttonName.activate' : 'buttonName.deactivate'),
                                        icon: driver.isDeactivated ? PersonAddAlt1OutlinedIcon : PersonRemoveAlt1OutlinedIcon,
                                        onClick: () => onToggleStatus(driver),
                                    },
                                ]}
                            />
                        </Box>
                    </Box>
                );
            },
        [isMobile, classes.container, t, onEdit, onToggleStatus]
    );
};
