// eslint-disable @typescript-eslint/no-unsafe-return
import { Grid } from '@mui/material';
import React, { useContext, useMemo } from 'react';

import GoogleMap from '../../../../components/navigation/GoogleMap';
import { COLORS } from '../../../../constants/colors';
import { NewRideContext } from '../context/RideContext';

const RideMap = () => {
    const {
        summary: {
            pickupCoordinates,
            dropOffCoordinates,
            pickUpPlaceId,
            dropOffPlaceId,
            prettifiedUnid,
            lastPositionUpdate,
        },
    } = useContext(NewRideContext);

    const points = useMemo(() => {
        return [pickupCoordinates, dropOffCoordinates];
    }, [dropOffCoordinates, pickupCoordinates]);

    return (
        <Grid
            container
            style={{
                width: '100%',
                flexDirection: 'column',
                border: `1px solid ${COLORS.BLACK_12}`,
                borderRadius: '3px',
            }}
        >
            <Grid
                container
                style={{
                    width: '100%',
                    flexDirection: 'column',
                }}
            >
                <Grid
                    item
                    style={{
                        height: '200px',
                        background: COLORS.EXTRA_LIGHT_GRAY,
                    }}
                >
                    <GoogleMap
                        points={points}
                        placeIds={[pickUpPlaceId ?? '', dropOffPlaceId ?? '']}
                        subscribeChannelId={`private-trip-${prettifiedUnid}`}
                        driverPosition={lastPositionUpdate}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default RideMap;
