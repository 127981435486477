import { Grid, useMediaQuery } from '@mui/material';
import {
    DataGridPro,
    useGridApiRef,
    GridRowId,
    GridEventListener,
    GridColDef,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import useDataGridStyles from '../../../../styles/DataGrid';
import theme from '../../../../styles/theme';
import { FeedbackTopics } from '../../../ride/api/types';
import { ReportTabProps } from '../api/types';
import AverageRatingDistributionCard from '../AverageRatingDistributionCard';
import AverageRatingTopicCard from '../AverageRatingTopicCard';
import { useReviewsColumns } from '../hooks/useReviewsColumns';
import { useReviewsDetailsContent } from '../hooks/useReviewsDetailsContent';
import SummaryBasicCard from '../SummaryBasicCard';

export const getTopicLabel = (topic: keyof typeof FeedbackTopics, t: TFunction) => {
    switch (topic) {
        case FeedbackTopics.SERVICE_RATE:
            return t('pages.reports.overallService');
        case FeedbackTopics.DRIVER_RATE:
            return t('driver');
        case FeedbackTopics.MEETING_DRIVER_RATE:
            return t('pages.reports.meetingTheDriver');
        case FeedbackTopics.VEHICLE_RATE:
            return t('vehicle');
        default:
            return topic;
    }
};

const ReviewsTab = ({ currentPeriod, pastPeriod, period }: ReportTabProps) => {
    const { t } = useTranslation();
    const dataGridClases = useDataGridStyles();
    const gridApiRef = useGridApiRef();
    const columns = useReviewsColumns();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const rows = useMemo(() => currentPeriod?.reviews || [], [currentPeriod?.reviews]);

    const onRowClick = useCallback<GridEventListener<'rowClick'>>(
        (params: { id: GridRowId }) => {
            gridApiRef.current.toggleDetailPanel(params.id);
        },
        [gridApiRef]
    );

    const averageRateByTopic = currentPeriod
        ? Object.entries(currentPeriod?.summary.reviews.averageRateByTopic)
            .map(([key, value]) => {
                return {
                    label: getTopicLabel(key as keyof typeof FeedbackTopics, t),
                    rate: value,
                };
            })
        : null;

    const columnVisibilityModel = useMemo(() => {
        return {
            rideNumber: !isMobile,
            driver: !isMobile,
            averageRating: !isMobile,
        };
    }, [isMobile]);

    return (
        <Grid container width="100%">
            <Grid container columnSpacing={3} marginTop={2}>
                <Grid item xs={12} md={4} lg={4}>
                    <SummaryBasicCard
                        title={t('pages.reports.averageRatingScore')}
                        rating={Math.round((currentPeriod?.summary.reviews.totalRate ?? 0) * 100) / 100}
                        numberOfReviews={Math.round((currentPeriod?.summary.reviews.totalReviews ?? 0) * 100) / 100}
                        text1={`${pastPeriod?.summary.reviews.totalReviews.toString() ?? ''} ${t('inPreviousPeriod')}`}
                        text2={period}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <AverageRatingDistributionCard
                        title={t('pages.reports.averageRatingDistribution')}
                        stars5={currentPeriod?.summary.reviews.totalFiveStar}
                        stars4={currentPeriod?.summary.reviews.totalFourStar}
                        stars3={currentPeriod?.summary.reviews.totalThreeStar}
                        stars2={currentPeriod?.summary.reviews.totalTwoStar}
                        stars1={currentPeriod?.summary.reviews.totalOneStar}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <AverageRatingTopicCard ratingsByTopic={averageRateByTopic} />
                </Grid>
            </Grid>
            <DataGridPro
                apiRef={gridApiRef}
                className={dataGridClases.dataGrid}
                autoHeight
                hideFooter
                disableColumnSelector
                disableColumnFilter
                rows={rows}
                columns={columns as GridColDef[]}
                getDetailPanelContent={useReviewsDetailsContent()}
                getDetailPanelHeight={() => 'auto'}
                onRowClick={onRowClick}
                columnVisibilityModel={columnVisibilityModel}
            />
        </Grid>
    );
};

export default ReviewsTab;
