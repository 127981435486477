/* eslint-disable  prefer-named-capture-group */
/* eslint-disable @typescript-eslint/prefer-regexp-exec */
/* eslint-disable no-trailing-spaces */
import { useLocation } from 'react-router-dom';

const useEmailFromUrlQuery = (): string => {
    const location = useLocation();
    
    // This works for all characters that can be in email !#$%&'*+-/=?^_`{|}~, expect # and %
    const emailRegex = /[?&]email=([^&#%]+)/;
    const emailMatch = location.search.match(emailRegex);
    const email = emailMatch ? decodeURIComponent(emailMatch[1]) : '';

    return email;
};

export default useEmailFromUrlQuery;
