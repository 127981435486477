/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/indent */
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../constants/colors";

const settingsStyles = makeStyles(styleTheme => ({
  mainWrapper: {
    alignItems: "center",
    padding: 0,
  },
  mainWrapperTopPadding: { paddingTop: "3.5rem" },
  headerTitleWrapper: {
    marginBottom: "2rem",
    marginTop: "1rem",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    [styleTheme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      marginTop: "2rem",
    },
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "auto",
    [styleTheme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "0.5rem",
    },
  },
  label: {
    color: COLORS.BLUE_DARK,
    fontWeight: "700",
  },
  labelDescription: { color: COLORS.SLATE_GREY },
}));

export default settingsStyles;
