/* eslint-disable @typescript-eslint/no-explicit-any */
import amplitude from 'amplitude-js';

import config from '../config';

export const initAmplitude = () => {
    amplitude.getInstance().init(config.amplitudeApi ?? '');
};

export const setAmplitudeUserId = (userId: string) => {
    amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties: any) => {
    amplitude.getInstance().setUserProperties(properties);
};

export const logAmplitudeEvent = (event: any, eventProperties = {}) => {
    amplitude.getInstance().logEvent(event, eventProperties);
};
