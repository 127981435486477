import { TextField, MultipleSelect } from '@get-e/react-components';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { InputAdornment, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/modal/Modal';
import { USER_ROLES } from '../../../constants';
import { COLORS } from '../../../constants/colors';
import { STATUS_422 } from '../../../constants/httpStatusCodes';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { InputError } from '../../../helpers/inputValidation/InputError';
import and from '../../../helpers/inputValidation/validators/and';
import isEmail from '../../../helpers/inputValidation/validators/isEmail';
import isFilledString from '../../../helpers/inputValidation/validators/isFilledString';
import { inviteUser } from '../api';
import { InviteUserRequest, UserPermissions, UserRoles, ErrorResponse } from '../api/types';
import userModalStyles from './UserModal.styles';

interface AddUserFields {
    firstName: string;
    lastName: string;
    email: string;
    permissions: Omit<UserPermissions, UserRoles.SEE_INVOICES>;
}

interface AddUserErrors {
    firstName: InputError | null;
    lastName: InputError | null;
    email: InputError | null;
}

interface AddUserModalProps {
    onInviteUser: () => void;
    onClose: () => void;
    isOpen: boolean;
}

const AddUserModal = ({ onInviteUser, onClose, isOpen }: AddUserModalProps) => {
    const classes = userModalStyles();
    const { t } = useTranslation();
    const [isSaving, setIsSaving] = useState(false);
    const [roleIds, setRoleIds] = useState<string[]>([]);
    const { showNotification } = useNotificationContext();

    const [values, setValues] = useState<AddUserFields>({
        firstName: '',
        lastName: '',
        email: '',
        permissions: {
            [UserRoles.MANAGE_USERS]: false,
            [UserRoles.MANAGE_RIDES_BOOKED_BY_OTHERS]: false,
        },
    });

    const [formErrors, setFormErrors] = useState<AddUserErrors>({
        firstName: null,
        lastName: null,
        email: null,
    });

    const roleMapValues = new Map(
        [
            [USER_ROLES.MANAGE_USERS, t('pages.users.fields.manageUsers')],
            [USER_ROLES.MANAGE_RIDES_BOOKED_BY_OTHERS, `${t('pages.users.fields.manageRidesBookedByOthers')}`],
        ]
    );

    const defaultRoleValues = useMemo(() => {
        const result: string[] = [];

        for (const [key, value] of Object.entries(values.permissions)) {
            value && result.push(t(`pages.users.fields.${key}`));
        }

        return result;
    }, [t, values.permissions]);

    const onSetIds = (ids: string[]) => {
        setRoleIds(ids);

        const permissionValues = Array.from(roleMapValues.keys()).reduce(
            (acc, key) =>
                Object.assign(acc, { [UserRoles[key as keyof typeof UserRoles]]: ids.includes(key) }),
            {}
        );

        const newValues = {
            ...values,
            permissions: permissionValues as UserPermissions,
        };

        setValues(newValues);
    };

    const validateFields = (): boolean => {
        const validated = {
            firstName: isFilledString(values.firstName, InputError.Empty),
            lastName: isFilledString(values.lastName, InputError.Empty),
            email: and(isFilledString(values.email, InputError.Empty), () => isEmail(values.email, InputError.InvalidEmail)),
        };

        const fieldErrors: AddUserErrors = {
            firstName: validated.firstName.isValid ? null : validated.firstName.error,
            lastName: validated.lastName.isValid ? null : validated.lastName.error,
            email: validated.email.isValid ? null : validated.email.error,
        };

        const isValid = Object.values(fieldErrors).every(error => error === null);

        !isValid && setFormErrors(fieldErrors);

        return isValid;
    };

    const handleSubmit = async (): Promise<void> => {
        if (!validateFields()) {
            return;
        }

        const payload: InviteUserRequest = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            permissions: values.permissions,
        };

        try {
            setIsSaving(true);
            await inviteUser(payload);
            onInviteUser();
            onClose();
        } catch (error) {
            handleInviteUserError(error as AxiosError);
        } finally {
            setIsSaving(false);
        }
    };

    const handleInviteUserError = ({ response }: AxiosError) => {
        if (response?.status === STATUS_422) {
            const errorMessage = response?.data as ErrorResponse;

            if (errorMessage.message === t(InputError.EmailAlreadyTaken)) {
                const fieldErrors: AddUserErrors = {
                    firstName: null,
                    lastName: null,
                    email: InputError.EmailAlreadyTaken,
                };

                setFormErrors(fieldErrors);
            }

            return;
        }

        showNotification(t('errors.defaultError'), Severity.Error);
    };

    const handleChange = <T extends keyof AddUserFields>(key: T, newValue: AddUserFields[T] & (string | boolean)): void => {
        setValues({
            ...values,
            [key]: newValue,
        });

        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [key]: null,
        }));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={t('pages.users.inviteUser')}
            confirmButtonLabel={t('buttonName.invite')}
            cancelButtonLabel={t('buttonName.cancel')}
            isDisabled={isSaving}
        >
            <Typography
                style={{
                    color: COLORS.BLUE_DARK,
                    fontSize: '1rem',
                    fontWeight: 700,
                    marginBottom: '1rem',
                }}
            >
                {t('pages.users.fields.profileInformation')}
            </Typography>
            <TextField
                className={classes.formField}
                error={formErrors.firstName !== null}
                helperText={formErrors.firstName ? t(formErrors.firstName) : null}
                label={t('form.fields.firstName')}
                name="firstName"
                onChange={event => handleChange('firstName', event.target.value)}
                required
                autoComplete="off"
                value={values.firstName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
                disabled={isSaving}
            />
            <TextField
                className={classes.formField}
                error={formErrors.lastName !== null}
                helperText={formErrors.lastName ? t(formErrors.lastName) : null}
                label={t('form.fields.lastName')}
                name="lastName"
                onChange={event => handleChange('lastName', event.target.value)}
                required
                autoComplete="off"
                value={values.lastName}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
                disabled={isSaving}
            />
            <TextField
                className={classes.formField}
                error={formErrors.email !== null}
                helperText={formErrors.email ? t(formErrors.email) : null}
                label={t('email')}
                name="email"
                onChange={event => handleChange('email', event.target.value)}
                required
                autoComplete="off"
                value={values.email}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <EmailOutlinedIcon />
                        </InputAdornment>
                    ),
                }}
                disabled={isSaving}
            />
            <Typography
                style={{
                    color: COLORS.BLUE_DARK,
                    fontSize: '1rem',
                    fontWeight: 700,
                    marginBottom: '1rem',
                }}
            >
                {t('pages.users.fields.userPermissions')}
            </Typography>
            <MultipleSelect
                label={t('pages.users.fields.userPermissions')}
                value={roleIds}
                values={roleMapValues}
                onSetIds={onSetIds}
                defaultSelectedValue={defaultRoleValues}
                classNames={{ item: classes.multipleSelectItem }}
                disabled={isSaving}
            />
        </Modal>
    );
};

export default AddUserModal;
