import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Grid, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CopyToClipboardButton from '../../../../components/buttons/CopyToClipboardButton';
import { IconedLabel } from '../../../../components/iconedLabel/IconedLabel';
import { COLORS } from '../../../../constants/colors';
import CellText from '../../components/CellText';
import StatusBadge from '../../components/StatusBadge';
import { RideRow } from '../api/types';
import { ChangeStatusButton } from '../hooks/components/ChangeStatusButton';
import { FlightInfo } from '../hooks/components/FlightInfo';
import { RideInformationCell } from '../hooks/components/RideInformationCell';
import { RideChangeStatusOptionsMap } from '../hooks/ridesChangeStatusUtil';

interface MobileListProps {
    data: RideRow[];
    handleChangeStatusButtonClick: (rideId: string, optionsMap: RideChangeStatusOptionsMap) => void;
    handleReferenceAndRemarks: (data: RideRow) => void;
    handleDownloadRideOrder: (id: string) => void;
    handleRemoveDriver: (rideId: string, driverId: string) => void;
    handleDriverAndVehicle: (rideId: string) => void;
    handleRowClick: (row: RideRow, event?: React.MouseEvent<HTMLElement>) => void;
}

const MobileList = ({
    data,
    handleChangeStatusButtonClick,
    handleReferenceAndRemarks,
    handleDownloadRideOrder,
    handleRemoveDriver,
    handleDriverAndVehicle,
    handleRowClick,
}: MobileListProps) => {
    const { t } = useTranslation();
    const previousPickupDate = useRef<string | null>(null);
    const groupingDate = useRef<string | null>(null);

    if (!data) {
        return null;
    }

    return (
        <>
            {data?.map((rideItem: RideRow) => {
                if (rideItem.pickupDate !== previousPickupDate.current) {
                    previousPickupDate.current = rideItem.pickupDate;
                    groupingDate.current = rideItem.pickupDate;
                } else {
                    groupingDate.current = null;
                }

                return (
                    <Box key={rideItem.id}>
                        {groupingDate.current && (
                            <Typography color={COLORS.BLUE_DARK} fontWeight={700} paddingBottom="1rem">
                                {rideItem.pickupDate}
                            </Typography>
                        )}
                        <Grid
                            container
                            key={rideItem.id}
                            padding="1rem"
                            border={`1px solid ${COLORS.BLACK_12}`}
                            borderRadius="4px"
                            marginBottom="1rem"
                            onClick={() => handleRowClick(rideItem)}
                        >
                            <Grid
                                item
                                display="flex"
                                width="100%"
                                alignItems="center"
                                justifyContent="space-between"
                                paddingBottom="1rem"
                                borderBottom={`1px solid ${COLORS.BLACK_12}`}
                            >
                                <Box display="flex" flexDirection="column" justifyContent="space-between">
                                    <Typography color={COLORS.BLUE_DARK} fontWeight={700}>
                                        {rideItem.pickupTime}
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            color={COLORS.SLATE_GREY}
                                            fontWeight={700}
                                            fontSize="1.25rem"
                                            marginRight="0.5rem"
                                        >
                                            {rideItem.rideNumber}
                                        </Typography>
                                        <CopyToClipboardButton text={rideItem.rideNumber} size="1rem" padding="0" />
                                    </Box>
                                </Box>
                                <StatusBadge
                                    status={rideItem.status}
                                    trackingStatus={rideItem.trackingStatus}
                                    pickupDateTimeUtc={rideItem.originalPickupUtc}
                                />
                            </Grid>
                            <Grid item>
                                <CellText marginBottom="0.5rem" marginTop="1rem">
                                    <IconedLabel
                                        icon={LocationOnOutlinedIcon}
                                        label={t('from')}
                                        dataLabel={rideItem.pickUp.location.name}
                                    />
                                </CellText>
                                <CellText>
                                    <IconedLabel
                                        icon={LocationOnOutlinedIcon}
                                        label={t('To')}
                                        dataLabel={rideItem.dropOff.location.name}
                                    />
                                </CellText>
                                <CellText marginBottom=".25rem">
                                    <FlightInfo row={rideItem} />
                                </CellText>
                                <RideInformationCell row={rideItem} />
                                <CellText marginBottom=".25rem">
                                    <Box display="flex" marginBottom=".5rem">
                                        <IconedLabel
                                            icon={BadgeOutlinedIcon}
                                            dataLabel={rideItem.driver?.name ?? t('notAssigned')}
                                            suffix=""
                                        />
                                    </Box>
                                    <Box display="flex">
                                        <IconedLabel
                                            icon={DirectionsCarOutlinedIcon}
                                            label={t('model')}
                                            dataLabel={rideItem.vehicleType}
                                            alignToTop
                                        />
                                    </Box>
                                </CellText>
                                {rideItem.supplierReference && (
                                    <CellText marginBottom=".5rem" marginTop=".25rem">
                                        <strong style={{ color: COLORS.SLATE_GREY }}>
                                            {t('reference')}</strong>: {rideItem.supplierReference}
                                    </CellText>
                                )}
                                <CellText marginBottom="1rem">
                                    <strong style={{ color: COLORS.SLATE_GREY }}>
                                        {t('price')}
                                    </strong>: <span style={{ color: COLORS.BLUE_DARK }}>{rideItem.price}</span>
                                </CellText>
                                <ChangeStatusButton
                                    row={rideItem}
                                    handleChangeStatusButtonClick={handleChangeStatusButtonClick}
                                    handleReferenceAndRemarks={handleReferenceAndRemarks}
                                    handleDownloadRideOrder={handleDownloadRideOrder}
                                    handleRemoveDriver={handleRemoveDriver}
                                    handleDriverAndVehicle={handleDriverAndVehicle}
                                    isMobile={true}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                );
            })}
        </>
    );
};

export default React.memo(MobileList);
