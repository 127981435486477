export interface Driver {
    id: number;
    name: string;
    phoneNumber: string;
    phoneOs?: string | null;
    tripsCount?: number | null;
    isDeactivated?: boolean;
    feedbacks?: DriverFeedbacks;
    pictureUrl?: string | null;
}

export interface DriverFeedbacks {
    averageRate: number | null;
    count: number;
}

export enum ChangeStatus {
    EDIT_DRIVER = 'EDIT_DRIVER',
    DEACTIVATE_DRIVER = 'DEACTIVATE_DRIVER',
    ACTIVATE_DRIVER = 'ACTIVATE_DRIVER'
}

export type EditDriverRequest = Pick<Driver, 'id' | 'name' | 'phoneNumber' | 'pictureUrl'>;

export type AddDriverRequest = Pick<Driver, 'name' | 'phoneNumber' | 'pictureUrl'>;
