import { Dispatch, SetStateAction, useState } from 'react';

import { InputError } from '../../../../helpers/inputValidation/InputError';
import allValid from '../../../../helpers/inputValidation/validators/allValid';
import and from '../../../../helpers/inputValidation/validators/and';
import isAfterTime from '../../../../helpers/inputValidation/validators/isAfterTime';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import isNotNull from '../../../../helpers/inputValidation/validators/isNotNull';
import isPastDate from '../../../../helpers/inputValidation/validators/isPastDate';
import { initialRide, initialRideErrors, Ride, RideError } from '../../components/RideInformation';

const useRideInformation = (isUtc: boolean): {
    rideValues: Ride;
    setRideValues: Dispatch<SetStateAction<Ride>>;
    rideErrors: RideError;
    setRideErrors: Dispatch<SetStateAction<RideError>>;
    validateRideFields: () => boolean;
} => {
    const [errors, setErrors] = useState<RideError>(initialRideErrors);
    const [rideValues, setRideValues] = useState<Ride>(initialRide);

    const validateFields = () => {
        const pickupDate = isUtc ? rideValues.pickUpDate : rideValues.pickUpDateLocal;
        const pickupTime = isUtc ? rideValues.pickUpTime : rideValues.pickUpTimeLocal;

        const validated = {
            pickUpDate: and(
                isNotNull(pickupDate, InputError.Empty),
                () => isPastDate(pickupDate, InputError.InvalidPastDate),
            ),
            pickUpTime: and(
                isNotNull(pickupTime, InputError.Empty),
                () => isAfterTime(pickupTime, new Date(), InputError.InvalidPastTime),
            ),
            travellers: isFilledString(rideValues.travellers, InputError.Empty),
            luggages: isFilledString(rideValues.luggages, InputError.Empty),
            pickUpLocation: isNotNull(rideValues.pickUpLocation, InputError.Empty),
            dropOffLocation: isNotNull(rideValues.dropOffLocation, InputError.Empty),
        };

        const validatedCustomFields = {};
        const validatedCustomFieldsErrors = {};

        rideValues.customFields.forEach(customField => {
            const validation = isFilledString(customField.value, InputError.Empty);

            if (customField.isRequired) {
                validatedCustomFields[customField.key] = validation;
                validatedCustomFieldsErrors[customField.key] = validation.isValid ? null : validation.error;
            }
        });

        if (!allValid(validated) || !allValid(validatedCustomFields)) {
            const fieldErrors = {
                pickUpDate: validated.pickUpDate.isValid ? null : validated.pickUpDate.error,
                pickUpTime: validated.pickUpTime.isValid ? null : validated.pickUpTime.error,
                travellers: validated.travellers.isValid ? null : validated.travellers.error,
                luggages: validated.luggages.isValid ? null : validated.luggages.error,
                pickUpLocation: validated.pickUpLocation.isValid ? null : validated.pickUpLocation.error,
                dropOffLocation: validated.dropOffLocation.isValid ? null : validated.dropOffLocation.error,
                customFields: validatedCustomFieldsErrors,
            };

            setErrors(fieldErrors);
            return false;
        }

        return true;
    };

    return {
        rideValues,
        setRideValues,
        rideErrors: errors,
        setRideErrors: setErrors,
        validateRideFields: validateFields,
    };
};

export default useRideInformation;
