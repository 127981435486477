import { EstimationDetails, RideResponse } from '../../pages/ride/api/types';
import { Ride } from '../../pages/ride/components/RideInformation';
import { SummaryRideType } from '../../pages/ride/customer/context/RideContext';
import getScheduledArrival from '../getScheduledArrival';
import metersToKmAndMiFormatted from '../metersToKmAndMiFormatted';
import secondsToTimeFormatted from '../secondsToTimeFormatted';

export const mapRideToSummaryRide = (
    ride: RideResponse,
    rideValues: Ride,
    newEstimateDistanceAndDuration: EstimationDetails | undefined
): SummaryRideType => ({
    prettifiedUnid: ride.prettifiedUnid,
    status: ride.status,
    distance: metersToKmAndMiFormatted(newEstimateDistanceAndDuration?.distanceInMeters ?? ride.distanceInMeters),
    time: secondsToTimeFormatted(newEstimateDistanceAndDuration?.durationInSeconds ?? ride.durationInSeconds),
    pickupCoordinates:
    rideValues.pickUpLocation?.latitude && rideValues.pickUpLocation?.longitude
        ? {
            lat: rideValues.pickUpLocation?.latitude,
            lon: rideValues.pickUpLocation?.longitude,
        }
        : undefined,
    dropOffCoordinates:
    rideValues.dropOffLocation?.latitude && rideValues.dropOffLocation?.longitude
        ? {
            lat: rideValues.dropOffLocation?.latitude,
            lon: rideValues.dropOffLocation?.longitude,
        }
        : undefined,
    paymentMethod: ride?.paymentMethod,
    booker: ride?.booker,
    primaryTimeZone: rideValues.primaryTimeZone,
    dropOffPlaceId: rideValues.dropOffLocation?.id,
    pickUpPlaceId: rideValues.pickUpLocation?.id,
    scheduledArrival: getScheduledArrival(
        rideValues.pickUpTime,
        newEstimateDistanceAndDuration?.durationInSeconds ?? ride.durationInSeconds
    ),
    scheduledArrivalLocal: getScheduledArrival(
        rideValues.pickUpTimeLocal,
        newEstimateDistanceAndDuration?.durationInSeconds ?? ride.durationInSeconds
    ),
    lastPositionUpdate: ride?.lastPositionUpdate,
});
