import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material';
import React, { useContext } from 'react';

import CopyToClipboardButton from '../../../../../../components/buttons/CopyToClipboardButton';
import { RideSummaryAccordions, RideSummaryCardContext } from '../RideSummaryCardContext';
import informationSummaryStyles from './InformationSummary.styles';

interface SummaryAccordionProps {
    unid: keyof typeof RideSummaryAccordions;
    summaryTitle: string;
    detailsContent: JSX.Element;
    getCopyToClipboardText?: string;
    hasCopyButton?: boolean;
}

const SummaryAccordion = ({
    unid,
    summaryTitle,
    detailsContent,
    getCopyToClipboardText = '',
    hasCopyButton,
}: SummaryAccordionProps) => {
    const classes = informationSummaryStyles();
    const { expanded, setExpanded } = useContext(RideSummaryCardContext);

    const handleOnChange = (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded({
            ...expanded,
            [unid]: isExpanded,
        });
    };

    return (
        <Accordion className={classes.accordion} expanded={expanded[unid]} onChange={handleOnChange}>
            <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreOutlinedIcon />}
                aria-controls={`panel${unid}-content`}
            >
                <Grid display="flex" alignItems="center">
                    <Typography variant="h3" component="h4" className={classes.subtitle} marginBottom="0 !important">
                        {summaryTitle}
                    </Typography>
                    {hasCopyButton ? (<CopyToClipboardButton text={getCopyToClipboardText} />) : ''}
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>{detailsContent}</AccordionDetails>
        </Accordion>
    );
};

export default SummaryAccordion;
