/* eslint-disable max-len */
/* eslint-disable complexity */
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import FlightLandOutlinedIcon from '@mui/icons-material/FlightLandOutlined';
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CopyToClipboardButton from '../../../../../../components/buttons/CopyToClipboardButton';
import IconedData from '../../../../../../components/iconedData/IconedData';
import { COLORS } from '../../../../../../constants/colors';
import { DATE_FORMATS } from '../../../../../../constants/dateFormats';
import { FlightStatus, getFlightStatusKeyName } from '../../../../../../helpers/flightStatusUtil';
import { getDateTimeWithoutOffsetFormatted } from '../../../../../../helpers/getDateTimeWithoutOffset';
import theme from '../../../../../../styles/theme';
import { Arrival, Departure, FlightDetails, FlightType } from '../../../../../rides/api/types';
import FlightStatusBadge from '../../../../../rides/components/FlightStatusBadge';
import { Passenger } from '../../../../api/types';
import { RideSummaryAccordions } from '../RideSummaryCardContext';
import informationSummaryStyles from './InformationSummary.styles';
import SummaryAccordion from './SummaryAccordion';

interface RideInformationSummaryProps {
    unid: keyof typeof RideSummaryAccordions;
    prettifiedUnid?: string;
    durationInSeconds: number;
    pickUp?: Departure;
    dropOff?: Arrival;
    flightDetails?: FlightDetails | null;
    flightStatus?: FlightStatus;
    specialRequest?: string;
    isOnlyPickupAndDropoff?: boolean;
    displayAsAcccordion?: boolean;
    isAlwaysMobile?: boolean;
    hidePickUpDateAndTime?: boolean;
    vehicle?: string;
    numberOfPassengers?: number;
    numberOfBags?: number;
    passengers?: Passenger[];
}

const getPassengersNamesList = (passengers: Passenger[]): string =>
    passengers.map(passenger => `${passenger.firstName} ${passenger.lastName}`).join(', ');

const RideInformationSummary = ({
    unid,
    prettifiedUnid,
    pickUp,
    dropOff,
    flightDetails,
    flightStatus,
    durationInSeconds,
    specialRequest,
    isOnlyPickupAndDropoff,
    displayAsAcccordion = true,
    isAlwaysMobile,
    hidePickUpDateAndTime,
    vehicle,
    numberOfPassengers,
    numberOfBags,
    passengers = [],
}: RideInformationSummaryProps) => {
    const classes = informationSummaryStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down(isAlwaysMobile ? 'xl' : 'md'));

    const scheduledArrivalLocal = useMemo(
        () => {
            let scheduledTime: string | null = null;

            if (flightDetails?.type === FlightType.ARRIVAL) {
                scheduledTime = getDateTimeWithoutOffsetFormatted(
                    flightDetails?.arrival?.datetimeLocal,
                    DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']
                );
            } else if (flightDetails?.type === FlightType.DEPARTURE) {
                scheduledTime = getDateTimeWithoutOffsetFormatted(
                    flightDetails?.departure?.datetimeLocal,
                    DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']
                );
            } else if (flightDetails?.arrivalTime) {
                scheduledTime = getDateTimeWithoutOffsetFormatted(
                    flightDetails?.arrivalTime,
                    DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']
                );
            } else {
                scheduledTime = null;
            }

            return scheduledTime;
        }
        , [
            flightDetails?.arrival?.datetimeLocal,
            flightDetails?.arrivalTime,
            flightDetails?.departure?.datetimeLocal,
            flightDetails?.type,
        ]
    );

    const pickUpDateAndTime = useMemo(
        () => {
            if (!pickUp?.departAtLocal) {
                return '';
            }

            return getDateTimeWithoutOffsetFormatted(pickUp.departAtLocal, DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']);
        }
        , [pickUp?.departAtLocal]
    );

    const dropOffDateAndTime = useMemo(
        () => {
            if (!dropOff?.arriveAtLocal) {
                return '';
            }

            return getDateTimeWithoutOffsetFormatted(dropOff.arriveAtLocal, DATE_FORMATS['ddd, DD MMM YYYY at HH:mm A']);
        }
        , [dropOff?.arriveAtLocal]
    );

    const flightTypeIcon = useMemo(() => {
        if (flightDetails?.type === FlightType.DEPARTURE) {
            return FlightTakeoffOutlinedIcon;
        }

        return FlightLandOutlinedIcon;
    }, [flightDetails?.type]);

    const scheduledLabel = useMemo(() => {
        if (flightDetails?.type === FlightType.DEPARTURE) {
            return `${t('pages.summary.departureTime')}:`;
        } else if (flightDetails?.type === FlightType.ARRIVAL) {
            return `${t('pages.summary.arrivalTime')}:`;
        }

        return `${t('pages.summary.scheduledTime')}:`;
    }, [flightDetails?.type, t]);

    const passengerNames = getPassengersNamesList(passengers);

    const getCopyToClipboardText = () => {
        return (
            [
                `${t('ride')}: ${prettifiedUnid}`,
                `${t('pages.singleRide.fields.pickUpDate')}: ${pickUpDateAndTime}`,
                `${t('pickUpLocation')}: ${pickUp?.location.address}`,
                `${t('dropOffLocation')}: ${dropOff?.location.address}`,
                `${t('specialRequest')}: ${specialRequest}`,
                `${t('vehicleType')}: ${vehicle}`,
                `${t('numberOfPassengers')}: ${numberOfPassengers}`,
                `${t('piecesOfLuggage')}: ${numberOfBags}`,
                `${t('passengers')}: ${passengerNames}`,
            ].join('\n')
        );
    };

    const content = useMemo(
        () => (
            <>
                {isMobile && !hidePickUpDateAndTime && (
                    <IconedData
                        icon={CalendarTodayOutlinedIcon}
                        data={pickUpDateAndTime}
                        tooltip={t('dateAndTime')}
                        dataLabel={`${t('dateAndTime')}:`}
                        dataLabelColor={COLORS.SLATE_GREY}
                    />
                )}
                <Box
                    display="flex"
                    flexDirection={isMobile ? 'column' : 'row'}
                    justifyContent="space-between"
                    marginBottom={isOnlyPickupAndDropoff || isMobile ? '1rem' : '2.5rem'}
                >
                    <Box width={isMobile ? '100%' : '50%'}>
                        <Box display="flex" alignItems="center" marginBottom="0.25rem" >
                            <IconedData
                                icon={RoomOutlinedIcon}
                                tooltip={t('from')}
                                dataLabel={`${t('from')}`}
                                dataLabelColor={COLORS.SLATE_GREY}
                                bottomMargin="0"
                            />
                            <CopyToClipboardButton text={(pickUp?.location?.address || pickUp?.location?.name) ?? ''} padding="0.5rem 0.5rem 0.5rem 0.1rem" />
                        </Box>
                        <Typography paddingLeft="2.125rem">
                            {pickUp?.location?.address || pickUp?.location?.name || ''}
                        </Typography>
                        <Typography className={classes.pickupDropoffDateTime}>{pickUpDateAndTime}</Typography>
                    </Box>
                    <Box width={isMobile ? '100%' : '50%'}>
                        <Box display="flex" alignItems="center" marginBottom="0.25rem">
                            <IconedData
                                icon={RoomOutlinedIcon}
                                tooltip={t('pages.rides.to')}
                                dataLabel={`${t('pages.rides.to')}`}
                                dataLabelColor={COLORS.SLATE_GREY}
                                bottomMargin="0"
                            />
                            <CopyToClipboardButton text={(dropOff?.location?.address || dropOff?.location?.name) ?? ''} padding="0.5rem 0.5rem 0.5rem 0.1rem" />
                        </Box>
                        <Typography paddingLeft="2.125rem">
                            {dropOff?.location?.address || dropOff?.location?.name || ''}
                        </Typography>
                        <Typography className={classes.pickupDropoffDateTime}>{dropOffDateAndTime}</Typography>
                    </Box>
                </Box>
                {!isOnlyPickupAndDropoff && (
                    <>
                        <IconedData
                            icon={QuestionMarkOutlinedIcon}
                            data={specialRequest ? specialRequest : t('notAvailable')}
                            tooltip={t('specialRequest')}
                            dataLabel={`${t('specialRequest')}:`}
                            dataLabelColor={COLORS.SLATE_GREY}
                            dataColor={COLORS.BLACK}
                        />
                        <Box display="flex">
                            <IconedData
                                icon={flightTypeIcon}
                                data={flightDetails?.number ? flightDetails.number : t('notAvailable')}
                                tooltip={t('flight')}
                                dataLabel={`${t('flight')}:`}
                                dataLabelColor={COLORS.SLATE_GREY}
                                rightMargin="0.625rem"
                            />
                            {flightStatus && (<FlightStatusBadge showTooltip status={getFlightStatusKeyName(flightStatus)} />)}
                        </Box>
                        <IconedData
                            data={scheduledArrivalLocal ?? t('notAvailable')}
                            dataLabel={scheduledLabel}
                            dataLabelColor={COLORS.SLATE_GREY}
                            leftMargin="2.125rem"
                        />
                    </>
                )}
            </>
        ),
        [
            classes.pickupDropoffDateTime,
            dropOff?.location?.address,
            dropOff?.location?.name,
            dropOffDateAndTime,
            flightDetails?.number,
            flightStatus,
            flightTypeIcon,
            hidePickUpDateAndTime,
            isMobile,
            isOnlyPickupAndDropoff,
            pickUp?.location?.address,
            pickUp?.location?.name,
            pickUpDateAndTime,
            scheduledArrivalLocal,
            specialRequest,
            scheduledLabel,
            t,
        ]
    );

    return isMobile && displayAsAcccordion
        ? (
            <SummaryAccordion
                unid={unid}
                summaryTitle={t('ride')}
                detailsContent={content}
                getCopyToClipboardText={getCopyToClipboardText()}
                hasCopyButton={true}
            />
        )
        : (
            <>
                <Grid display="flex" alignItems="center" marginBottom="0.5rem">
                    <Typography variant="h3" component="h4" className={classes.subtitleWithIcon} marginBottom="0">
                        {t('ride')}
                    </Typography>
                    <CopyToClipboardButton
                        text={getCopyToClipboardText()}
                    />
                </Grid>
                {content}
            </>
        );
};

export default RideInformationSummary;
