/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import React, { useState } from 'react';

import { COLORS } from '../../../constants/colors';
import theme from '../../../styles/theme';

const withExpandable = (WrappedComponent: React.ComponentType<any>) => {
    return (props: any) => {
        const isMobile = useMediaQuery(theme.breakpoints.down('md'));
        const [isExpanded, setIsExpanded] = useState(true);

        const toggleExpanded = () => {
            setIsExpanded(prev => !prev);
        };

        return (
            <>
                <WrappedComponent
                    {...props}
                    isExpanded={isMobile ? isExpanded : true}
                    renderChevrons={isMobile && (
                        isExpanded
                            ? (
                                <ExpandLess
                                    sx={{ color: COLORS.SLATE_GREY }}
                                    onClick={toggleExpanded}
                                />
                            )
                            : (
                                <ExpandMore
                                    sx={{ color: COLORS.SLATE_GREY }}
                                    onClick={toggleExpanded}
                                />
                            )
                    )}
                />
            </>
        );
    };
};

export default withExpandable;
