/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/quotes */
import { PortalLocale } from "../context/LocaleContext";
import { SupportedLocale } from "../typings/zendesk";

/*
 * Supported locales can be found at
 * https://support.zendesk.com/api/v2/rosetta/locales/public.json
 */

const findZendeskLocale = (value: PortalLocale): SupportedLocale => {
  switch (value) {
    case "en-GB":
      return "en-gb";
    case "es-ES":
      return "es-es";
    default:
      console.log(`${value as string} is unsupported`);
      return "en-gb";
  }
};

export default findZendeskLocale;
