import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../../components/modal/Modal';
import { RideResponse } from '../../../ride/api/types';
import ReferenceCard from '../../../ride/supplier/components/ReferenceCard';
import PassengerInformationSummary from
    '../../../ride/supplier/components/rideSummaryCard/components/PassengerInformationSummary';
import RideInformationSummary from '../../../ride/supplier/components/rideSummaryCard/components/RideInformationSummary';
import { RideSummaryAccordions } from '../../../ride/supplier/components/rideSummaryCard/RideSummaryCardContext';
import useConfirmCloseAddRemarksRide from '../../../ride/supplier/hooks/useConfirmCloseAddRemarksRide';
import { RemarksCard, RemarksValues, initialRemarks } from './RemarksCard';

const useStyles = makeStyles({ closeRideModal: { '& .MuiDialog-container .MuiPaper-root': { maxWidth: '600px' } } });

export interface CloseRideData {
    referenceNumber: string;
    remarks: string;
}

interface CloseRideModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    ride: RideResponse;
    onFinished: (updatedRide?: RideResponse) => void;
    supplierReference?: string;
}

const CloseRideModal = ({ isModalOpen, onClose, ride, onFinished, supplierReference }: CloseRideModalProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [remarksValues, setRemarksValues] = useState<RemarksValues>(initialRemarks);
    const [reference, setReference] = useState<string | null>(supplierReference ?? null);

    const { onCloseRide, isSaving } = useConfirmCloseAddRemarksRide({
        rideId: ride.unid,
        reference,
        remarksValues,
    });

    const handleClose = async () => {
        const data = await onCloseRide();

        onClose();
        onFinished(data);
    };

    return (
        <Modal
            modalContentClassName={classes.closeRideModal}
            isOpen={isModalOpen}
            onClose={onClose}
            onSubmit={handleClose}
            title={t('pages.rides.closeRide')}
            confirmButtonLabel={t('buttonName.close')}
            cancelButtonLabel={t('buttonName.back')}
            isDisabled={isSaving}
            maxWidth="sm"
            fullWidth
        >
            <Grid container flexDirection="column">
                <Grid item>
                    <RideInformationSummary
                        unid={RideSummaryAccordions.RideInformationSummary}
                        pickUp={ride.pickUp}
                        dropOff={ride.dropOff}
                        flightDetails={ride.flightDetails}
                        durationInSeconds={ride?.durationInSeconds ?? 0}
                        specialRequest={ride?.specialRequest}
                        displayAsAcccordion={false}
                        isOnlyPickupAndDropoff
                        isAlwaysMobile
                        hidePickUpDateAndTime
                    />
                </Grid>
                <Grid item>
                    <PassengerInformationSummary
                        unid={RideSummaryAccordions.PassengerInformationSummary}
                        passengers={ride?.passengers}
                        numberOfPassengers={ride?.numberOfPassengers.toString() ?? ''}
                        luggage={ride?.numberOfBags.toString() ?? ''}
                        clientInstructions={ride?.notesToSupplier}
                        displayAsAcccordion={false}
                    />
                </Grid>
                <Grid item paddingTop="2rem">
                    <ReferenceCard isVisible reference={reference} setReference={setReference} />
                </Grid>
                <Grid item>
                    <RemarksCard remarksValues={remarksValues} setRemarksValues={setRemarksValues} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default CloseRideModal;
