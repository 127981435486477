import { TextField } from '@get-e/react-components';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Dispatch, useState, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import {
    SINGLE_RIDE_ADD_REMARKS_EXTRA_WAITING_TIME,
    SINGLE_RIDE_ADD_REMARKS_OTHER_COSTS,
    SINGLE_RIDE_ADD_REMARKS_PARKING_TOLL_COSTS,
    SINGLE_RIDE_ADD_REMARKS_WAITING_TIME_COSTS,
} from '../../../../constants/amplitude/supplierKeys';
import { COLORS } from '../../../../constants/colors';
import { IS_DECIMAL_NUMBER } from '../../../../helpers/inputValidation/regexRules';
import { RideContext } from '../../../ride/supplier/context/RideContext';

export const initialRemarks = {
    extraWaitingTime: '',
    waitingTimeCosts: '',
    parkingTollCosts: '',
    otherCosts: '',
    comments: '',
};

const useStyles = makeStyles({
    container: {},
    formField: { marginBottom: '1.25rem' },
    buttonAddRemarks: {
        color: COLORS.BLUE,
        padding: 0,
        '&:hover': { backgroundColor: COLORS.WHITE },
    },
});

export interface RemarksValues {
    extraWaitingTime: string | null;
    waitingTimeCosts: string | null;
    parkingTollCosts: string | null;
    otherCosts: string | null;
    comments: string | null;
}

interface RemarksCardProps {
    remarksValues: RemarksValues;
    setRemarksValues: Dispatch<SetStateAction<RemarksValues>>;
    isAdditionalButtonHidden?: boolean;
    isAdditionalCostsTitleHidden?: boolean;
}

export const RemarksCard = ({
    remarksValues,
    setRemarksValues,
    isAdditionalButtonHidden,
    isAdditionalCostsTitleHidden,
}: RemarksCardProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [isAdditionalCosts, setIsAdditionalCosts] = useState(false);
    const { amplitudeEventProperties } = useContext(RideContext);

    const handleChange = <T extends keyof RemarksValues>(
        key: T,
        newValue: RemarksValues[T] & string): void => {
        if (key !== 'comments') {
            if (newValue === '' || IS_DECIMAL_NUMBER.test(newValue)) {
                setRemarksValues({
                    ...remarksValues,
                    [key]: newValue,
                });
            }
        }

        setRemarksValues({
            ...remarksValues,
            [key]: newValue,
        });
    };

    return (
        <Grid item className={classes.container}>
            {!isAdditionalCosts && !isAdditionalButtonHidden && (
                <Button
                    onClick={() => setIsAdditionalCosts(true)}
                    variant="text"
                    className={classes.buttonAddRemarks}
                >{t('pages.rides.addRemarks')}
                </Button>
            )}
            {(isAdditionalCosts || isAdditionalButtonHidden)
                && (
                    <Grid item>
                        {!isAdditionalCostsTitleHidden && (
                            <Typography
                                sx={{
                                    color: COLORS.BLUE_DARK,
                                    fontWeight: '700',
                                    paddingBottom: '1rem',
                                }}
                            >
                                {t('modals.referencesAndRemarks.additionalCosts')}
                            </Typography>
                        )}
                        <Typography
                            sx={{
                                paddingBottom: '0.25rem',
                                color: COLORS.SLATE_GREY,
                                fontWeight: 700,
                            }}
                        >
                            {t('modals.referencesAndRemarks.additionalCostsDescription1')}
                        </Typography>
                        <Typography
                            sx={{
                                color: COLORS.BLACK,
                                paddingBottom: '2rem',
                            }}
                        >
                            {t('modals.referencesAndRemarks.additionalCostsDescription2')}
                        </Typography>
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{
                                xs: 1,
                                sm: 2,
                                md: 3,
                            }}
                        >
                            <Grid item xs={12} md={6} paddingTop="0 !important">
                                <TextField
                                    className={classes.formField}
                                    type="number"
                                    name="extraWaitingTime"
                                    label={t('modals.referencesAndRemarks.extraWaitingTime')}
                                    value={remarksValues.extraWaitingTime}
                                    onChange={event => handleChange('extraWaitingTime', event.target.value)}
                                    onBlur={() =>
                                        logAmplitudeEvent(SINGLE_RIDE_ADD_REMARKS_EXTRA_WAITING_TIME, amplitudeEventProperties)}
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} paddingTop="0 !important">
                                <TextField
                                    className={classes.formField}
                                    type="number"
                                    name="waitingTimeCosts"
                                    label={t('modals.referencesAndRemarks.waitingTimeCosts')}
                                    value={remarksValues.waitingTimeCosts}
                                    onChange={event => handleChange('waitingTimeCosts', event.target.value)}
                                    onBlur={() =>
                                        logAmplitudeEvent(SINGLE_RIDE_ADD_REMARKS_WAITING_TIME_COSTS, amplitudeEventProperties)}
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} paddingTop="0 !important">
                                <TextField
                                    className={classes.formField}
                                    type="number"
                                    name="parkingTollCosts"
                                    label={t('modals.referencesAndRemarks.parkingTollCosts')}
                                    value={remarksValues.parkingTollCosts}
                                    onChange={event => handleChange('parkingTollCosts', event.target.value)}
                                    onBlur={() =>
                                        logAmplitudeEvent(SINGLE_RIDE_ADD_REMARKS_PARKING_TOLL_COSTS, amplitudeEventProperties)}
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} paddingTop="0 !important">
                                <TextField
                                    className={classes.formField}
                                    type="number"
                                    name="otherCosts"
                                    label={t('modals.referencesAndRemarks.otherCosts')}
                                    value={remarksValues.otherCosts}
                                    onChange={event => handleChange('otherCosts', event.target.value)}
                                    onBlur={() =>
                                        logAmplitudeEvent(SINGLE_RIDE_ADD_REMARKS_OTHER_COSTS, amplitudeEventProperties)}
                                    InputProps={{ inputProps: { min: 0 } }}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            className={classes.formField}
                            label={t('comments')}
                            value={remarksValues.comments}
                            onChange={event => handleChange('comments', event.target.value)}
                            InputProps={{
                                minRows: '3',
                                multiline: true,
                            }}
                        />
                    </Grid>)
            }
        </Grid>
    );
};
