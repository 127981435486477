import { Box, Grid, Rating, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';

const useStyles = makeStyles({
    mainContainer: {
        width: '100%',
        height: 'calc(100% - 1rem)',
        flexDirection: 'column',
        border: `1px solid ${COLORS.BLACK_12}`,
        borderRadius: '3px',
        marginTop: '1rem',
    },
    ratingBox: {
        display: 'flex',
        alignItems: 'center',
    },
    serviceBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '.5rem',
    },
});

const AverageRatingTopicCard: FunctionComponent<{
    ratingsByTopic: Array<{
        label: string;
        rate: number | null;
    }> | null;
}> = ({ ratingsByTopic }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container className={classes.mainContainer}>
            <Grid item padding="1rem" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="1.25rem">
                    <Typography fontWeight="700" color={COLORS.SLATE_GREY}>
                        {t('pages.reports.averageRatingByTopic')}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" paddingRight="2px">
                    {ratingsByTopic && ratingsByTopic.map(({ label, rate }, index) => (
                        <Box className={classes.serviceBox} key={index}>
                            <Typography>{label}</Typography>
                            <Box className={classes.ratingBox}>
                                {rate?.toFixed(1)}
                                <Rating name="ratingBox" value={rate ?? 0} readOnly sx={{ paddingLeft: '1rem' }} />
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Grid>
        </Grid>
    );
};

export default AverageRatingTopicCard;
