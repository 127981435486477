import { IS_LETTER_AND_NUMBER } from '../regexRules';
import { Validated } from '../Validator';

export default function isOnlyLettersAndNumbersString<TError>(
    value: string,
    error: TError,
): Validated<string, TError> {
    return (
        !value || !IS_LETTER_AND_NUMBER.test(value)
            ? {
                isValid: false,
                error,
            }
            : {
                isValid: true,
                value,
            }
    );
}
