import { Download } from '@mui/icons-material';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';
import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import CopyToClipboardButton from '../../../../components/buttons/CopyToClipboardButton';
import PageActionButton from '../../../../components/buttons/PageActionButton';
import { SINGLE_RIDE_PAGE } from '../../../../constants/amplitude/commonKeys';
import {
    SINGLE_RIDE_CLOSE,
    SINGLE_RIDE_CONFIRM,
    SINGLE_RIDE_DOWNLOAD_DOCUMENTATION,
} from '../../../../constants/amplitude/supplierKeys';
import { COLORS } from '../../../../constants/colors';
import { DATE_FORMATS } from '../../../../constants/dateFormats';
import { RideStatus, getRideStatusKey } from '../../../../helpers/rideStatusUtil';
import theme from '../../../../styles/theme';
import downloadRideOrder from '../../../rides/api/downloadRideOrder';
import StatusBadge from '../../../rides/components/StatusBadge';
import { RideAmplitudeEventProperties, RideResponse } from '../../api/types';
import { RideContext } from '../context/RideContext';
import CloseRideModal from './CloseRideModal';
import ConfirmRideModalNew from './ConfirmRideModalNew';
import RideLoadingSkeleton from './rideLoadingSkeleton/RideLoadingSkeleton';

const useStyles = makeStyles(styleTheme => ({
    headerTitleWrapper: {
        marginBottom: '2rem',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        [styleTheme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            marginTop: '2rem',
        },
    },
    headerTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 0,
        [styleTheme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '0.5rem',
            gap: '0.5rem',
        },
    },
    headerSecondaryTitle: {
        color: COLORS.BLUE,
        fontSize: '1.5rem',
        fontWeight: 700,
        marginRight: '1rem',
    },
    subheaderText: {
        fontWeight: 400,
        fontSize: '0.75rem',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        marginRight: '0.5rem',
        color: COLORS.BLUE,
        '& .MuiSvgIcon-root': {
            fontSize: '1.3rem',
            marginRight: '0.3rem',
        },
        [styleTheme.breakpoints.down('lg')]: { marginBottom: '1rem' },
    },
    mainWrapper: {
        display: 'initial',
        alignItems: 'center',
        padding: '0 0 0 2rem',
        [styleTheme.breakpoints.down('lg')]: { padding: 0 },
    },
}));

interface SupplierRideHeaderProps {
    data?: RideResponse;
    refetch: () => void;
    isLoading: boolean;
}

const RideHeader = ({ data, isLoading, refetch }: SupplierRideHeaderProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [isConfirmRideModalOpen, setIsConfirmRideModalOpen] = useState(false);
    const [isCloseRideModalOpen, setIsCloseRideModalOpen] = useState(false);
    const [supplierReference, setSupplierReference] = useState<string | null>(null);
    const isPageOpened = useRef(false);

    useEffect(() => {
        if (data?.supplierReference) {
            setSupplierReference(data.supplierReference);
        }
    }, [data?.supplierReference, setSupplierReference]);

    const amplitudeEventProperties = useMemo(() => {
        return {
            'Ride number': data?.prettifiedUnid,
            'Ride created time': moment.utc(data?.createdAt).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
            'Ride pickup time': moment.utc(data?.pickUp.departAt).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
            'Ride customer': data?.customer,
            'Ride supplier': data?.supplier,
            'Ride status': data?.status,
            'Ride situation tags': data?.situationTag,
            'Ride qc status': data?.qcStatus,
            'Ride has driver': Boolean(data?.driver),
        } as RideAmplitudeEventProperties;
    }, [
        data?.createdAt,
        data?.customer,
        data?.driver,
        data?.pickUp.departAt,
        data?.prettifiedUnid,
        data?.qcStatus,
        data?.situationTag,
        data?.status,
        data?.supplier,
    ]);

    const { mutate: downloadRideOrderMutation } = useMutation(downloadRideOrder);

    const isConfirmRideButtonVisible = useMemo(
        () => data?.status && [
            getRideStatusKey(RideStatus.TO_CONFIRM),
            getRideStatusKey(RideStatus.TO_CONFIRM_CHANGE),
        ].includes(data.status),
        [data?.status]
    );

    const isCloseRideButtonVisible = useMemo(
        () => data?.status === getRideStatusKey(RideStatus.TO_CLOSE),
        [data?.status]
    );

    const handleConfirmRide = () => {
        setIsConfirmRideModalOpen(true);
        logAmplitudeEvent(SINGLE_RIDE_CONFIRM, amplitudeEventProperties);
    };

    const handleCloseRide = () => {
        setIsCloseRideModalOpen(true);
        logAmplitudeEvent(SINGLE_RIDE_CLOSE, amplitudeEventProperties);
    };

    const handleConfirmCloseFinished = () => {
        refetch();
    };

    if (!data || isLoading) {
        return <RideLoadingSkeleton />;
    }

    if (!isPageOpened.current) {
        isPageOpened.current = true;
        logAmplitudeEvent(SINGLE_RIDE_PAGE, amplitudeEventProperties);
    }

    return (
        <>
            <RideContext.Provider
                value={{
                    isDisabled: false,
                    rideResponse: data,
                    amplitudeEventProperties,
                }}
            >
                <Grid container className={classes.mainWrapper}>
                    <Grid item xs className={classes.headerTitleWrapper} sx={{ marginBottom: '0 !important' }}>
                        <Grid item className={classes.headerTitle}>
                            <Typography className={classes.headerSecondaryTitle} >
                                {t('ride')} {data?.prettifiedUnid}
                                <CopyToClipboardButton text={data?.prettifiedUnid} />
                            </Typography>
                            <StatusBadge
                                status={data?.status}
                                trackingStatus={data?.trackingStatus}
                                pickupDateTimeUtc={data?.pickUp.departAt}
                            />
                        </Grid>
                        <Grid item>
                            <PageActionButton
                                isVisible={isConfirmRideButtonVisible}
                                onClick={handleConfirmRide}
                                isLoading={false}
                            >
                                {t('buttonName.confirmRide')}
                            </PageActionButton>
                            <PageActionButton
                                isVisible={isCloseRideButtonVisible}
                                onClick={handleCloseRide}
                                isLoading={false}
                            >
                                {t('buttonName.closeRide')}
                            </PageActionButton>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs
                        display="flex"
                        alignItems={isMobile ? 'flex-start' : 'center'}
                        flexDirection={isMobile ? 'column' : 'row'}
                    >
                        <Typography
                            className={classes.subheaderText}
                            onClick={() => {
                                downloadRideOrderMutation(data?.unid ?? '');
                                logAmplitudeEvent(SINGLE_RIDE_DOWNLOAD_DOCUMENTATION, amplitudeEventProperties);
                            }}
                        >
                            <Download />
                            {t('pages.singleRide.downloadRideOrder')}
                        </Typography>
                    </Grid>
                </Grid>
                {isConfirmRideModalOpen && (
                    <ConfirmRideModalNew
                        isModalOpen={isConfirmRideModalOpen}
                        onClose={() => {
                            setIsConfirmRideModalOpen(false);
                        }}
                        onFinished={handleConfirmCloseFinished}
                        ride={data}
                        supplierReference={supplierReference ? supplierReference : undefined}
                    />
                )}
                {isCloseRideModalOpen && (
                    <CloseRideModal
                        isModalOpen={isCloseRideModalOpen}
                        onClose={() => setIsCloseRideModalOpen(false)}
                        onFinished={handleConfirmCloseFinished}
                        ride={data}
                        supplierReference={supplierReference ? supplierReference : undefined}
                    />
                )}
            </RideContext.Provider>
        </>
    );
};

export default RideHeader;
