/* eslint-disable @typescript-eslint/indent */
/* eslint-disable max-lines-per-function */
import { Spinner, TextField } from '@get-e/react-components';
import AirplanemodeInactiveOutlinedIcon from '@mui/icons-material/AirplanemodeInactiveOutlined';
import FlightIcon from '@mui/icons-material/Flight';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Grid, Box, Typography, InputAdornment, Button, Tooltip, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker, TimePicker } from '@mui/x-date-pickers-pro';
import moment from 'moment';
import React, { SetStateAction, Dispatch, useState, KeyboardEvent, useMemo, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import CopyToClipboardButton from '../../../components/buttons/CopyToClipboardButton';
import IconedData from '../../../components/iconedData/IconedData';
import {
    RIDE_INFORMATION_CAR_PICKUP_TIME,
    RIDE_INFORMATION_FROM,
    RIDE_INFORMATION_LUGGAGE,
    RIDE_INFORMATION_PASSENGERS,
    RIDE_INFORMATION_PICKUP_DATE, RIDE_INFORMATION_REFERENCE, RIDE_INFORMATION_SPECIAL_REQUEST, RIDE_INFORMATION_TO,
} from '../../../constants/amplitude/customerKeys';
import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { datePickerPlaceholder } from '../../../constants/datePickerPlaceholder';
import { ENTER } from '../../../constants/keyCodes';
import { TIME_FORMATS } from '../../../constants/timeFormats';
import { InputError } from '../../../helpers/inputValidation/InputError';
import { getAirlineIataImage } from '../../../services/routes';
import { PrimaryTimeZone } from '../../../services/types';
import useFormStyles from '../../../styles/Form';
import theme from '../../../styles/theme';
import { FlightDetails } from '../../rides/api/types';
import { CustomField, RideFeedback, SearchLocation } from '../api/types';
import { RideContext } from '../customer/context/RideContext';
import { SearchLocationComponent } from './SearchLocationComponent';

const useStyles = makeStyles({
    centerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > div > span': { fontWeight: 700 },
    },
    timeSepartor: {
        '& .MuiTimePickerToolbar-hourMinuteLabel': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiTimePickerToolbar-separator': { marginTop: '-.5rem' },
    },
});

const AIRLINE_IMAGE_WIDTH = 75;
const AIRLINE_IMAGE_HEIGHT = 25;

export interface RideError {
    pickUpDate: InputError | null;
    pickUpTime: InputError | null;
    travellers: InputError | null;
    luggages: InputError | null;
    pickUpLocation: InputError | null;
    dropOffLocation: InputError | null;
    customFields: { [key: string]: InputError | null };
}

export const initialRideErrors = {
    pickUpDate: null,
    pickUpTime: null,
    travellers: null,
    luggages: null,
    pickUpLocation: null,
    dropOffLocation: null,
    customFields: {},
};

export interface Ride {
    id: string;
    pickUpLocation: SearchLocation | null;
    dropOffLocation: SearchLocation | null;
    pickUpDate: Date | null;
    pickUpTime: Date | null;
    pickUpDateLocal: Date | null;
    pickUpTimeLocal: Date | null;
    dropOffDate: Date | null;
    dropOffDateLocal: Date | null;
    travellers: string;
    luggages: string;
    flightNumber: string;
    specialRequest: string;
    customReference: string;
    flightDetails: FlightDetails;
    primaryTimeZone: PrimaryTimeZone | null;
    feedbacks: RideFeedback[];
    customFields: CustomField[];
}

export const initialRide = {
    id: uuid(),
    pickUpLocation: null,
    dropOffLocation: null,
    pickUpDate: null,
    pickUpDateLocal: null,
    pickUpTime: null,
    pickUpTimeLocal: null,
    dropOffDate: null,
    dropOffDateLocal: null,
    travellers: '',
    luggages: '',
    flightNumber: '',
    specialRequest: '',
    customReference: '',
    flightDetails: {} as FlightDetails,
    primaryTimeZone: null,
    feedbacks: [],
    customFields: [],
} as Ride;

interface RideInformationProps {
    rideValues: Ride;
    setRideValues: Dispatch<SetStateAction<Ride>>;
    errors: RideError;
    setErrors: Dispatch<SetStateAction<RideError>>;
    handleFlightNumberFinishing: () => void;
    isLoadingFlight?: boolean;
    isErrorFlight?: boolean;
    isUtc: boolean;
    initialRideValues?: Ride | null;
}

// eslint-disable-next-line complexity
const RideInformation = ({
    rideValues,
    setRideValues,
    errors,
    setErrors,
    handleFlightNumberFinishing,
    isLoadingFlight,
    isErrorFlight,
    isUtc,
    initialRideValues,
}: RideInformationProps) => {
    const { t } = useTranslation();
    const [isReferenceVisible, setIsReferenceVisible] = useState(Boolean(rideValues.customReference));
    const classes = useStyles();
    const formClasses = useFormStyles();
    const [inputValue, setInputValue] = useState('');
    const [inputValueDropOff, setInputValueDropOff] = useState('');
    const [openDate, setOpenDate] = useState(false);
    const [openTime, setOpenTime] = useState(false);
    const [imageError, setImageError] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isFlightTracked = !isErrorFlight && Boolean(rideValues.flightDetails.number);

    const { isDisabled, addedTravellerCount, isTravellerIncreasingDisabled, amplitudeEventProperties } = useContext(RideContext);

    const updatedRideValues = useMemo(() => {
        if (addedTravellerCount > parseInt(rideValues.travellers) && !isTravellerIncreasingDisabled) {
            return {
                ...rideValues,
                travellers: addedTravellerCount.toString(),
            };
        }

        return rideValues;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addedTravellerCount, isTravellerIncreasingDisabled]);

    useEffect(() => {
        setRideValues(updatedRideValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedRideValues]);

    const handleChangeValue = (field: keyof Ride, value: string | Date | SearchLocation | null, fieldKey?: string) => {
        let newValues = { ...rideValues };

        if (field === 'customFields') {
            const updatedCustomFields = rideValues.customFields?.map(customField => {
                if (customField.key === fieldKey) {
                    return {
                        ...customField,
                        value,
                    } as CustomField;
                }

                return customField;
            });

            newValues.customFields = updatedCustomFields;
        } else {
            newValues = {
                ...rideValues,
                [field]: value,
            };
        }

        const newErrors = { ...errors };

        if (field !== 'customFields') {
            newErrors[field] = null;
        } else if (fieldKey) {
            newErrors.customFields[fieldKey] = null;
        }

        newErrors.pickUpDate = null;
        newErrors.pickUpTime = null;

        if (field === 'pickUpDateLocal') {
            newValues.pickUpTimeLocal = moment(
                moment(value as Date).format(DATE_FORMATS['YYYY-MM-DDT'])
                + moment(newValues.pickUpTimeLocal).format(TIME_FORMATS['HH:mm:ss'])
            ).toDate();
        }

        if (field === 'pickUpDate') {
            newValues.pickUpTime = moment(
                moment(value as Date).format(DATE_FORMATS['YYYY-MM-DDT'])
                + moment(newValues.pickUpTime).format(TIME_FORMATS['HH:mm:ss'])
            ).toDate();
        }

        setRideValues(newValues);
        setErrors(newErrors);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ENTER) {
            handleFlightNumberFinishing();
        }
    };

    const flightDepartureDateTime = useMemo(() => {
        const dateTime = isUtc
            ? rideValues.flightDetails.departure?.datetimeZulu
            : rideValues.flightDetails.departure?.datetimeLocal;

        return dateTime && moment(dateTime).utc()
            .format(DATE_FORMATS['DD MMM at HH:mm A']);
    }, [isUtc, rideValues.flightDetails.departure?.datetimeLocal, rideValues.flightDetails.departure?.datetimeZulu]);

    const flightArrivalDateTime = useMemo(() => {
        const dateTime = isUtc ? rideValues.flightDetails.arrival?.datetimeZulu : rideValues.flightDetails.arrival?.datetimeLocal;

        return dateTime && moment(dateTime).utc()
            .format(DATE_FORMATS['DD MMM at HH:mm A']);
    }, [isUtc, rideValues.flightDetails.arrival?.datetimeLocal, rideValues.flightDetails.arrival?.datetimeZulu]);

    const airlineAbrevation = useMemo(() => rideValues.flightNumber.replace(/[^a-zA-Z]/g, ''), [rideValues.flightNumber]);

    const handleImageError = () => {
        setImageError(true);
    };

    const flightIcon = useMemo(() => {
        if (isFlightTracked) {
            return airlineAbrevation && flightDepartureDateTime && !imageError
                ? (
                    <img
                        src={getAirlineIataImage(
                            airlineAbrevation,
                            AIRLINE_IMAGE_WIDTH,
                            AIRLINE_IMAGE_HEIGHT
                        )}
                        onError={handleImageError}
                        alt="Airline"
                    />
                )
                : (
                    <FlightIcon />
                );
        }

        return <AirplanemodeInactiveOutlinedIcon />;
    }, [airlineAbrevation, flightDepartureDateTime, imageError, isFlightTracked]);

    return (
        <>
            <Box sx={{ marginBottom: '1rem' }}>
                <Typography
                    style={{
                        fontWeight: 700,
                        color: COLORS.BLUE_DARK,
                    }}
                >
                    {t('rideInformation')}
                </Typography>
            </Box>
            <Grid item rowGap=".75rem" display="flex" flexDirection="column">
                <Grid gap={4} display="flex" flexDirection={isMobile ? 'column' : 'row'} marginBottom="1.2rem">
                    <Grid item xs={12} md={6} lg={6}>
                        <SearchLocationComponent
                            error={errors.pickUpLocation}
                            helperText={(errors.pickUpLocation && t(errors.pickUpLocation)) ?? ''}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            address={rideValues.pickUpLocation}
                            setAddress={(newValue: SearchLocation | null) => {
                                handleChangeValue('pickUpLocation', newValue);
                            }}
                            label={t('pickUpLocation')}
                            logEvent={() => logAmplitudeEvent(RIDE_INFORMATION_FROM, amplitudeEventProperties)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <SearchLocationComponent
                            error={errors.dropOffLocation}
                            helperText={(errors.dropOffLocation && t(errors.dropOffLocation)) ?? ''}
                            inputValue={inputValueDropOff}
                            setInputValue={setInputValueDropOff}
                            address={rideValues.dropOffLocation}
                            setAddress={(newValue: SearchLocation | null) => {
                                handleChangeValue('dropOffLocation', newValue);
                            }}
                            label={t('dropOffLocation')}
                            logEvent={() => logAmplitudeEvent(RIDE_INFORMATION_TO, amplitudeEventProperties)}
                        />
                    </Grid>
                </Grid>
                <Grid gap={isMobile ? 2 : 4} display="flex" flexDirection={isMobile ? 'column' : 'row'}>
                    <Tooltip
                        title={
                            isUtc
                                ? moment(rideValues.pickUpDate).format(DATE_FORMATS['DD MMM yyyy'])
                                : moment(rideValues.pickUpDateLocal).format(DATE_FORMATS['DD MMM yyyy'])
                        }
                        placement="top"
                        arrow
                    >
                        <Grid item xs={12} md={3} lg={3}>
                            <DatePicker
                                sx={{ width: '100%' }}
                                label={t(isUtc ? 'pages.singleRide.fields.pickUpDateUtc' : 'pages.singleRide.fields.pickUpDate')}
                                value={isUtc ? rideValues.pickUpDate : rideValues.pickUpDateLocal}
                                onChange={newValue => handleChangeValue(isUtc ? 'pickUpDate' : 'pickUpDateLocal', newValue)}
                                format={DATE_FORMATS['dd MMM yyyy']}
                                disabled={isDisabled}
                                className={formClasses.disabledField}
                                onClose={() => {
                                    logAmplitudeEvent(RIDE_INFORMATION_PICKUP_DATE, amplitudeEventProperties);
                                    setOpenDate(false);
                                }}
                                slotProps={{
                                    textField: {
                                        error: errors.pickUpDate !== null,
                                        helperText: errors.pickUpDate ? t(errors.pickUpDate) : '',
                                        variant: 'filled',
                                        required: true,
                                        inputProps: { readOnly: true },
                                        onClick: () => setOpenDate(true),
                                    },
                                }}
                                localeText={datePickerPlaceholder}
                                open={openDate}
                            />
                        </Grid>
                    </Tooltip>
                    <Tooltip
                        title={
                            isUtc
                                ? moment(rideValues.pickUpTime).format(TIME_FORMATS['HH:mm A'])
                                : moment(rideValues.pickUpTimeLocal).format(TIME_FORMATS['HH:mm A'])
                        }
                        placement="top"
                        arrow
                    >
                        <Grid item xs={12} md={3} lg={3}>
                            <TimePicker
                                sx={{ width: '100%' }}
                                label={t(isUtc ? 'pages.singleRide.fields.pickUpTimeUtc' : 'pages.singleRide.fields.pickUpTime')}
                                value={isUtc ? rideValues.pickUpTime : rideValues.pickUpTimeLocal}
                                onChange={newValue => handleChangeValue(isUtc ? 'pickUpTime' : 'pickUpTimeLocal', newValue)}
                                format={TIME_FORMATS['HH:mm a']}
                                disabled={isDisabled}
                                className={formClasses.disabledField}
                                onClose={() => {
                                    logAmplitudeEvent(RIDE_INFORMATION_CAR_PICKUP_TIME, amplitudeEventProperties);
                                    setOpenTime(false);
                                }}
                                slotProps={{
                                    textField: {
                                        error: errors.pickUpTime !== null,
                                        helperText: errors.pickUpTime ? t(errors.pickUpTime) : '',
                                        variant: 'filled',
                                        required: true,
                                        inputProps: { readOnly: true },
                                        onClick: () => setOpenTime(true),
                                    },
                                    mobilePaper: { className: classes.timeSepartor },
                                }}
                                ampm={false}
                                open={openTime}
                            />
                        </Grid>
                    </Tooltip>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField
                            label={t('passengers')}
                            required
                            type="number"
                            value={rideValues.travellers}
                            onChange={event => handleChangeValue('travellers', event.target.value)}
                            error={errors.travellers !== null}
                            helperText={errors.travellers && t(errors.travellers)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <PersonOutlineOutlinedIcon color={isDisabled ? 'disabled' : undefined} />
                                    </InputAdornment>
                                ),
                                inputProps: { min: 1 },
                            }}
                            disabled={isDisabled}
                            className={formClasses.disabledField}
                            onBlur={() =>
                            (initialRideValues?.travellers !== rideValues.travellers
                                ? logAmplitudeEvent(RIDE_INFORMATION_PASSENGERS, amplitudeEventProperties)
                                : {})
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField
                            label={t('luggages')}
                            required
                            type="number"
                            value={rideValues.luggages}
                            onChange={event => handleChangeValue('luggages', event.target.value)}
                            error={errors.luggages !== null}
                            helperText={errors.luggages && t(errors.luggages)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LuggageOutlinedIcon color={isDisabled ? 'disabled' : undefined} />
                                    </InputAdornment>
                                ),
                                inputProps: { min: 0 },
                            }}
                            disabled={isDisabled}
                            className={formClasses.disabledField}
                            onBlur={() =>
                            (initialRideValues?.luggages !== rideValues.luggages
                                ? logAmplitudeEvent(RIDE_INFORMATION_LUGGAGE, amplitudeEventProperties)
                                : {})
                            }
                        />
                    </Grid>
                </Grid>
                <Grid gap={isMobile ? 2 : 4} display="flex" flexDirection={isMobile ? 'column' : 'row'} marginBottom="1.25rem">
                    <Grid item xs={12} md={6} lg={6}>
                        <TextField
                            label={t('pages.singleRide.fields.flightNumber')}
                            helperText={!isFlightTracked && t('pages.singleRide.fields.flightNumberHelper')}
                            value={rideValues.flightNumber}
                            onChange={event => handleChangeValue('flightNumber', event.target.value)}
                            onBlur={handleFlightNumberFinishing}
                            onKeyDown={handleKeyDown}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isDisabled && isFlightTracked
                                            ? (
                                                <InputAdornment position="end">
                                                    <CopyToClipboardButton text={rideValues.specialRequest} />
                                                </InputAdornment>
                                            )
                                            : (
                                                <Tooltip
                                                    title={isFlightTracked
                                                        ? rideValues.flightDetails.airlineName
                                                        : t('pages.singleRide.fields.flightNotTracked')}
                                                    arrow
                                                    placement="top"
                                                >
                                                    {isLoadingFlight
                                                        ? (
                                                            <Spinner size={24} />
                                                        )
                                                        : (
                                                            flightIcon
                                                        )}
                                                </Tooltip>
                                            )}
                                    </InputAdornment>
                                ),
                            }}
                            disabled={isDisabled}
                            className={formClasses.disabledField}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        {isFlightTracked && flightDepartureDateTime && (
                            <Box className={classes.centerContainer}>
                                <IconedData
                                    icon={FlightTakeoffIcon}
                                    data={rideValues.flightDetails.departure?.iata}
                                    dataColor={COLORS.BLACK}
                                    tooltip={rideValues.flightDetails.departure?.name}
                                    dataTextBold
                                />
                                <Typography
                                    style={{
                                        fontSize: '.75rem',
                                        fontWeight: 700,
                                        color: COLORS.BLUE_DARK,
                                    }}
                                    marginBottom=".25rem"
                                    marginLeft="1rem"
                                >
                                    {flightDepartureDateTime} {isUtc ? '(UTC)' : ''}
                                </Typography>
                            </Box>
                        )}
                        {isFlightTracked && flightArrivalDateTime && (
                            <Box className={classes.centerContainer}>
                                <IconedData
                                    icon={FlightLandIcon}
                                    data={rideValues.flightDetails.arrival?.iata}
                                    dataColor={COLORS.BLACK}
                                    tooltip={rideValues.flightDetails.arrival?.name}
                                    dataTextBold
                                />
                                <Typography
                                    style={{
                                        fontSize: '.75rem',
                                        fontWeight: 700,
                                        color: COLORS.BLUE_DARK,
                                    }}
                                    marginBottom=".25rem"
                                    marginLeft="1rem"
                                >
                                    {flightArrivalDateTime} {isUtc ? '(UTC)' : ''}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <TextField
                        value={rideValues.specialRequest}
                        label={t('specialRequest')}
                        onChange={event => handleChangeValue('specialRequest', event.target.value)}
                        disabled={isDisabled}
                        multiline
                        className={formClasses.disabledField}
                        InputProps={{
                            endAdornment: isDisabled && (
                                <InputAdornment position="end">
                                    <CopyToClipboardButton text={rideValues.specialRequest} />
                                </InputAdornment>
                            ),
                        }}
                        onBlur={() =>
                        (initialRideValues?.specialRequest !== rideValues.specialRequest
                            ? logAmplitudeEvent(RIDE_INFORMATION_SPECIAL_REQUEST, amplitudeEventProperties)
                            : {})
                        }
                    />
                </Grid>
                {isReferenceVisible && (
                    <>
                        <Grid item key={rideValues.id}>
                            <TextField
                                label={t('reference')}
                                value={rideValues.customReference}
                                onChange={event => handleChangeValue('customReference', event.target.value)}
                                disabled={isDisabled}
                                className={formClasses.disabledField}
                                InputProps={{
                                    endAdornment: isDisabled && (
                                        <InputAdornment position="end">
                                            <CopyToClipboardButton text={rideValues.specialRequest} />
                                        </InputAdornment>
                                    ),
                                }}
                                onBlur={() =>
                                (initialRideValues?.customReference
                                    !== rideValues.customReference
                                    ? logAmplitudeEvent(RIDE_INFORMATION_REFERENCE, amplitudeEventProperties)
                                    : {})
                                }
                            />
                        </Grid>
                        {rideValues.customFields?.map(customField => {
                            const customFieldError = errors.customFields[customField.key] !== undefined
                                && errors.customFields[customField.key] !== null;

                            return (
                                <Grid item key={customField.id}>
                                    <TextField
                                        label={customField.label}
                                        value={customField.value}
                                        onChange={event => handleChangeValue('customFields', event.target.value, customField.key)}
                                        required={customField.isRequired}
                                        error={customFieldError}
                                        helperText={customFieldError ? t('form.helperText.empty') : ' '}
                                        disabled={isDisabled}
                                        className={formClasses.disabledField}
                                        InputProps={{
                                            endAdornment: isDisabled && (
                                                <InputAdornment position="end">
                                                    <CopyToClipboardButton text={rideValues.specialRequest} />
                                                </InputAdornment>
                                            ),
                                        }}

                                        onBlur={() =>
                                        (initialRideValues?.customFields[customField.key]
                                            !== rideValues.customFields[customField.key]
                                            ? logAmplitudeEvent(RIDE_INFORMATION_REFERENCE, amplitudeEventProperties)
                                            : {})
                                        }
                                    />
                                </Grid>
                            );
                        })}
                    </>
                )}
                <Grid item display="flex">
                    <Button
                        variant="outlined"
                        startIcon={isReferenceVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        sx={{ color: COLORS.BLUE }}
                        onClick={() => setIsReferenceVisible(!isReferenceVisible)}
                    >
                        {isReferenceVisible
                            ? t('pages.singleRide.fields.hideReferences')
                            : t('pages.singleRide.fields.showReferences')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default RideInformation;
