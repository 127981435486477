import { SCREEN } from './eventSourceKeys';

export const RIDES_PAGE = `${SCREEN} Rides list`;
export const SINGLE_RIDE_PAGE = `${SCREEN} Single Ride`;
export const NOT_AUTHORIZED_PAGE = `${SCREEN} 403 Not authorized`;
export const NOT_FOUND_PAGE = `${SCREEN} 404 Not found`;
export const TO_MANY_REQUEST_PAGE = `${SCREEN} 429 To many request`;
export const UNEXPECTED_ERROR_PAGE = `${SCREEN} Unexpected error`;
export const INVALID_PASSWORD_TOKEN_PAGE = `${SCREEN} Invalid password token`;
export const INVALID_INVITE_TOKEN_PAGE = `${SCREEN} Invalid invite token`;
export const INVALID_AUTH_CODE_PAGE = `${SCREEN} Invalid auth code`;
export const INVALID_COOKIE_PAGE = `${SCREEN} Invalid cookie`;
