import { BUTTON, FIELD, INPUT } from './eventSourceKeys';

const CHANGE_TIME_ROUTE_CAR = 'Change time, route and car:';
const RIDE_INFORMATION = 'Ride information:';
const CHANGE_PASSENGER_AND_RIDE_INFO = 'Change passenger and ride info:';
const PASSENGERS = 'Passengers:';

export const RIDE_INFORMATION_FROM = `${FIELD} ${CHANGE_TIME_ROUTE_CAR} From`;
export const RIDE_INFORMATION_TO = `${FIELD} ${CHANGE_TIME_ROUTE_CAR} To`;
export const RIDE_INFORMATION_PICKUP_DATE = `${FIELD} ${CHANGE_TIME_ROUTE_CAR} Pickup date`;
export const RIDE_INFORMATION_CAR_PICKUP_TIME = `${FIELD} ${CHANGE_TIME_ROUTE_CAR} Pickup time`;

export const RIDE_INFORMATION_PASSENGERS = `${INPUT} ${CHANGE_PASSENGER_AND_RIDE_INFO} ${RIDE_INFORMATION} Passengers`;
export const RIDE_INFORMATION_LUGGAGE = `${INPUT} ${CHANGE_PASSENGER_AND_RIDE_INFO} ${RIDE_INFORMATION} Pieces of luggage`;
export const RIDE_INFORMATION_SPECIAL_REQUEST = `${INPUT} ${CHANGE_PASSENGER_AND_RIDE_INFO} ${RIDE_INFORMATION} Special request`;
export const RIDE_INFORMATION_REFERENCE = `${INPUT} ${CHANGE_PASSENGER_AND_RIDE_INFO} 
${RIDE_INFORMATION} Customer reference number`;

export const RIDE_INFORMATION_VEHICLE_CARD = `${BUTTON} ${CHANGE_TIME_ROUTE_CAR} Select service level`;
export const RIDE_INFORMATION_FLIGHT_TRACKING = `${INPUT} Flight tracking: Flight number`;

export const PASSENGERS_MODAL_FIRST_NAME = `${INPUT} ${CHANGE_PASSENGER_AND_RIDE_INFO} ${PASSENGERS} First name`;
export const PASSENGERS_MODAL_LAST_NAME = `${INPUT} ${CHANGE_PASSENGER_AND_RIDE_INFO} ${PASSENGERS} Last name`;
export const PASSENGERS_MODAL_EMAIL = `${INPUT} ${CHANGE_PASSENGER_AND_RIDE_INFO} ${PASSENGERS} Email`;
export const PASSENGERS_MODAL_PHONE = `${INPUT} ${CHANGE_PASSENGER_AND_RIDE_INFO} ${PASSENGERS} Phone number`;
export const PASSENGERS_MODAL_ADD_PASSENGER = `${BUTTON} ${CHANGE_PASSENGER_AND_RIDE_INFO} ${PASSENGERS} Add passenger`;

export const UPDATE_RIDE = `${BUTTON} Update ride: Open modal`;
export const CANCEL_RIDE = `${BUTTON} Cancel ride: Open modal`;
export const CONFIRM_UPDATE = `${BUTTON} Save ride changes modal: Save`;
export const CONFIRM_CANCELLATION_RIDE = `${BUTTON} Cancel modal: Confirm cancellation`;

export const DONWLOAD_DOCUMENTATION = `${BUTTON} Download documentation`;
export const SEND_CONFIRMATION = `${BUTTON} Single ride: Send confirmation email`;
