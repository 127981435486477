/* eslint-disable @typescript-eslint/no-floating-promises */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import * as Sentry from '@sentry/browser';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './i18n';
import App from './App';
import config from './config';
import { SENTRY_IGNORE_ERRORS } from './constants/sentryIgnoreErrors';
import currentEnvironment from './environment/currentEnvironment';
import Environment from './environment/Environment';
import { initializeZendeskStyles } from './helpers/prefillZendeskConfig';
import ErrorBoundary from './pages/errorPages/ErrorBoundary';
import theme from './styles/theme';

if (currentEnvironment === Environment.Production) {
    Sentry.init({
        dsn: config.sentryDsn,
        ignoreErrors: SENTRY_IGNORE_ERRORS,
        beforeSend(event) {
            if (event.message && SENTRY_IGNORE_ERRORS.includes(event.message)) {
                return null;
            }

            return event;
        },
        environment: 'production',
        release: config.appVersion,
        logLevel: 1,
    });
}

if (currentEnvironment !== Environment.Production) {
    const favicon = document.getElementById('newFavicon') as HTMLLinkElement;

    favicon.href = `${config.appUrl}/logo_favicon_pink.ico`;
}

initializeZendeskStyles();

(async () => {
    const initialLdContext = {
        kind: 'user',
        key: 'test@get-e.com',
        name: 'get-e',
        email: 'test@get-e.com',
    };

    const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID ?? '',
        context: initialLdContext,
    });

    ReactDOM.render(
        <ThemeProvider theme={theme}>
            <ErrorBoundary message="logIn">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <LDProvider>
                        <App />
                    </LDProvider>
                </LocalizationProvider>
            </ErrorBoundary>
        </ThemeProvider>,
        document.getElementById('root'),
    );
})();

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
// ServiceWorker.unregister();
