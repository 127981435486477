import apiClient from '../../../../services/api';
import { SUMMARY_REPORT, DRIVERS_REPORT, SITUATIONS_REPORT, REVIEWS_REPORT } from '../../../../services/routes';
import {
    ReportParams,
    SummaryResponse,
    DriversResponse,
    SituationsResponse,
    ReviewsResponse,
} from './types';

export const listSummary = async (variables: ReportParams): Promise<SummaryResponse> => {
    const { data } = await apiClient.get<SummaryResponse>(SUMMARY_REPORT, {
        params: (({ startDate, endDate, accountId }) => ({
            startDate,
            endDate,
            accountId,
        }))(variables),
    });

    return data;
};

export const listDrivers = async (variables: ReportParams): Promise<DriversResponse> => {
    const { data } = await apiClient.get<DriversResponse>(DRIVERS_REPORT, {
        params: (({ startDate, endDate, accountId }) => ({
            startDate,
            endDate,
            accountId,
        }))(variables),
    });

    return data;
};

export const listSituations = async (variables: ReportParams): Promise<SituationsResponse> => {
    const { data } = await apiClient.get<SituationsResponse>(SITUATIONS_REPORT, {
        params: (({ startDate, endDate, accountId }) => ({
            startDate,
            endDate,
            accountId,
        }))(variables),
    });

    return data;
};

export const listReviews = async (variables: ReportParams): Promise<ReviewsResponse> => {
    const { data } = await apiClient.get<ReviewsResponse>(REVIEWS_REPORT, { params: variables });

    return data;
};
