import axios from 'axios';
import Cookies from 'js-cookie';

import config from '../config';
import { STATUS_403, STATUS_404, STATUS_429 } from '../constants/httpStatusCodes';
import { ERROR_NOT_AUTHORIZED, ERROR_NOT_FOUND, ERROR_TOO_MANY_REQUESTS } from './routes';

const apiClient = axios.create({
    baseURL: config.apiUrl,
    withCredentials: true,
    headers: { 'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') as string },
});

apiClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === STATUS_429) {
            window.location.href = ERROR_TOO_MANY_REQUESTS;
        } else if (error.response.status === STATUS_403) {
            window.location.href = ERROR_NOT_AUTHORIZED;
        } else if (error.response.status === STATUS_404) {
            window.location.href = ERROR_NOT_FOUND;
        } else {
            return Promise.reject(error);
        }
    },
);

export default apiClient;
