import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../modal/Modal';
import ContactUsContact from './ContactUsContent';

const useStyles = makeStyles({ confirmButton: { width: '100% !important' } });

interface ContactUsProps {
    isModalOpen: boolean;
    onClose: () => void;
}

const ContactUsModal = ({ isModalOpen, onClose }: ContactUsProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            onSubmit={onClose}
            title={t('contactUs')}
            confirmButtonLabel={t('contact.ok')}
            confirmButtonClassName={classes.confirmButton}
        >
            <ContactUsContact />
        </Modal>
    );
};

export default ContactUsModal;
