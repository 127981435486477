/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/quotes */
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AppBar,
  Chip,
  Drawer,
  Grid,
  IconButton,
  Link,
  Toolbar,
  useMediaQuery,
  useTheme,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import config from "../../config";
import { COLORS, getRandomProfileColor } from "../../constants/colors";
import { GET_E_PRIVACY, GET_E_TERMS_AND_CONDITIONS } from "../../constants/getEWebsites";
import { LOG_IN_NEW, SETTINGS } from "../../constants/urlPaths";
import {
  IS_LOGIN_WITH_NEW_PORTAL,
  PROFILE_COLOR,
  RIDES_FILTER,
  SUPPLIER_RIDES_FILTER,
} from "../../constants/windowStorageKeys";
import { useAuth } from "../../context/AuthenticatedUserContext";
import { useCurrentProfileContext } from "../../context/CurrentProfileContext";
import { Severity, useNotificationContext } from "../../context/NotificationContext";
import currentEnvironment from "../../environment/currentEnvironment";
import Environment from "../../environment/Environment";
import { useDocuments } from "../../pages/documents/api/useDocument";
import ContactUsModal from "../../pages/pageContainer/customer/ContactUsModal";
import EditUserProfileModal from "../../pages/users/components/EditUserProfileModal";
import { AccountType } from "../../services/types";
import containerStyles from "../../styles/Container";
import typographyStyles from "../../styles/Typography";
import { default as ContactUsModalSupplier } from "../contactUs/ContactUsModal";
import NavigationLogo from "../logos/NavigationLogo";
import { MenuListItem } from "../menuListItem/MenuListItem";
import CustomerList from "./customers/CustomerList";
import navigationStyles from "./NavigationMenu.styles";
import SupplierList from "./suppliers/SupplierList";

const useStyles = makeStyles({
  dropdownItem: {
    padding: ".5rem",
    "&:hover": { background: `${COLORS.WHITE_10} !important` },
    "& > div": { paddingLeft: ".2rem" },
  },
  closedItem: { padding: ".5rem" },
});

interface DropdownItem {
  icon: JSX.Element;
  label: string;
  onClick?: () => void;
  closed?: boolean;
}

const DropdownProfileItem = ({ icon, label, onClick, closed }: DropdownItem) => {
  const classes = useStyles();

  return (
    <Tooltip title={label} arrow placement="right" disableHoverListener={!closed}>
      <ListItemButton onClick={onClick} className={clsx(classes.dropdownItem, { [classes.closedItem]: closed })}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{label}</ListItemText>
      </ListItemButton>
    </Tooltip>
  );
};

interface NavigationMenuProps {
  closed: boolean;
  closeMenu: () => void;
  openMenu: () => void;
  role: AccountType;
}

const NavigationMenu = ({ closed, closeMenu, openMenu, role }: NavigationMenuProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOpenMyProfileModal, setIsOpenMyProfileModal] = useState(false);
  const [isOpenSupportModal, setIsOpenSupportModal] = useState(false);
  const classes = navigationStyles();
  const { subtitle1Override, whiteLink } = typographyStyles();
  const { companyInformationContainer } = containerStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { currentProfile, refetchUserProfile } = useCurrentProfileContext();
  const { onLogout } = useAuth();
  const history = useHistory();
  const { showNotification } = useNotificationContext();
  const { data: documents } = useDocuments();
  const { useSettingsPage } = useFlags();

  useEffect(() => {
    setIsDropdownOpen(closed);
  }, [closed]);

  const handleUserProfileUpdate = async () => {
    await refetchUserProfile();
  };

  const onMobileClick = (): void => {
    if (mobileView) {
      closeMenu();
    }
  };

  const getNavigation = (userRole: AccountType): JSX.Element => {
    switch (userRole) {
      case AccountType.SUPPLIER:
        return <SupplierList showDocuments={documents.length > 0} />;
      case AccountType.CUSTOMER:
        return <CustomerList showDocuments={documents.length > 0} />;
      default:
        throw new Error("Unhandled user role");
    }
  };

  const logOut = async (): Promise<void> => {
    await fetch(config.deleteCookieUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          window.location.href = config.logOutUrl;

          return;
        }

        throw new Error(`Unexpected response status: ${response.status}`);
      })
      .catch(error => {
        showNotification(t("alert.logOutError"), Severity.Error);
      });
    localStorage.removeItem(IS_LOGIN_WITH_NEW_PORTAL);
    localStorage.removeItem(RIDES_FILTER);
    localStorage.removeItem(SUPPLIER_RIDES_FILTER);
  };

  const logOutNew = async () => {
    try {
      await onLogout();
      history.push(LOG_IN_NEW);
    } catch (error) {
      showNotification(t("alert.logOutError"), Severity.Error);
    }

    localStorage.removeItem(RIDES_FILTER);
    localStorage.removeItem(SUPPLIER_RIDES_FILTER);
    localStorage.removeItem(IS_LOGIN_WITH_NEW_PORTAL);
  };

  const backgroundProfileColor = localStorage.getItem(PROFILE_COLOR)?.toString();

  if (!backgroundProfileColor) {
    localStorage.setItem(PROFILE_COLOR, getRandomProfileColor());
  }

  const handleLogout = () => {
    const isLoginWithNewPortal = localStorage.getItem(IS_LOGIN_WITH_NEW_PORTAL);

    if (isLoginWithNewPortal) {
      return logOutNew();
    }

    return logOut();
  };

  const getUserInitials = () => {
    if (currentProfile?.firstName && currentProfile?.lastName) {
      return `${currentProfile.firstName[0].toUpperCase()}${currentProfile.lastName[0].toUpperCase()}`;
    }

    if (currentProfile?.firstName) {
      return `${currentProfile.firstName[0].toUpperCase()}${currentProfile.firstName[1]?.toUpperCase() || ''}`;
    }

    if (currentProfile?.lastName) {
      return `${currentProfile.lastName[0].toUpperCase()}${currentProfile.lastName[1]?.toUpperCase() || ''}`;
    }

    return `${currentProfile?.email?.substring(0, 2).toUpperCase()}`;
  };

  return (
    <>
      {mobileView && (
        <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: !closed })}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <IconButton
              color="default"
              aria-label="open drawer"
              onClick={() => openMenu()}
              data-testid="open-menu-button"
              edge="start"
              className={clsx(classes.menuButton, { [classes.hide]: !closed })}
            >
              <MenuIcon style={{ color: COLORS.BLUE }} />
            </IconButton>
            <IconButton color="default" onClick={() => setIsOpenSupportModal(true)} className={!closed ? classes.hide : ""}>
              <HelpIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: !closed,
          [classes.drawerClose]: closed,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: !closed,
            [classes.drawerClose]: closed,
          }),
        }}
        variant="permanent"
        anchor="left"
        open={!closed}
        data-testid={`navigation-menu-${closed ? "closed" : "open"}`}
      >
        <div className={classes.toolbar}>
          {currentEnvironment !== Environment.Production && <Chip label={Environment[currentEnvironment]} />}
          <IconButton
            onClick={() => (closed ? openMenu() : closeMenu())}
            title={`menu-${closed ? "open" : "close"}`}
            aria-label="close drawer"
            data-testid="desktop-menu-toggle"
          >
            {closed ? <ChevronRightIcon /> : mobileView ? <CloseIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Grid container direction="column" justifyContent="space-between">
          <Grid item>
            <div className={clsx(classes.separateListContainer, classes.separateContainer, { [classes.closedItem]: closed })}>
              {
                <ListItemButton
                  className={clsx({
                    [classes.profileDropdown]: true,
                    [classes.selectedItem]: isDropdownOpen && !closed,
                  })}
                  onClick={() => {
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                >
                  <ListItemIcon className={classes.profilePhoto} style={{ background: backgroundProfileColor }}>
                    {getUserInitials()}
                  </ListItemIcon>
                  <ListItemText>{t("authentication.account")}</ListItemText>
                  <ListItemIcon style={{ justifyContent: "flex-end" }}>
                    {isDropdownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  </ListItemIcon>
                </ListItemButton>
              }

              {isDropdownOpen && (
                <MenuList style={{ padding: 0 }}>
                  {useSettingsPage && (
                    <DropdownProfileItem
                      label={t("authentication.settings")}
                      icon={<SettingsIcon fontSize="small" />}
                      onClick={() => history.push(SETTINGS)}
                      closed={closed}
                    />
                  )}
                  <DropdownProfileItem
                    label={t("authentication.myProfile")}
                    icon={<AccountCircleIcon fontSize="small" />}
                    onClick={() => setIsOpenMyProfileModal(true)}
                    closed={closed}
                  />
                  <DropdownProfileItem
                    label={t("authentication.logOut")}
                    icon={<LogoutIcon fontSize="small" />}
                    onClick={handleLogout}
                    closed={closed}
                  />
                </MenuList>
              )}
            </div>
            <div onClick={() => onMobileClick()} className={clsx({ [classes.closedList]: closed })}>
              {getNavigation(role)}
            </div>
            <div className={clsx(classes.separateListContainer, classes.separateContainer, { [classes.closedItem]: closed })}>
              <MenuListItem
                icon={<HelpIcon fontSize="small" />}
                label={t("navigation.supplier.support")}
                href={window.location.pathname}
                onClick={() => setIsOpenSupportModal(true)}
                disableMathcingPath
              />
            </div>
          </Grid>
          <Grid item className={companyInformationContainer}>
            <NavigationLogo closed={closed} />
            {!closed && (
              <>
                <Link className={`${subtitle1Override} ${whiteLink}`} href={GET_E_PRIVACY} target="_blank">
                  <strong>{t("accountActivation.privacyPolicy")}</strong>
                  <span className="sr-only">{t("screenReader.newWindow")}</span>
                </Link>
                <br />
                <Link className={`${subtitle1Override} ${whiteLink}`} href={GET_E_TERMS_AND_CONDITIONS} target="_blank">
                  <strong>{t("accountActivation.termsAndConditions")}</strong>
                  <span className="sr-only">{t("screenReader.newWindow")}</span>
                </Link>
              </>
            )}
          </Grid>
        </Grid>
      </Drawer>
      {isOpenSupportModal && currentProfile.accountType === AccountType.CUSTOMER && (
        <ContactUsModal onClose={() => setIsOpenSupportModal(false)} />
      )}
      {currentProfile.accountType === AccountType.SUPPLIER && (
        <ContactUsModalSupplier isModalOpen={isOpenSupportModal} onClose={() => setIsOpenSupportModal(false)} />
      )}
      {isOpenMyProfileModal && currentProfile && (
        <EditUserProfileModal
          user={currentProfile}
          onClose={() => setIsOpenMyProfileModal(false)}
          onUserProfileUpdated={handleUserProfileUpdate}
        />
      )}
    </>
  );
};

export default NavigationMenu;
