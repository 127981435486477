/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/init-declarations */
import { Autocomplete } from '@get-e/react-components';
import StarIcon from '@mui/icons-material/Star';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import Modal from '../../../../components/modal/Modal';
import { SINGLE_RIDE_DRIVER_SAVE } from '../../../../constants/amplitude/supplierKeys';
import { COLORS } from '../../../../constants/colors';
import { Severity, useNotificationContext } from '../../../../context/NotificationContext';
import { InputError } from '../../../../helpers/inputValidation/InputError';
import { useDrivers } from '../../../drivers/api';
import { Driver } from '../../../drivers/api/types';
import { RideAmplitudeEventProperties, RideResponse } from '../../../ride/api/types';
import { NO_RESULTS_DRIVER_OPTION } from '../../../ride/supplier/hooks/useSupplierDriverInformation';
import { assignDriverToRide } from '../api';

const useStyles = makeStyles({
    formField: {
        marginBottom: '1.25rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    modalContent: { '& .MuiDialog-paper': { overflow: 'visible' } },
});

interface ChangeDriverModalProps {
    isModalOpen: boolean;
    onClose: () => void;
    rideId: string;
    initialDriver: Driver;
    onFinished: (updatedRide?: RideResponse) => void;
    amplitudeEventProperties?: RideAmplitudeEventProperties;
}

const ChangeDriverModalRidesOverview = ({
    isModalOpen,
    onClose,
    rideId,
    initialDriver,
    onFinished,
    amplitudeEventProperties,
}: ChangeDriverModalProps) => {
    const classes = useStyles();
    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();
    const [searchPhrase, setSearchPhrase] = useState('');
    const [driverInformation, setDriverInformation] = useState<Driver | null>(initialDriver);
    const [driverError, setDriverError] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const { data: drivers } = useDrivers();

    const driverInformationOptions: Driver[] = useMemo(
        () => drivers.filter(driverInformationItem => !driverInformationItem.isDeactivated)
        , [drivers]
    );

    const handleSubmit = async () => {
        if (!driverInformation) {
            setDriverError(InputError.Empty);
            return;
        }

        setIsSaving(true);

        let rideResponse: RideResponse | undefined;

        await assignDriverToRide({
            driverId: (driverInformation?.id)?.toString(),
            rideId,
        }).then(({ data }) => {
            showNotification(t('alert.edit.success'), Severity.Info);
            logAmplitudeEvent(SINGLE_RIDE_DRIVER_SAVE, amplitudeEventProperties);
            rideResponse = data;
        })
            .catch(error => {
                showNotification(t('alert.edit.error'), Severity.Error);
            })
            .finally(() => {
                setIsSaving(false);
                onClose();
                onFinished(rideResponse);
            });
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            maxWidth="md"
            onSubmit={handleSubmit}
            title={t('pages.rides.changeDriver')}
            confirmButtonLabel={t('buttonName.update')}
            cancelButtonLabel={t('buttonName.back')}
            modalContentClassName={classes.modalContent}
            isDisabled={isSaving}
        >
            <Autocomplete
                label={t('pages.rides.nameOrTaxiVehicleNumber')}
                options={driverInformationOptions}
                getOptionLabel={(option: Driver) => option.name}
                filterOptions={options => {
                    if (options.length === 1) {
                        options.unshift(NO_RESULTS_DRIVER_OPTION);
                    }

                    const searchTerm = searchPhrase.toLowerCase().trim();

                    const filteredDrivers = options.filter(
                        driver => driver.name.toLowerCase().includes(searchTerm)
                            || driver.phoneNumber.includes(searchTerm)
                    );

                    return filteredDrivers;
                }}
                renderOption={(prop, option: Driver) => {
                    const averageRate = option.feedbacks?.averageRate
                        ? option.feedbacks?.averageRate.toFixed(1).toString()
                        : '';

                    const count = option.feedbacks?.count ?? '';

                    return (
                        <Box
                            key={option.id}
                            component="li"
                            {...prop}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                color: COLORS.SLATE_GREY,
                                padding: '.75rem 1rem',
                            }}
                        >
                            <Typography marginRight="0.2rem" color={COLORS.BLACK}>
                                {option.name}  ({option.phoneNumber})
                            </Typography>
                            <Typography marginRight="0.2rem">{averageRate}</Typography>
                            {count !== '' && count !== 0 && (
                                <>
                                    <StarIcon
                                        style={{
                                            fontSize: '1.25rem',
                                            marginRight: '0.2rem',
                                        }}
                                    />
                                    <Typography>({option.feedbacks?.count})</Typography>
                                </>
                            )}
                        </Box>
                    );
                }}
                value={driverInformation}
                onChange={(_, newValue) => {
                    setDriverInformation(newValue as Driver);
                    setDriverError('');
                }}
                required
                className={classes.formField}
                inputValue={searchPhrase}
                onInputChange={(_, newInputValue) => {
                    setSearchPhrase(newInputValue);
                    setDriverError('');
                }}
                isError={driverError !== ''}
                helperText={driverError ? t(driverError) : ''}
            />
        </Modal>
    );
};

export default ChangeDriverModalRidesOverview;
