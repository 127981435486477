import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import {
    USE_VEHICLES,
    USE_BRANDS,
    USE_MODELS,
    USE_VEHICLE,
    USE_DRIVER_VEHICLES,
    USE_RIDE_VEHICLE,
} from '../../../constants/queryKeys';
import { useDebounce } from '../../../hooks/useDebounce';
import apiClient from '../../../services/api';
import {
    getSearchBrands, getSearchVehicles, getFindVehicleRoute,
    VEHICLE_MODELS, VEHICLES,
    getVehicleRoute,
    getDriverVehiclesRoute,
    getRideVehicleRoute,
} from '../../../services/routes';
import { ColorOption, getColorOption } from '../colorOptions';
import { Entity, Vehicle, VehicleUpdateVariables, VehicleVariables } from './types';

interface Response<T> {
    data: T;
}

export const useVehicles = (search: string, limit?: number) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery(
        [USE_VEHICLES + search],
        () => getVehicles(search, limit),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: false,
        }
    );

    return {
        data: data ?? [],
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

const getVehicles = async (search: string, limit?: number): Promise<Vehicle[]> => {
    const response = await apiClient.get<Response<Vehicle[]>>(getSearchVehicles(search, limit));

    return response.data?.data;
};

export function useBrands(search: string, limit?: number) {
    return useQuery([USE_BRANDS + search], () => getBrands(search, limit), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        cacheTime: 500,
    });
}

const getBrands = async (search: string, limit?: number): Promise<Entity[]> => {
    const response = await apiClient.get<Response<Entity[]>>(getSearchBrands(search, limit));

    return response.data?.data;
};

export function useModels(search: string, brandId?: number, limit?: number) {
    return useQuery([USE_MODELS + search], () => getModels(search, brandId, limit), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        cacheTime: 500,
        enabled: Boolean(brandId) || Boolean(search),
    });
}

const getModels = async (search: string, brandId?: number, limit?: number): Promise<Entity[]> => {
    const response = await apiClient.get<Response<Entity[]>>(VEHICLE_MODELS, {
        params: {
            search,
            brand_id: brandId,
        },
    });

    return response.data?.data;
};

export const createVehicle = async (variables: VehicleVariables): Promise<AxiosResponse> => {
    const response = await apiClient.post<Response<AxiosResponse>>(VEHICLES, variables);

    return response;
};

export const updateVehicle = async (variables: VehicleUpdateVariables): Promise<AxiosResponse> => {
    const response = await apiClient.put<Response<AxiosResponse>>(getVehicleRoute(variables.id), variables);

    return response;
};

export const archiveVehicle = async (id: number): Promise<AxiosResponse> => {
    const response = await apiClient.put<Response<AxiosResponse>>(`${getVehicleRoute(id)}/archive`);

    return response;
};

export const restoreVehicle = async (id: number): Promise<AxiosResponse> => {
    const response = await apiClient.put<Response<AxiosResponse>>(`${getVehicleRoute(id)}/restore`);

    return response;
};

export function useFindVehicleByLicensePlate(search: string, isDisabled?: boolean) {
    const debouncedSearchQuery = useDebounce(search, 400);

    return useQuery([USE_VEHICLE + debouncedSearchQuery], () => findVehicle(debouncedSearchQuery), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        cacheTime: 500,
        enabled: Boolean(debouncedSearchQuery) && isDisabled,
    });
}

export const findVehicle = async (search: string): Promise<Vehicle> => {
    const response = await apiClient.get<Response<Vehicle>>(getFindVehicleRoute(search));

    return response.data?.data;
};

export function useVehicle(vehicleId?: number | null, uniqueId?: Date | string) {
    const { data, isLoading, isError, isFetching, refetch, isFetched }
     = useQuery([USE_VEHICLE + uniqueId], () => getVehicle(vehicleId ?? -1), {
         keepPreviousData: false,
         refetchOnWindowFocus: false,
         cacheTime: 500,
         enabled: Boolean(vehicleId),
     });

    return {
        data: data
            ? {
                ...data,
                color: getColorOption(data?.color as string) as ColorOption,
            }
            : null,
        isLoading,
        isError,
        isFetching,
        refetch,
        isFetched,
    };
}

export const getVehicle = async (vehicleId: number): Promise<Vehicle> => {
    const response = await apiClient.get<Response<Vehicle>>(getVehicleRoute(vehicleId));

    return response.data?.data;
};

export function useDriverVehicles(driverId: number) {
    return useQuery([USE_DRIVER_VEHICLES], () => getDriverVehicles(driverId), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        cacheTime: 500,
        enabled: Boolean(driverId),
    });
}

const getDriverVehicles = async (driverId: number): Promise<Vehicle[]> => {
    const response = await apiClient.get<Response<Vehicle[]>>(getDriverVehiclesRoute(driverId));

    return response.data?.data;
};

export function useRideVehicle(rideId: string) {
    return useQuery([USE_RIDE_VEHICLE], () => getRideVehicle(rideId), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        cacheTime: 500,
        enabled: Boolean(rideId),
    });
}

const getRideVehicle = async (rideId: string): Promise<Vehicle | null> => {
    const response = await apiClient.get<Response<Vehicle>>(getRideVehicleRoute(rideId));

    return response.data.data
        ? {
            ...response.data?.data,
            color: getColorOption(response.data?.data?.color as string) as ColorOption || {
                id: '',
                color: '',
                name: '',
            },
        }
        : null;
};
