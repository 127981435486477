/* eslint-disable @typescript-eslint/no-shadow */
import { AxiosError } from 'axios';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { USE_AVAILABILITIES } from '../../../../constants/queryKeys';
import apiClient from '../../../../services/api';
import { RIDE_AVAILABILITIES } from '../../../../services/routes';
import { AvailabilitiesResponse, UpdateAvailabilites, VehicleResponse } from '../../api/types';
import { Vehicle } from '../../components/VehicleInformationCards';

const mapResponseToVehicle = (response: VehicleResponse): Vehicle => ({
    id: response.uuid,
    vehicleClass: response.vehicleClass,
    vehicleType: response.vehicleType,
    vehicleImage: response.vehicleImage,
    travellers: response.vehicleSeatsNumber.toString(),
    luggages: response.vehicleBagsCapacity.toString(),
    price: response.price,
    priceCurrency: response.currency,
});

export function useAvailabilites(variables: UpdateAvailabilites) {
    const [errorCode, setErrorCode] = useState<number | null>(0);

    const { data, isLoading, refetch, error, isRefetching, isFetching, isFetched }
        = useQuery([USE_AVAILABILITIES], () => getAvailabilites(variables), {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            retry: false,
            cacheTime: 500,
            enabled: false,
            onError: (error: AxiosError) => setErrorCode(error?.response?.status ?? null),
            onSuccess: () => setErrorCode(null),
        });

    return useMemo(() => {
        const vehicleList
            = errorCode ? [] : data?.availabilities?.map((response: VehicleResponse) => mapResponseToVehicle(response)) ?? [];

        const newEstimateDistanceAndDuration = errorCode
            ? undefined
            : data?.estimationDetails;

        return {
            vehicleList,
            newEstimateDistanceAndDuration,
            isLoading,
            refetch,
            error,
            isRefetching,
            isFetching,
            isFetched,
        };
    }, [data, errorCode, isLoading, refetch, error, isRefetching, isFetching, isFetched]);
}

export const getAvailabilites = async (variables: UpdateAvailabilites): Promise<AvailabilitiesResponse> => {
    const { data } = await apiClient.get<AvailabilitiesResponse>(RIDE_AVAILABILITIES, { params: variables });

    return data;
};
