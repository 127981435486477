import { Form, TextField } from '@get-e/react-components';
import { AxiosError, AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation as useReactQueryMutation } from 'react-query';

import ShowPasswordButton from '../../../components/buttons/showPasswordButton/ShowPasswordButton';
import Modal from '../../../components/modal/Modal';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { InputError } from '../../../helpers/inputValidation/InputError';
import and from '../../../helpers/inputValidation/validators/and';
import isEmail from '../../../helpers/inputValidation/validators/isEmail';
import isEqualString from '../../../helpers/inputValidation/validators/isEqualString';
import isFilledString from '../../../helpers/inputValidation/validators/isFilledString';
import isPasswordStrong from '../../../helpers/inputValidation/validators/isPasswordStrong';
import useFormStyles from '../../../styles/Form';
import { updateUserProfile } from '../api';
import { User } from '../api/types';

interface EditUserProfileFields {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

interface EditUserProfileError {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    password: string | null;
    confirmPassword: string | null;
}

interface EditUserProfileModalProps {
    user: User;
    onClose: () => void;
    onUserProfileUpdated?: () => Promise<void>;
}

const EditUserProfileModal = ({ user, onClose, onUserProfileUpdated }: EditUserProfileModalProps) => {
    const formClasses = useFormStyles();
    const { t } = useTranslation();
    const [showingPassword, setShowingPassword] = useState(false);
    const [showingConfirmPassword, setShowingConfirmPassword] = useState(false);
    const { showNotification } = useNotificationContext();

    const [values, setValues] = useState<EditUserProfileFields>({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        password: '',
        confirmPassword: '',
    });

    const [formErrors, setFormErrors] = useState<EditUserProfileError>({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        confirmPassword: null,
    });

    const { mutate: updateUserProfileMutation, isLoading } = useReactQueryMutation(updateUserProfile, {
        onSuccess: () => {
            onUserProfileUpdated && onUserProfileUpdated();
            onClose();
        },
        onError: (error: AxiosError) => {
            onUserProfileUpdated && onUserProfileUpdated();
            handleServerErrors(error);
        },
    });

    const handleServerErrors = (errors: AxiosError) => {
        const serverErrors = (errors.response as AxiosResponse).data.errors;

        const fieldErrors: EditUserProfileError = {
            firstName: serverErrors.firstName ? serverErrors.firstName[0] : null,
            lastName: serverErrors.lastName ? serverErrors.lastName[0] : null,
            email: serverErrors.email ? serverErrors.email[0] : null,
            password: serverErrors.password ? serverErrors.password[0] : null,
            confirmPassword: serverErrors.confirmPassword ? serverErrors.confirmPassword[0] : null,
        };

        setFormErrors({ ...fieldErrors });
    };

    const handleUpdateProfile = () => {
        updateUserProfileMutation({
            email: values.email.trim(),
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            password: values.password.trim(),
            password_confirmation: values.confirmPassword.trim(),
        });

        showNotification(t('modals.edit.editSuccessMessage'), Severity.Info);
    };

    const handleSubmit = () => {
        if (!validateFields()) {
            return;
        }

        handleUpdateProfile();
    };

    const handleChange = <T extends keyof EditUserProfileFields>(key: T, newValue: EditUserProfileFields[T] & string): void => {
        setValues({
            ...values,
            [key]: newValue,
        });
        setFormErrors(prevStateForm => ({
            ...prevStateForm,
            [key]: null,
        }));
    };

    const validateFields = (): boolean => {
        const shouldValidatePassword = values.password.trim() !== '';

        const validated = {
            firstName: isFilledString(values.firstName, InputError.Empty),
            lastName: isFilledString(values.lastName, InputError.Empty),
            email: and(isFilledString(values.email, InputError.Empty), () => isEmail(values.email, InputError.InvalidEmail)),
            password: isPasswordStrong(values.password, InputError.NotStrongPassword),
            confirmPassword: isEqualString(values.confirmPassword, values.password, InputError.NoMatch),
        };

        const fieldErrors: EditUserProfileError = {
            firstName: validated.firstName.isValid ? null : t(validated.firstName.error),
            lastName: validated.lastName.isValid ? null : t(validated.lastName.error),
            email: validated.email.isValid ? null : t(validated.email.error),
            password: validated.password.isValid || !shouldValidatePassword ? null : validated.password.error,
            confirmPassword: validated.confirmPassword.isValid || !shouldValidatePassword
                ? null
                : validated.confirmPassword.error,
        };

        const isValid = Object.values(fieldErrors).every(error => error === null);

        !isValid && setFormErrors(fieldErrors);

        return isValid;
    };

    return (
        <Modal
            isOpen
            title={t('modals.edit.editMyProfile')}
            onClose={onClose}
            onSubmit={handleSubmit}
            confirmButtonLabel={t('buttonName.edit')}
            cancelButtonLabel={t('buttonName.cancel')}
            isDisabled={isLoading}
            maxWidth="sm"
        >
            <Form className={formClasses.root} autoComplete="off" noValidate onSubmit={handleSubmit}>
                <TextField
                    className={formClasses.textField}
                    error={formErrors.firstName !== null}
                    helperText={formErrors.firstName && formErrors.firstName}
                    label={t('form.fields.firstName')}
                    name="firstName"
                    onChange={event => handleChange('firstName', event.target.value)}
                    required
                    value={values.firstName}
                />
                <TextField
                    className={formClasses.textField}
                    error={formErrors.lastName !== null}
                    helperText={formErrors.lastName && formErrors.lastName}
                    label={t('form.fields.lastName')}
                    name="lastName"
                    onChange={event => handleChange('lastName', event.target.value)}
                    required
                    value={values.lastName}
                />
                <TextField
                    className={formClasses.textField}
                    error={formErrors.email !== null}
                    inputProps={{ autoCapitalize: 'none' }}
                    helperText={formErrors.email && formErrors.email}
                    label={t('email')}
                    name="email"
                    onChange={event => handleChange('email', event.target.value)}
                    required
                    value={values.email}
                />
                <TextField
                    label={t('form.fields.passwordFillToChange')}
                    type={showingPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={event => handleChange('password', event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <ShowPasswordButton
                                onShowPassword={password => {
                                    setShowingPassword(password);
                                }}
                            />
                        ),
                        autoComplete: 'new-password',
                    }}
                    error={formErrors.password !== null}
                    helperText={formErrors.password ? t(formErrors.password) : null}
                    required
                    className={formClasses.textField}
                />
                <TextField
                    id="edit-user-confirm-password"
                    label={t('form.fields.confirmPassword')}
                    type={showingConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    onChange={event => handleChange('confirmPassword', event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <ShowPasswordButton
                                onShowPassword={password => {
                                    setShowingConfirmPassword(password);
                                }}
                            />
                        ),
                        autoComplete: 'confirm-new-password',
                    }}
                    error={formErrors.confirmPassword !== null}
                    helperText={formErrors.confirmPassword ? t(formErrors.confirmPassword) : null}
                    required
                    className={formClasses.textField}
                />
            </Form>
        </Modal>
    );
};

export default EditUserProfileModal;
