import { PortalLocale } from '../context/LocaleContext';
import supportedLocales from './supportedLocales';

export default function findSupportedLocale(
    preferredLocales: string[],
): PortalLocale | null {
    for (const preferredLocale of preferredLocales) {
        for (const supportedLocale of supportedLocales) {
            if (preferredLocale.toLowerCase() === supportedLocale.locale.toLowerCase()) {
                return supportedLocale.code;
            }
        }
    }

    return 'en-GB';
}
