import { Dispatch, SetStateAction, useState } from 'react';

import { InputError } from '../../../../helpers/inputValidation/InputError';
import { Validated } from '../../../../helpers/inputValidation/Validator';
import and from '../../../../helpers/inputValidation/validators/and';
import isEmail from '../../../../helpers/inputValidation/validators/isEmail';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import {
    initialTraveller,
    initialTravellerErrors,
    Traveller,
    TravellerError,
} from '../../components/travellerInformation/TravellerInformation';

const useTravellerInformation = (): {
    travellerValues: Traveller[];
    setTravellerValues: Dispatch<SetStateAction<Traveller[]>>;
    travellerErrors: TravellerError[];
    setTravellerErrors: Dispatch<SetStateAction<TravellerError[]>>;
    validateTravellerFields: () => boolean;
} => {
    const [values, setValues] = useState<Traveller[]>([initialTraveller]);

    const [errors, setErrors] = useState<TravellerError[]>([initialTravellerErrors]);

    const validateFields = () => {
        const fieldErrors: TravellerError[] = values.map(traveller => {
            const { isLead } = traveller;

            const validatedFirstName = isFilledString(
                traveller.firstName,
                InputError.Empty
            );

            const validatedLastName = isFilledString(
                traveller.lastName,
                InputError.Empty
            );

            let validatedPhone = {
                isValid: true,
                value: '',
            } as Validated<string, InputError>;

            if (isLead) {
                validatedPhone = isFilledString(
                    traveller.phone,
                    InputError.Empty
                );
            }

            const validatedEmail = and(
                isFilledString(traveller.email, InputError.Empty),
                () => isEmail(traveller.email, InputError.InvalidEmail)
            );

            return {
                id: traveller.id,
                firstNameError: validatedFirstName.isValid
                    ? null
                    : validatedFirstName.error,
                lastNameError: validatedLastName.isValid
                    ? null
                    : validatedLastName.error,
                emailError: validatedEmail.isValid ? null : validatedEmail.error,
                phoneError: validatedPhone.isValid ? null : validatedPhone.error,
            };
        });

        setErrors(fieldErrors);

        const hasErrors = fieldErrors.some(error => (
            error.firstNameError
              || error.lastNameError
              || error.emailError
              || error.phoneError
        ));

        return !hasErrors;
    };

    return {
        travellerValues: values,
        setTravellerValues: setValues,
        travellerErrors: errors,
        setTravellerErrors: setErrors,
        validateTravellerFields: validateFields,
    };
};

export default useTravellerInformation;
