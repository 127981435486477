/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/indent */
import { ButtonBase, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { FC, PropsWithChildren, useMemo } from "react";

import { COLORS } from "../../constants/colors";
import { BUTTON_WIDTH, MIN_BUTTON_WIDTH } from "../../constants/layout";
import { useCurrentProfileContext } from "../../context/CurrentProfileContext";
import { useLocaleContext } from "../../context/LocaleContext";
import theme from "../../styles/theme";
import { CLOSED_DRAWER_WITH, DRAWER_WIDTH } from "../navigation/NavigationMenu.styles";

const useStyles = makeStyles(() => ({
  buttonMobile: {
    zIndex: 1000,
    position: "fixed",
  },
  button: {
    zIndex: 1000,
    height: "44px",
    boxShadow: `0px 4px 20px ${COLORS.BLACK_12}`,
    borderRadius: "4px",
    color: COLORS.WHITE,
    fontWeight: 700,
  },
  buttonDisabled: {
    backgroundColor: COLORS.LIGHT_GRAY,
    color: COLORS.DARK_GRAY,
  },
}));

interface PageActionButtonProps {
  onClick: () => void;
  isVisible?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  width?: number;
  color?: string;
  hoverColor?: string;
  desktopInitialRight?: number;
  mobileInitialBottom?: number;
  className?: string;
}

const MOBILE_INITIAL_BOTTOM = 24;
const DESKTOP_INITIAL_RIGHT = 0;

const PageActionButton: FC<PropsWithChildren<PageActionButtonProps>> = ({
  children,
  onClick,
  isVisible = true,
  isDisabled,
  isLoading,
  width,
  color,
  hoverColor,
  desktopInitialRight,
  mobileInitialBottom,
  className,
}) => {
  const { locale } = useLocaleContext();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isExtraSmall = useMediaQuery(theme.breakpoints.only("xs"));
  const { isMenuClosed } = useCurrentProfileContext();

  const right = useMemo(() => {
    const buttonWidth = width ?? 150;

    if (isExtraSmall) {
      return `calc(50% - ${buttonWidth / 2}px) !important`;
    }

    if (!isMobile) {
      return `${desktopInitialRight ?? DESKTOP_INITIAL_RIGHT}px`;
    }

    if (isMenuClosed) {
      return `calc(50% - ${CLOSED_DRAWER_WITH}px - ${buttonWidth / 2}px) !important`;
    }

    return `calc(50% - ${DRAWER_WIDTH}px) !important`;
  }, [desktopInitialRight, isExtraSmall, isMenuClosed, isMobile, width]);

  if (!isVisible) {
    return null;
  }

  return (
    <ButtonBase
      component="div"
      className={clsx({
        [classes.button]: true,
        [classes.buttonMobile]: isMobile,
        [classes.buttonDisabled]: isDisabled,
      }, className)}
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        right,
        transition: theme.transitions.create([isLoading ? "none" : "right"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        width: width ?? (locale === "es-ES" ? "fit-content" : BUTTON_WIDTH),
        minWidth: width ?? (locale === "es-ES" ? MIN_BUTTON_WIDTH : "auto"),
        backgroundColor: color ?? COLORS.ORANGE,
        ":hover": { backgroundColor: hoverColor ?? COLORS.ORANGE_TEXT },
        bottom: isMobile ? `${mobileInitialBottom ?? MOBILE_INITIAL_BOTTOM}px` : "unset",
      }}
    >
      {children}
    </ButtonBase>
  );
};

export default PageActionButton;
