import { makeStyles, createStyles } from '@mui/styles';

const userModalStyles = makeStyles(() => createStyles({
    formField: {
        marginBottom: '1rem',
        width: '100%',
        '& .MuiFormHelperText-root.Mui-error': { padding: '0 .75rem' },
    },
    multipleSelectItem: {
        width: '100% !important',
        padding: '0.5rem !important',
        '& .MuiListItemText-root': { paddingLeft: '0.5rem !important' },
    },
}));

export default userModalStyles;
