/* eslint-disable max-len */
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import StarIcon from '@mui/icons-material/Star';
import {
    Box,
    Container,
    Rating,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DetailPanelToggle } from '../../../../components/detailPanelToggle/DetailPanelToggle';
import { COLORS } from '../../../../constants/colors';
import { DATE_FORMATS } from '../../../../constants/dateFormats';
import { RIDES } from '../../../../constants/urlPaths';
import { getPercentage } from '../../../../helpers/getPercentage';
import useDataGridStyles from '../../../../styles/DataGrid';
import { TABLE_ROW_HEIGHT } from '../../../../styles/theme';
import { getStatusColor } from '../../helpers';
import { DriverRide, QualityValuationEnum } from '../api/types';
import DetailsRow from '../DetailsRow';
import DriverUpdatesTooltip from '../DriverUpdatesTooltip';
import LocationSharingTooltip from '../LocationSharingTooltip';

const COLUMN_WIDTH = 230;

const useStyles = makeStyles({
    tableHead: { height: `${TABLE_ROW_HEIGHT}px` },
    tableContainer: {
        background: COLORS.WHITE,
        border: 'none',
    },
    tableCell: {
        color: COLORS.BLACK,
        background: COLORS.EXTRA_LIGHT_GRAY,
        paddingRight: '0.625rem',
        paddingLeft: '0',
    },
    firstTableCell: { paddingLeft: '0.625rem' },
    tableHeaderCellContent: {
        lineHeight: '2rem',
        paddingRight: '0.5rem',
    },
    mobileRowDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: COLORS.EXTRA_LIGHT_GRAY,
        padding: '1rem 0.5rem',
        borderBottom: `1px solid ${COLORS.BLACK_12}`,
    },
});

const getRidesTrackedAccuratelyPercent = (rides: DriverRide[], totalTrips: number) => {
    const trackedRidesCount = rides.filter((ride: { gpsTracking: QualityValuationEnum; driverUpdates: QualityValuationEnum }) =>
        ride.gpsTracking === QualityValuationEnum.ACCURATE && ride.driverUpdates === QualityValuationEnum.ACCURATE).length;

    return getPercentage(trackedRidesCount, totalTrips, 0);
};

export const useDriverAnalysisColumns = () => {
    const classes = useDataGridStyles();
    const detailsClasses = useStyles();
    const { t } = useTranslation();

    const columns = useMemo(() => [
        {
            field: 'ride',
            headerName: t('driver'),
            width: '100%',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRowParams) => (
                <Box display="flex" flexDirection="column" width="100%">
                    <Typography marginRight="0.25rem">{params.row.name}</Typography>
                    {params.row.averageRate !== 0 && params.row.averageRate !== null && <Box display="flex" alignItems="center" paddingBottom="0.5rem">
                        <Typography marginRight="0.25rem" fontWeight="700">{params.row.averageRate}</Typography>
                        <Rating
                            value={params.row.averageRate ?? 0}
                            readOnly
                            icon={(
                                <StarIcon
                                    style={{
                                        color: COLORS.ORANGE_OFFICIAL,
                                        fontSize: '1.25rem',
                                    }}
                                />)
                            }
                        />
                    </Box>}
                    <Typography fontSize="0.75rem" lineHeight="0.875rem">
                        <strong>{t('pages.reports.totalRides')}:</strong> {params.row.totalTrips}
                    </Typography>
                    <Typography fontSize="0.75rem" lineHeight="0.875rem">
                        <strong>{t('pages.reports.ridesTrackedAccurately')}:</strong>
                        {getRidesTrackedAccuratelyPercent(params.row.rides, params.row.totalTrips)}%
                    </Typography>
                </Box>
            ),
        },
        {
            field: 'name',
            minWidth: COLUMN_WIDTH,
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => (
                <Container className={classes.tableHeader}>
                    <Typography>{t('driver')}</Typography>
                </Container>
            ),
        },
        {
            field: 'totalReviews',
            minWidth: COLUMN_WIDTH,
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => (
                <Container className={classes.tableHeader}>
                    <Typography>{t('reviews')}</Typography>
                </Container>
            ),
            renderCell: (params: GridRowParams) => (
                <Box display="flex" alignItems="center">
                    {params.row.averageRate === 0 || params.row.averageRate === null
                        ? (
                            <Box>
                                <Typography fontSize="1rem" color={COLORS.SLATE_GREY} textTransform="lowercase">
                                    {`${params.row.totalReviews} ${t('reviews')}`}
                                </Typography>
                            </Box>
                        )
                        : (
                            <>
                                <Box display="flex" flexDirection="column" alignItems="center" marginRight="0.5rem">
                                    <Typography fontSize="1.5rem" fontWeight="700" color={COLORS.BLACK}>
                                        {Math.round((params.row.averageRate ?? 0) * 100) / 100}
                                    </Typography>
                                    {params.row.totalReviews && (
                                        <Typography fontSize="0.75rem" color={COLORS.SLATE_GREY} textTransform="lowercase">
                                            {`${params.row.totalReviews} ${t('reviews')}`}
                                        </Typography>
                                    )}
                                </Box>
                                <Rating
                                    value={params.row.averageRate ?? 0}
                                    readOnly
                                    style={{ paddingBottom: '0.25rem' }}
                                    icon={(
                                        <StarIcon
                                            style={{
                                                color: COLORS.ORANGE_OFFICIAL,
                                                fontSize: '1.25rem',
                                            }}
                                        />)}
                                />
                            </>
                        )}
                </Box>
            ),
        },
        {
            field: 'totalTrips',
            minWidth: COLUMN_WIDTH,
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderHeader: () => (
                <Container className={classes.tableHeader}>
                    <Typography>{t('pages.reports.totalRides')}</Typography>
                </Container>
            ),
        },
        {
            field: 'ridesTrackedAccuratelyPercent',
            minWidth: COLUMN_WIDTH,
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            headerClassName: classes.hideSeparator,
            renderHeader: () => (
                <Container className={clsx(classes.tableHeader, classes.noRightBorder)}>
                    <Typography paddingRight="0.5rem">{t('pages.reports.ridesTrackedAccurately')}</Typography>
                    <Tooltip title={t('pages.reports.ridesTrackedTooltip2')} arrow placement="top">
                        <InfoOutlinedIcon
                            style={{ color: COLORS.SLATE_GREY }}
                        />
                    </Tooltip>
                </Container>
            ),
            renderCell: (params: GridRowParams) => (
                <Typography>
                    {getRidesTrackedAccuratelyPercent(params.row.rides, params.row.totalTrips)}%
                </Typography>
            ),
        },
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            headerClassName: classes.hideSeparator,
            renderCell: (params: { id: GridRowId; value: boolean }) => (
                <DetailPanelToggle id={params.id} value={params.value} />
            ),
        },
    ], [t, classes.hideSeparator, classes.tableHeader, classes.noRightBorder]);

    const rowDetails = useMemo(() => ({ row }: GridRowParams) => (
        <TableContainer className={detailsClasses.tableContainer}>
            <Table size="small" style={{ tableLayout: 'fixed' }}>
                <TableHead className={detailsClasses.tableHead}>
                    <TableRow>
                        <TableCell className={clsx(detailsClasses.tableCell, detailsClasses.firstTableCell)}>
                            <Box display="flex" alignItems="center" borderRight={`1px solid ${COLORS.BLACK_12}`}>
                                <Typography className={detailsClasses.tableHeaderCellContent}>
                                    {t('pages.reports.pickUpTime')}
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell className={detailsClasses.tableCell}>
                            <Box display="flex" alignItems="center" borderRight={`1px solid ${COLORS.BLACK_12}`}>
                                <Typography className={detailsClasses.tableHeaderCellContent}>
                                    {t('rideNumber')}
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell className={detailsClasses.tableCell}>
                            <Box display="flex" alignItems="center" borderRight={`1px solid ${COLORS.BLACK_12}`}>
                                <Typography className={detailsClasses.tableHeaderCellContent}>
                                    {t('pages.reports.driverUpdates')}
                                </Typography>
                                <Tooltip title={<DriverUpdatesTooltip />} arrow placement="top">
                                    <InfoOutlinedIcon
                                        style={{ color: COLORS.SLATE_GREY }}
                                    />
                                </Tooltip>
                            </Box>
                        </TableCell>
                        <TableCell className={detailsClasses.tableCell}>
                            <Box display="flex" alignItems="center">
                                <Typography className={detailsClasses.tableHeaderCellContent}>
                                    {t('pages.reports.locationSharing')}
                                </Typography>
                                <Tooltip title={<LocationSharingTooltip />} arrow placement="top">
                                    <InfoOutlinedIcon
                                        style={{ color: COLORS.SLATE_GREY }}
                                    />
                                </Tooltip>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(row.rides as DriverRide[])?.map((ride: DriverRide) => (
                        <DetailsRow
                            key={ride.rideNumber}
                            ride={ride}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), []);

    const rowDetailsMobile = useMemo(() => ({ id, row }: GridRowParams) => {
        return (
            <Container className={detailsClasses.mobileRowDetails} >
                {row.rides?.map((ride: DriverRide) => (
                    <Box key={id} paddingBottom="1rem">
                        <Box display="flex" alignItems="center" paddingBottom="0.25rem">
                            <Typography fontWeight={700} paddingRight="0.25rem">{t('rideNumber')}: </Typography>
                            <Link to={`${RIDES}/${ride.rideNumber.replace(/-/g, '')}`} target="_blank">
                                <Typography color={COLORS.BLUE}>{ride.rideNumber}</Typography>
                            </Link>
                        </Box>
                        <Typography paddingBottom="0.25rem">{formatInTimeZone(ride.pickupTime, 'UTC', DATE_FORMATS['d MMM yyyy at p'])}</Typography>
                        <Box display="flex">
                            <Typography fontSize="0.75rem" lineHeight="0.875rem" paddingRight="0.25rem">
                                <strong>{t('pages.reports.driverUpdatesAndLocationSharing')}:</strong>
                            </Typography>
                            <Typography
                                textTransform="lowercase"
                                fontSize="0.75rem"
                                lineHeight="0.875rem"
                                color={getStatusColor(ride.driverUpdates)}
                            >
                                {ride.driverUpdates}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Container>
        );
    }, [detailsClasses.mobileRowDetails, t]);

    return [columns, rowDetails, rowDetailsMobile] as const;
};
