import { Dispatch, SetStateAction, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { InputError } from '../../../../helpers/inputValidation/InputError';
import { Validated } from '../../../../helpers/inputValidation/Validator';
import and from '../../../../helpers/inputValidation/validators/and';
import isEmail from '../../../../helpers/inputValidation/validators/isEmail';
import isFilledString from '../../../../helpers/inputValidation/validators/isFilledString';
import { Passenger } from '../../components/passengerInformation/PassengerInformation';
import { PassengerError } from '../../components/passengerInformation/PassengerList';

const initialPassengerId = uuidv4();

export const initialPassenger = {
    id: initialPassengerId,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isLead: true,
} as Passenger;

export const initialPassengerErrors = {
    id: initialPassengerId,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
} as PassengerError;

export const validField = {
    isValid: true,
    value: '',
} as Validated<string, InputError>;

const usePassengerInformation = (): {
    passengerValues: Passenger[];
    setPassengerValues: Dispatch<SetStateAction<Passenger[]>>;
    passengerErrors: PassengerError[];
    setPassengerErrors: Dispatch<SetStateAction<PassengerError[]>>;
    validatePassengerFields: () => boolean;
} => {
    const [values, setValues] = useState<Passenger[]>([initialPassenger]);

    const [errors, setErrors] = useState<PassengerError[]>([initialPassengerErrors]);

    const validateFields = () => {
        const fieldErrors: PassengerError[] = values.map(traveller => {
            const { isLead } = traveller;

            const validatedFirstName = isFilledString(
                traveller.firstName,
                InputError.Empty
            );

            const validatedLastName = isFilledString(
                traveller.lastName,
                InputError.Empty
            );

            let validatedPhone = validField;

            if (isLead) {
                validatedPhone = isFilledString(
                    traveller.phone,
                    InputError.Empty
                );
            }

            const validatedEmail = and(
                (isLead
                    ? isFilledString(traveller.email, InputError.Empty)
                    : validField),
                () => (traveller.email
                    ? isEmail(traveller.email, InputError.InvalidEmail)
                    : validField)
            );

            return {
                id: traveller.id,
                firstName: validatedFirstName.isValid
                    ? null
                    : validatedFirstName.error,
                lastName: validatedLastName.isValid
                    ? null
                    : validatedLastName.error,
                email: validatedEmail.isValid ? null : validatedEmail.error,
                phone: validatedPhone.isValid ? null : validatedPhone.error,
            };
        });

        setErrors(fieldErrors);

        const hasErrors = fieldErrors.some(error => (
            error.firstName
              || error.lastName
              || error.email
              || error.phone
              || !values.find(el => el.isLead)
        ));

        return !hasErrors;
    };

    return {
        passengerValues: values,
        setPassengerValues: setValues,
        passengerErrors: errors,
        setPassengerErrors: setErrors,
        validatePassengerFields: validateFields,
    };
};

export default usePassengerInformation;
