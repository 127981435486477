import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';

const useButtonStyles = makeStyles((theme: Theme) => createStyles(
    {
        root: {
            width: '100%',
            '& > *': {
                marginRight: theme.spacing(1),
                marginTop: theme.spacing(2),
            },
        },
        defaultTextButton: {
            color: theme.palette.common.black,
            fontWeight: 'normal',
            margin: 0,
            padding: 0,
            textAlign: 'left',
            '&:hover': {
                backgroundColor: 'transparent',
                color: '#000',
            },
        },
        inlineButtonMargin: { marginRight: theme.spacing(1) },
        iconButton: {
            padding: theme.spacing(0, 0.25),
            margin: 0,
            minWidth: 0,
            backgroundColor: 'transparent',
            color: theme.palette.grey[300],
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.grey[200],
            },
            '& .MuiSvgIcon-root': { fontSize: '1em' },
        },
    }
),);

export default useButtonStyles;
