import moment from 'moment';

import { DATE_FORMATS } from '../../../../constants/dateFormats';
import { RideAmplitudeEventProperties, RideResponse } from '../../../ride/api/types';

const getAmplitudeEventProperies = (data: RideResponse | undefined) => {
    if (!data) {
        return;
    }

    return {
        'Ride number': data.prettifiedUnid,
        'Ride created time': moment.utc(data.createdAt).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
        'Ride pickup time': moment.utc(data.pickUp.departAt).format(DATE_FORMATS['YYYY-MM-DD HH:mm:ss']),
        'Ride customer': data.customer,
        'Ride supplier': data.supplier,
        'Ride status': data.status,
        'Ride situation tags': data.situationTag,
        'Ride qc status': data.qcStatus,
        'Ride has driver': Boolean(data.driver),
    } as RideAmplitudeEventProperties;
};

export default getAmplitudeEventProperies;
