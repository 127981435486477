import WarningIcon from '@mui/icons-material/Warning';
import { Typography, Box, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconedData from '../../../components/iconedData/IconedData';
import Modal from '../../../components/modal/Modal';
import { COLORS } from '../../../constants/colors';
import { CancellationPolicy } from '../api/types';
import { Vehicle } from './VehicleInformationCards';

const useStyles = makeStyles({
    confirmButton: {
        background: COLORS.RED,
        '&:hover': { background: COLORS.RED_WARNING },
    },
    closeButton: { marginLeft: '.5rem' },
    systemAutomatedBox: {
        padding: '.5rem',
        borderRadius: '.25rem',
        backgroundColor: COLORS.RED,
        marginBottom: '2rem',
    },
    contact: {
        textDecoration: 'underline',
        color: COLORS.WHITE,
        '&:hover': {
            color: COLORS.RED_WARNING,
            textDecoration: 'underline',
            borderBottom: 'none',
        },
    },
});

const CancelRideConfirmModal = ({
    onClose,
    onConfirm,
    cancellationPolicies,
    selectedVehicle,
    isLoading,
    isSystemAutomatedRide,
}: {
    onClose: () => void;
    onConfirm: () => void;
    cancellationPolicies: CancellationPolicy[];
    selectedVehicle: Vehicle | null;
    isLoading?: boolean;
    isSystemAutomatedRide?: boolean;
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { useManagedByCars } = useFlags();

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={onConfirm}
            title={t('modals.cancelRide.title')}
            confirmButtonLabel={t('buttonName.cancel')}
            cancelButtonLabel={t('buttonName.back')}
            confirmButtonClassName={classes.confirmButton}
            maxWidth="sm"
            isDisabled={isLoading}
        >
            {isSystemAutomatedRide && useManagedByCars && (
                <Box className={classes.systemAutomatedBox}>
                    <IconedData
                        icon={WarningIcon}
                        data={t('modals.cancelRide.isSystemAutomated2')}
                        dataLabel={t('modals.cancelRide.isSystemAutomated1')}
                        dataLabelColor={COLORS.WHITE}
                        iconColor={COLORS.WHITE}
                        dataLabelBold
                        dataColor={COLORS.WHITE}
                    />
                    <Typography marginLeft="34px" color={COLORS.WHITE} fontWeight={700}>
                        {t('modals.cancelRide.isSystemAutomated3')}
                        <Link
                            href={`mailto:${t('contact.emailAddress')}`}
                            className={classes.contact}
                        >
                            {t('modals.cancelRide.isSystemAutomatedContact')}
                        </Link>
                        {t('modals.cancelRide.isSystemAutomatedNext')}
                    </Typography>
                </Box>
            )}
            <Typography>{t('modals.cancelRide.description')}</Typography>
            {cancellationPolicies.length > 0 && (
                <Typography
                    style={{
                        fontWeight: 700,
                        marginBottom: 0,
                        marginTop: '1rem',
                    }}
                >
                    {t('pages.singleRide.polices.cancellationPolicies')}
                </Typography>
            )}
            {cancellationPolicies.map(policy => (
                <div key={policy.id}>
                    <Typography key={policy.id} mb={'1rem'}>
                        {t('pages.singleRide.polices.cancelled', {
                            cancelledUnderHours: policy.cancelledUnderHours,
                            chargePercentage: policy.chargePercentage,
                        })}
                    </Typography>
                    {selectedVehicle && policy.vehicleTypes.includes(selectedVehicle.vehicleType) && (
                        <Typography key={policy.id} mb={'1rem'}>
                            {t('pages.singleRide.polices.appliableFor', { appliableCarTypes: selectedVehicle.vehicleType })}
                        </Typography>
                    )}
                </div>
            ))}
            <Typography fontWeight="700" marginTop="1rem">
                {t('doYouWantToProceed')}
            </Typography>
        </Modal>
    );
};

export default CancelRideConfirmModal;
