import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import { FormControlLabel, Radio, Tooltip, Typography } from '@mui/material';
import { GridRowParams, GridActionsCellItem } from '@mui/x-data-grid-pro';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../../constants/colors';
import useDataGridStyles from '../../../../styles/DataGrid';
import { Traveller } from './TravellerInformation';

export enum TravellerAction {
    EDIT = 'buttonName.edit',
    REMOVE = 'buttonName.remove',
}

export const useTravellerInformationColumns = (
    handleEdit: (traveller: Traveller) => void,
    handleRemove: (traveller: Traveller) => void,
    handleSetLead: (id: string) => void,
    isDisabled: boolean
) => {
    const { t } = useTranslation();
    const dataGridClases = useDataGridStyles();

    const onActionItemClick = useCallback((traveller: Traveller, action: TravellerAction) => {
        switch (action) {
            case TravellerAction.EDIT:
                handleEdit(traveller);
                break;
            case TravellerAction.REMOVE:
                handleRemove(traveller);
                break;
            default:
                throw new Error('Traveller action not supported');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(
        () => [
            {
                field: 'fullName',
                headerName: t('pages.rides.fullName'),
                headerClassName: dataGridClases.header,
                minWidth: 140,
                flex: 1,
                renderCell: (params: GridRowParams) => (
                    <Tooltip title={params.row.firstName} placement="top" arrow>
                        <Typography className={dataGridClases.truncateText}>
                            {params.row.firstName} {params.row.lastName}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                field: 'phone',
                headerName: t('form.fields.mobile'),
                headerClassName: dataGridClases.header,
                minWidth: 140,
                flex: 1,
                renderCell: (params: { value: string }) => (
                    <Tooltip title={params.value} placement="top" arrow>
                        <Typography className={dataGridClases.truncateText}>
                            {params.value}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                field: 'email',
                headerName: t('email'),
                headerClassName: dataGridClases.header,
                minWidth: 140,
                flex: 1,
                renderCell: (params: { value: string }) => (
                    <Tooltip title={params.value} placement="top" arrow>
                        <Typography className={dataGridClases.truncateText}>
                            {params.value}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                field: 'isLead',
                headerName: t('pages.rides.mainPassenger'),
                headerClassName: dataGridClases.header,
                minWidth: 140,
                maxWidth: 140,
                renderCell: (params: GridRowParams) => (
                    <FormControlLabel
                        key={params.row.id}
                        value={params.row.id}
                        label={null}
                        onClick={() => handleSetLead(params.row.id)}
                        control={<Radio checked={params.row.isLead} />}
                        disabled={isDisabled}
                    />
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                width: 20,
                headerClassName: dataGridClases.hideSeparator,
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        key={TravellerAction.EDIT}
                        className={dataGridClases.menuItem}
                        icon={<CreateOutlinedIcon style={{ color: COLORS.BLUE_DARK }} />}
                        label={t('buttonName.edit')}
                        onClick={() => onActionItemClick(params.row, TravellerAction.EDIT)}
                        showInMenu
                        onResize={undefined}
                        onResizeCapture={undefined}
                        nonce=""
                    />,
                    <GridActionsCellItem
                        key={TravellerAction.REMOVE}
                        className={params?.row?.isLead ? dataGridClases.hideItem : dataGridClases.menuItem}
                        icon={<PersonOffOutlinedIcon style={{ color: COLORS.BLUE_DARK }} />}
                        label={t('buttonName.remove')}
                        onClick={() => onActionItemClick(params.row, TravellerAction.REMOVE)}
                        showInMenu
                        onResize={undefined}
                        onResizeCapture={undefined}
                        nonce=""
                    />,
                ],
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t, dataGridClases.hideSeparator, dataGridClases.menuItem, handleSetLead, onActionItemClick]
    );
};
