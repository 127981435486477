import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { BUTTON_WIDTH } from '../../../constants/layout';

const useStyles = makeStyles({
    isDisabled: {
        backgroundColor: `${COLORS.LIGHT_GRAY} !important`,
        color: COLORS.SLATE_GREY,
    },
    exportMainButton: {
        position: 'absolute',
        background: COLORS.WHITE,
        bottom: '-15px',
        left: 'calc(50% - 4rem)',
        width: BUTTON_WIDTH,
        height: '44px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: `0px 4px 20px ${COLORS.BLACK_12}`,
        borderRadius: '4px',
        color: COLORS.SLATE_GREY,
        fontWeight: 700,
    },
});

interface ExportButtonProps {
    onClick?: () => void;
    isDisabled?: boolean;
}

const ExportButton = ({ onClick, isDisabled }: ExportButtonProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <ButtonBase
            component="div"
            onClick={onClick}
            className={clsx(classes.exportMainButton, { [classes.isDisabled]: isDisabled })}
        >
            <CloudDownloadOutlinedIcon style={{ paddingRight: '0.5rem' }} />{t('pages.rides.export')}
        </ButtonBase>
    );
};

export default ExportButton;
