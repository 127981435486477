import { Typography } from '@mui/material';
import React from 'react';

import { useTranslation } from 'react-i18next';
import Modal from '../../../components/modal/Modal';

const LeavePageModal = ({
    onClose,
    onConfirm,
}: {
    onClose: () => void;
    onConfirm: () => void;
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen
            onClose={onClose}
            onSubmit={onConfirm}
            title={t('modals.leavePage.title')}
            confirmButtonLabel={t('buttonName.confirm')}
            cancelButtonLabel={t('buttonName.back')}
            maxWidth="sm"
        >
            <Typography>{t('modals.leavePage.description')}</Typography>
            <Typography
                fontWeight="700"
                marginTop="1rem"
            >
                {t('doYouWantToProceed')}
            </Typography>
        </Modal>
    );
};

export default LeavePageModal;
