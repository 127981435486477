import { Select, SelectOption } from '@get-e/react-components';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import {
    Typography,
    Grid,
} from '@mui/material';
import {
    DateRange,
    DateRangePicker,
    LocalizationProvider,
    SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { PERIODS } from '../../constants';
import { COLORS } from '../../constants/colors';
import { DATE_FORMATS } from '../../constants/dateFormats';
import { datePickerPlaceholder } from '../../constants/datePickerPlaceholder';
import { InputError } from '../../helpers/inputValidation/InputError';
import isValidDate from '../../helpers/inputValidation/validators/isValidDate';
import typographyStyles from '../../styles/Typography';
import exportRidesToExcel from '../rides/api/exportRidesToExcel';
import ExportGridContainer from './components/ExportGridContainer';

export interface CustomerExportProps {
    startDate?: Moment;
    endDate?: Moment;
}

interface CustomerExportFormErrors {
    startDate: InputError | null;
    endDate: InputError | null;
}

const CustomerExport = ({ startDate, endDate }: CustomerExportProps) => {
    const [dateRange, setDateRange] = React.useState<DateRange<Moment>>([
        startDate ?? null,
        endDate ?? null,
    ]);

    const [formErrors, setFormErrors] = useState<CustomerExportFormErrors>({
        startDate: null,
        endDate: null,
    });

    const [period, setPeriod] = useState<number>(PERIODS.LAST_30_DAYS.value);

    const { t } = useTranslation();
    const typeClasses = typographyStyles();

    const { mutate: exportRidesToExcelMutation, isError, isLoading } = useMutation(exportRidesToExcel);

    const validateFields = (): boolean => {
        const fieldErrors = {
            startDate: isValidDate(dateRange[0]?.toDate() ?? null),
            endDate: isValidDate(dateRange[1]?.toDate() ?? null),
        } as CustomerExportFormErrors;

        setFormErrors({ ...fieldErrors });

        return fieldErrors.startDate === null && fieldErrors.endDate === null;
    };

    const handleClick = (): void => {
        if (!validateFields()) {
            return;
        }

        exportRidesToExcelMutation({
            startDate: dateRange[0]?.format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']) ?? '',
            endDate: dateRange[1]?.clone().endOf('day')
                .format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']) ?? '',
        });
    };

    const handlePeriodDownload = (): void => {
        const periodKey = Object.keys(PERIODS).find(key => PERIODS[key].value === period);

        if (period === null || !periodKey) {
            return;
        }

        let newStartDate: Moment | null = null;
        let newEndDate: Moment | null = null;

        if (periodKey === 'YESTERDAY') {
            newStartDate = moment().subtract(1, 'days')
                .startOf('day');
            newEndDate = newStartDate?.clone();
        } else {
            newStartDate = moment().subtract(PERIODS[periodKey].value, 'days')
                .startOf('day');
            newEndDate = moment();
        }

        exportRidesToExcelMutation({
            startDate: newStartDate.format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
            endDate: newEndDate.format(DATE_FORMATS['YYYY-MM-DDTHH:mm:ss']),
        });
    };

    const getHelperText = (position: 'start' | 'end') => {
        if (position === 'start') {
            return formErrors.startDate ? t(formErrors.startDate) : '';
        }

        return formErrors.endDate ? t(formErrors.endDate) : '';
    };

    return (
        <Grid
            container
            flexDirection="column"
            sx={{
                padding: ['1rem', '1rem', '0 2rem'],
                paddingBottom: ['4rem', '2rem', '2rem'],
            }}
        >
            <Grid item xs>
                <Typography
                    sx={{
                        color: COLORS.BLUE,
                        fontSize: '1.5rem',
                        fontWeight: 700,
                    }}
                >
                    {t('pages.export.export')}
                </Typography>
            </Grid>
            <ExportGridContainer
                title={t('pages.export.selectDateRange')}
                description={t('pages.export.selectDateRangeDescription')}
                isButtonDisabled={period === null}
                isLoading={isLoading}
                buttonOnClick={handlePeriodDownload}
                isShowingDownloadButton={period.toString() !== 'custom'}
            >
                <Grid item xs={12} md={10} xl={10}>
                    <Select
                        label={t('selectPeriod')}
                        value={period}
                        onChangeValue={newValue => setPeriod(newValue)}
                        disabled={isLoading}
                    >
                        <SelectOption value={PERIODS.LAST_30_DAYS.value}>
                            {t(PERIODS.LAST_30_DAYS.translationKey)}
                        </SelectOption>
                        <SelectOption value={PERIODS.LAST_7_DAYS.value}>
                            {t(PERIODS.LAST_7_DAYS.translationKey)}
                        </SelectOption>
                        <SelectOption value={PERIODS.YESTERDAY.value}>
                            {t(PERIODS.YESTERDAY.translationKey)}
                        </SelectOption>
                        <SelectOption value="custom">
                            {t('periodPicker.custom')}
                        </SelectOption>
                    </Select>
                </Grid>
            </ExportGridContainer>

            {period.toString() === 'custom' && (
                <ExportGridContainer
                    isButtonDisabled={dateRange[0] === null || dateRange[1] === null}
                    isLoading={isLoading}
                    buttonOnClick={handleClick}
                    isShowingDownloadButton
                >
                    <Grid item xs={12} md={10} xl={10}>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                        >
                            <DateRangePicker
                                value={dateRange}
                                label={t('dateRange')}
                                onChange={newValue => {
                                    setDateRange(newValue);
                                }}
                                sx={{ width: '100%' }}
                                localeText={{
                                    start: t('pages.rides.startingDate'),
                                    end: t('pages.rides.endingDate'),
                                    ...datePickerPlaceholder,
                                }}
                                slotProps={{
                                    fieldSeparator: { children: 'to' },
                                    textField: ({ position }) => ({
                                        helperText: position && getHelperText(position),
                                        error: position === 'start'
                                            ? formErrors.startDate !== null
                                            : formErrors.endDate !== null,
                                        variant: 'filled',
                                        InputProps: {
                                            endAdornment:
                                             <CalendarTodayOutlinedIcon style={{ color: COLORS.SLATE_GREY }} />,
                                        },
                                    }),
                                }}
                                slots={{ field: SingleInputDateRangeField }}
                                format={DATE_FORMATS['DD MMM YYYY']}
                            />
                        </LocalizationProvider>
                    </Grid>
                </ExportGridContainer>
            )}
            {isError && (
                <div
                    style={{
                        display: 'block',
                        width: '100%',
                    }}
                >
                    <p
                        className={clsx(
                            typeClasses.errorTheme,
                            typeClasses.errorMessageMargin
                        )}
                    >
                        {t('modals.unexpectedError')}
                    </p>
                </div>
            )}
        </Grid>
    );
};

export default CustomerExport;
