import { Autocomplete } from '@get-e/react-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FlightIcon from '@mui/icons-material/Flight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useContext } from 'react';

import CopyToClipboardButton from '../../../components/buttons/CopyToClipboardButton';
import { COLORS } from '../../../constants/colors';
import { InputError } from '../../../helpers/inputValidation/InputError';
import useFormStyles from '../../../styles/Form';
import { SearchLocation } from '../api/types';
import { useSearchLocation } from '../customer/api/useSearchLocation';
import { RideContext } from '../customer/context/RideContext';

const useStyles = makeStyles({ secondaryText: { color: COLORS.DARK_GRAY } });

interface SearchLocationProps {
    error: InputError | null;
    helperText?: string;
    inputValue: string;
    setInputValue: (inputValue: string) => void;
    address: SearchLocation | null;
    setAddress: (address: SearchLocation | null) => void;
    label: string;
    isSearchEnabled?: boolean;
    logEvent?: () => void;
}

export const SearchLocationComponent = ({
    error = null,
    helperText,
    inputValue = '',
    setInputValue,
    address = null,
    setAddress,
    label = '',
    logEvent,
}: SearchLocationProps) => {
    const classes = useStyles();
    const formClasses = useFormStyles();
    const [isSearchLocationEnabled, setIsSearchLocationEnabled] = useState(false);

    const { isDisabled } = useContext(RideContext);

    const { searchOptions = [], isLoading, refetch } = useSearchLocation(inputValue, isSearchLocationEnabled);

    const handleOpen = async () => {
        setIsSearchLocationEnabled(true);
        await refetch();
    };

    return (
        <Container
            sx={{
                position: 'relative',
                padding: '0 !important',
            }}
        >
            <Autocomplete
                popupIcon={isDisabled ? '' : <ArrowDropDownIcon /> }
                required
                filterOptions={(option): SearchLocation[] => option }
                label={label}
                loading={isLoading}
                options={searchOptions}
                getOptionLabel={(option: SearchLocation) => option.name}
                isError={error !== null}
                helperText={helperText}
                onOpen={handleOpen}
                renderOption={(prop, option: SearchLocation) => {
                    const renderIcon = option.type === 'AIRPORT' ? FlightIcon : LocationOnIcon;

                    return (
                        <Box
                            component="li"
                            {...prop}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '.75rem 1rem',
                            }}
                        >
                            <Box
                                component={renderIcon}
                                sx={{
                                    color: COLORS.BLUE_DARK,
                                    mr: 2,
                                }}
                            />
                            <Box>
                                <Typography
                                    sx={{ marginRight: '1rem' }}
                                >
                                    {option.address ?? option.name}
                                </Typography>
                                <Typography
                                    className={classes.secondaryText}
                                >
                                    {option?.city} {option?.country && `, ${option?.country}`}
                                </Typography>
                            </Box>
                        </Box>
                    );
                }}
                value={address}
                onChange={(_, newValue) => {
                    logEvent?.();
                    setAddress?.(newValue ?? null);
                }}
                onInputChange={(_, newInputValue) => {
                    setInputValue?.(newInputValue);
                }}
                disabled={isDisabled}
                className={formClasses.disabledField}
            />
            {isDisabled && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '5px',
                    }}
                >
                    <CopyToClipboardButton text={inputValue}/>
                </Box>
            )}
        </Container>
    );
};
